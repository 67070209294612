// @ts-nocheck
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useEffect, useState } from "react";

// This component is used to protect routes that require a user to be an organization admin.
export const AdminRoute = ({ children }) => {
    const location = useLocation();
    const [isLoaded, setIsLoaded] = useState(false);
    const {
        authUser,
        adminuser,
        organization,
        subscription,
        getOrganization,
        getAdminUser,
        getSubscription,
        datastore,
        logout,
    } = useAuth();

    // useEffect(() => {
    //     async function loadData() {
    //         try {
    //             const { error: orgError } = await getOrganization();
    //             const { error: adminUserError } = await getAdminUser();
    //             const { error: subError } = await getSubscription();

    //             if (orgError || adminUserError || subError) {
    //                 throw new Error("Failed to load user data");
    //             }
    //         } catch (err) {
    //             console.error(err);
    //             logout();
    //             return <Navigate to="/login" replace />;
    //         }

    //         setIsLoaded(true);
    //     }

    //     if (!isLoaded) {
    //         loadData();
    //     }
    // }, []);

    // if (!isLoaded) {
    //     return null;
    // }

    if (
        !authUser ||
        !adminuser?.organization_id ||
        !organization?.id ||
        adminuser.organization_id !== organization?.id ||
        !subscription
    ) {
        console.error('>>> ERROR: User is not authorized to view Admin Pages');
        return;
        // logout();
        // return <Navigate to="/login" replace />;
    }

    // Check for subscription - if none, then redirect to subscription setup page
    if (!subscription || subscription.status === "canceled") {
        console.log('>>> Admin Owner Route: Subscription is canceled');
        if (location.pathname !== "/subscription") {
            return <Navigate to="/subscription" replace />;
        } else {
            return children;
        }
    }

    if (subscription.status === "paused") {
        console.log('>>> Admin Owner Route: Subscription is paused');
        if (location.pathname !== "/account") {
            return <Navigate to="/account" replace />;
        } else {
            return children;
        }
    }

    if (!datastore || !datastore.api || datastore.api.type === "public") {
        // Waiting to load completely
        return null;
    }

    return children;
};

// @ts-nocheck
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { useAuth } from "../../../hooks/useAuth";
import useDebounce from "../../../hooks/useDebounce";

import "./styles.scss";

export default function UserSearch({ addUser, users }) {
    const { datastore } = useAuth();
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);

    const search = async (query) => {
        try {
            const data = await datastore.api.getUsers({ q: query });
            // Filter out users that are already in the group (look at users.email)
            const filteredUsers = data.users.filter((user) => {
                return !users.find((u) => u.email === user.email);
            });

            setOptions(filteredUsers);
        } catch (error) {
            console.error("Error searching users: ", error);
            return;
        }
    };

    useDebounce(() => {
        if (inputValue === "") {
            setOptions([]);
            return;
        }

        search(inputValue);
    }, [inputValue], 500);

    return (
        <Autocomplete
            key={`user-search-${users?.length || 0}`}
            getOptionLabel={(option) =>
                typeof option === "string" ? option : option.email
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            noOptionsText="No users found"
            onChange={(event, newValue) => {
                if (!newValue) {
                    return;
                }

                console.log(">>> search change: ", newValue);
                addUser(newValue);
                setInputValue("");
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label="Add user to group" fullWidth />
            )}
            renderOption={(props, option) => {
                delete props.className;
                return (
                    <Box
                        component="div"
                        className="user-search-option"
                        {...props}
                    >
                        <div className="email">{option.email}</div>
                        <Chip
                            className="add-text"
                            label="Add"
                            variant="outlined"
                            size="small"
                        />
                    </Box>
                );
            }}
        />
    );
}

import * as React from "react";
import {
    Link as RouterLink,
} from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useDocumentTitle from "../hooks/useDocumentTitle";

import "./NotFound.scss";

export default function NotFound() {
    useDocumentTitle("Toggles - Page Not Found");

    return (
        <Container maxWidth={false} className="not-found-container">
            <Typography className="warning-404" variant="h3" align="center">
                404
            </Typography>
            <Typography className="warning-message" variant="h3" align="center">
                Oops! This page doesn't exist...
            </Typography>
            <Button className="warning-return-btn" variant="contained" component={RouterLink} to="/">
                Back to Home
            </Button>
        </Container>
    );
};

// @ts-nocheck
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import EditAction from "./EditAction";
import { useAuth } from "../../../hooks/useAuth";

const CreateEditActions = ({ actions, updateActions, canEdit, availableTemplates, availableSignatures }) => {
    const { hasActiveSubscription } = useAuth();
    const [hasTemplateAction, setHasTemplateAction] = useState(false);
    const [hasSignatureAction, setHasSignatureAction] = useState(false);
    const [hasDelayAction, setHasDelayAction] = useState(false);
    const [hasLinkAction, setHasLinkAction] = useState(false);

    const handleUpdate = (action, index) => {
        const updatedActions = [...actions];

        if (!action) {
            // Delete
            updatedActions.splice(index, 1);
        } else {
            // update
            updatedActions[index] = action;
        }

        updateActions(updatedActions);
    };

    const createAction = () => {
        const updatedActions = [...actions];
        updatedActions.push({
            type: "tag",
            tag: "",
            location: "start",
        });
        updateActions(updatedActions);
    };

    useEffect(() => {
        // Check if already has "signature" or "template" action and disable ability to add another
        setHasSignatureAction(!!actions.find((a) => a.type === "signature"));
        setHasTemplateAction(!!actions.find((a) => a.type === "template"));
        setHasDelayAction(!!actions.find((a) => a.type === "delay"));
        setHasLinkAction(!!actions.find((a) => a.type === "link"));
    }, [actions]);

    return (
        <div className="actions">
            {actions.map((action, idx) => (
                <EditAction
                    action={action}
                    index={idx}
                    canEdit={canEdit}
                    updateAction={handleUpdate}
                    availableTemplates={availableTemplates}
                    availableSignatures={availableSignatures}
                    hasSignatureAction={hasSignatureAction}
                    hasTemplateAction={hasTemplateAction}
                    hasDelayAction={hasDelayAction}
                    hasLinkAction={hasLinkAction}
                    key={`edit-action-${idx}`}
                />
            ))}

            <Tooltip
                title={
                    !hasActiveSubscription() && actions.length > 0
                        ? "Upgrade to Premium to add more than 1 action"
                        : ""
                }
            >
                <span>
                    <Button
                        className="add-action-btn"
                        onClick={createAction}
                        variant="outlined"
                        disabled={
                            !canEdit ||
                            (!hasActiveSubscription() && actions.length > 0)
                        }
                    >
                        Add Action
                    </Button>
                </span>
            </Tooltip>
        </div>
    );
};

export default CreateEditActions;

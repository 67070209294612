// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Slider from "@mui/material/Slider";
import Signup from "./Signup";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useAuth } from "../../hooks/useAuth";
import { REACT_APP_STRIPE_PRICING } from "../../helpers/constants";

import "./styles.scss";

export default function Subscription() {
    const navigate = useNavigate();
    useDocumentTitle("Toggles Hub | Subscription");
    const { subscription, organization } = useAuth();
    const [type, setType] = useState('premium');
    const [data, setData] = useState({
        interval: "monthly",
        seats: 5,
    });

    const handlePaymentCompleted = (subscription) => {
        console.log('Signup for subscription completed: ', subscription);
        navigate("/account");
    };

    const getDisplayPricePerUser = () => {
        switch (data.interval) {
            case "monthly":
                return `$${REACT_APP_STRIPE_PRICING[type].monthly.toLocaleString()} / month / user (${data.seats} users)`;
            case "annually":
                return `$${REACT_APP_STRIPE_PRICING[type].annually.toLocaleString()} / year / user (${data.seats} users)`;
            default:
                return "Oops! There was an issue calculating the total price.";
        }
    };

    const getDisplayTotal = () => {
        switch (data.interval) {
            case "monthly":
                return `$${(data.seats * REACT_APP_STRIPE_PRICING[type].monthly).toLocaleString()} billed monthly`;
            case "annually":
                return `$${(data.seats * REACT_APP_STRIPE_PRICING[type].annually).toLocaleString()} billed annually`;
            default:
                return "Oops! There was an issue calculating the price per user.";
        }
    };

    useEffect(() => {
        setType(!organization ? 'premium' : 'business');
    }, [organization]);

    useEffect(() => {
        if (subscription) {
            navigate('/account');
        }
    }, [subscription]);

    return (
        <Container className="subscription-container" maxWidth="lg">
            <Grid container spacing={4}>
                <Grid item xs={12} className="header">
                    <Typography
                        variant="h4"
                        className="page-title"
                        align="center"
                    >
                        Setup your subscription
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="interval-selection">
                        <Typography
                            variant="subtitle2"
                            display="block"
                            className="section-label"
                        >
                            How do you want to pay?
                        </Typography>
                        <div
                            className={`interval-option ${
                                data.interval === "monthly" ? "selected" : ""
                            }`}
                            onClick={() =>
                                setData((prev) => ({
                                    ...prev,
                                    interval: "monthly",
                                }))
                            }
                        >
                            <Typography className="label">
                                Pay Monthly
                            </Typography>
                            <Typography className="pricing">
                                $
                                {REACT_APP_STRIPE_PRICING[type].monthly.toFixed(
                                    2
                                )}{" "}
                                / month {type === "business" ? "/ user" : ""}
                            </Typography>
                        </div>
                        <div
                            className={`interval-option ${
                                data.interval === "annually" ? "selected" : ""
                            }`}
                            onClick={() =>
                                setData((prev) => ({
                                    ...prev,
                                    interval: "annually",
                                }))
                            }
                        >
                            <Typography className="label">
                                Pay Annually
                            </Typography>
                            <Typography className="pricing">
                                $
                                {(
                                    REACT_APP_STRIPE_PRICING[type].annually / 12
                                ).toFixed(2)}{" "}
                                / month {type === "business" ? "/ user" : ""} ($
                                {REACT_APP_STRIPE_PRICING[type].annually} billed annually)
                            </Typography>
                            <span className="savings">
                                Save{" "}
                                {Math.floor(
                                    ((REACT_APP_STRIPE_PRICING[type].monthly -
                                        REACT_APP_STRIPE_PRICING[type]
                                            .annually /
                                            12) /
                                        REACT_APP_STRIPE_PRICING[type]
                                            .monthly) *
                                        100,
                                    10
                                )}
                                %
                            </span>
                        </div>
                    </div>
                    {type === "business" && (
                        <>
                            <div className="seat-selection">
                                <Typography
                                    variant="h5"
                                    display="block"
                                    className="section-label"
                                >
                                    How many users do you need?
                                </Typography>
                                <Input
                                    className="seat-input"
                                    type="number"
                                    inputProps={{
                                        max: 500,
                                        min: 5,
                                    }}
                                    value={data.seats}
                                    onChange={(event) => {
                                        const value = parseInt(
                                            event.target.value,
                                            10
                                        );
                                        if (value >= 5 && value <= 500) {
                                            setData((prev) => ({
                                                ...prev,
                                                seats: value,
                                            }));
                                        }
                                    }}
                                />
                                <Slider
                                    aria-label="# of user selection slider"
                                    valueLabelDisplay="auto"
                                    value={data.seats}
                                    step={5}
                                    marks
                                    min={5}
                                    max={500}
                                    onChange={(event, value) =>
                                        setData((prev) => ({
                                            ...prev,
                                            seats: value,
                                        }))
                                    }
                                />
                            </div>
                            <div className="calculated-price">
                                <Typography className="pricing">
                                    <b>Price</b>: {getDisplayPricePerUser()}
                                </Typography>
                                <Typography className="total">
                                    <b>Total</b>: {getDisplayTotal()}
                                </Typography>
                                {data.seats === 500 && (
                                    <Typography className="contact-message">
                                        Need more than 500 users? Contact us at{" "}
                                        <a href="mailto:sales@gettoggles.com">
                                            sales@gettoggles.com
                                        </a>{" "}
                                        for enterprise pricing.
                                    </Typography>
                                )}
                            </div>
                        </>
                    )}
                </Grid>
                <Grid item xs={0} md={1} />
                <Grid item xs={12} md={5}>
                    <Signup
                        interval={data.interval}
                        seats={data.seats}
                        completePayment={handlePaymentCompleted}
                        type={type}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

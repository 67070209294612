// @ts-nocheck
import React, { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import { TRIAL_NUM_DAYS } from "../../helpers/constants";

import "./styles.scss";

dayjs.extend(relativeTime);

const UpgradeBanner = memo(({ trialAvailable }) => {
    if (trialAvailable) {
        return (
            <Alert severity="warning" className="upgrade-banner">
                Try Toggles Premium for FREE for {TRIAL_NUM_DAYS['premium']} days.
                Experience the full potential of Toggles with no credit card
                required!{" "}
                <Link
                    color="primary"
                    variant="body2"
                    underline="none"
                    component={RouterLink}
                    to="/subscription"
                >
                    Try Premium
                </Link>
            </Alert>
        );
    }

    return (
        <Alert severity="warning" className="upgrade-banner">
            Unlock advanced features like email templates, signatures, and
             advanced rules and level up your productivity.{" "}
            <Link
                color="primary"
                variant="body2"
                underline="none"
                component={RouterLink}
                to="/subscription"
            >
                Upgrade to Premium
            </Link>
        </Alert>
    );
});

export default UpgradeBanner;

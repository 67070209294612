// @ts-nocheck
import React, { useState } from "react";
import { Popper, Paper, Typography, useTheme } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import "./styles.scss";

const VariableHelpPopper = () => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div
            className="variable-help-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <HelpOutlineIcon className="instructions-icon-btn" />
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement="bottom"
                sx={{ zIndex: theme.zIndex.tooltip }}
            >
                <Paper
                    sx={{
                        padding: theme.spacing(2),
                        border: "1px solid #cccccc",
                        borderRadius: "4px",
                        background: "#f9f9f9",
                    }}
                >
                    <div className="variable-help">
                        <Typography
                            className="section-label"
                            variant="subtitle2"
                            gutterBottom
                        >
                            How to use variables
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Variables are supported in templates, signatures,
                            and subject lines. To define a variable, insert{" "}
                            <span className="highlight">{`{{some_variable_name}}`}</span>{" "}
                            into the editor. When you toggle on a rule that
                            inserts this content, you will be prompted to enter
                            a value for this variable and the value will replace
                            this text.
                        </Typography>
                    </div>
                </Paper>
            </Popper>
        </div>
    );
};

export default VariableHelpPopper;

// @ts-nocheck
import React, { useState, useEffect } from "react";
import * as DOMPurify from "dompurify";
import {
    Popper,
    Paper,
    ToggleButtonGroup,
    ToggleButton,
    Divider,
    ScopedCssBaseline,
} from "@mui/material";
import { useTheme } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

import "./styles.scss";

const ContentPreview = ({ htmlString }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [widthType, setWidthType] = useState("mobile");
    const [sanitizedHtmlString, setSanitizedHtmlString] = useState("");

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
    };

    const handleWidthChange = (event, newWidthType) => {
        if (newWidthType !== null) {
            setWidthType(newWidthType);
        }
    };

    useEffect(() => {
        const sanitized = DOMPurify.sanitize(htmlString);
        setSanitizedHtmlString(sanitized);
    }, [htmlString]);

    const open = Boolean(anchorEl);

    return (
        <div
            className="content-preview-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <PreviewIcon className="preview-icon-btn" />
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement="right"
                sx={{ zIndex: theme.zIndex.tooltip }}
            >
                <Paper
                    sx={{
                        padding: theme.spacing(2),
                        border: "1px solid #cccccc",
                        borderRadius: "4px",
                        background: "#f9f9f9",
                    }}
                >
                    <ToggleButtonGroup
                        value={widthType}
                        exclusive
                        onChange={handleWidthChange}
                        aria-label="preview-device-type"
                        size="small"
                    >
                        <ToggleButton value="mobile" aria-label="mobile">
                            <PhoneAndroidIcon />
                        </ToggleButton>
                        <ToggleButton value="desktop" aria-label="desktop">
                            <PersonalVideoIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Divider sx={{ py: 1 }} />
                    <ScopedCssBaseline>
                        <div
                            className="preview-content"
                            dangerouslySetInnerHTML={{
                                __html: sanitizedHtmlString,
                            }}
                            style={{
                                width:
                                    widthType === "desktop" ? "600px" : "320px",
                            }}
                        />
                    </ScopedCssBaseline>
                </Paper>
            </Popper>
        </div>
    );
};

export default ContentPreview;

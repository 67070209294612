// @ts-nocheck
import { getSupabaseClient } from "../hooks/useSupabase";

export default class PublicDataStore {
    public type: string;
    private supabase: any;

    constructor() {
        this.supabase = getSupabaseClient();
        this.type = 'public';
    }
}

// @ts-nocheck
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import UserSearch from "./UserSearch";
import useDebounce from "../../../hooks/useDebounce";

import "./styles.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CreateEditGroup({
    open,
    onClose,
    onSave,
    group,
}) {
    const [currentGroup, setCurrentGroup] = useState(group);
    const [groupUserToAdd, setGroupUserToAdd] = useState([]);
    const [groupUsersToRemove, setGroupUsersToRemove] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [inputStateChanged, setInputStateChanged] = useState({
        name: false,
        description: false,
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({
        severity: "success",
        message: "",
    });

    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleAddUser = (user) => {
        // Should first check if user is in groupUsersToRemove and just remove from there if so
        if (groupUsersToRemove.find((u) => u.email === user.email)) {
            setGroupUsersToRemove((prev) =>
                prev.filter((u) => u.email !== user.email)
            );
        } else if (!groupUserToAdd.find((u) => u.email === user.email)) {
            // Add to groupUserToAdd (if doesn't exist)
            setGroupUserToAdd((prev) => [...prev, user]);
        }

        // Add to groupusers on currentGroup
        setCurrentGroup((prev) => ({
            ...prev,
            groupusers: [...prev.groupusers, user],
        }));
    };

    const handleRemoveUser = (user) => {
        // Should first check if user is in groupUsersToAdd and just remove from there if so
        if (groupUserToAdd.find((u) => u.email === user.email)) {
            setGroupUserToAdd((prev) =>
                prev.filter((u) => u.email !== user.email)
            );
        } else if (!groupUsersToRemove.find((u) => u.email === user.email)) {
            // Add to groupUsersToRemove (if doesn't exist)
            setGroupUsersToRemove((prev) => [...prev, user]);
        }

        // Remove from groupusers on currentGroup
        setCurrentGroup((prev) => ({
            ...prev,
            groupusers: prev.groupusers.filter((u) => u.email !== user.email),
        }));
    };

    const isGroupDetailsValid = () => {
        return currentGroup && currentGroup.name && currentGroup.description;
    };

    const handleSave = () => {
        if (!isGroupDetailsValid()) {
            setSnackbarDetails({
                severity: "error",
                message: `Please fill in all required fields to create this group`,
            });
            setSnackbarOpen(true);
            return;
        }

        onSave(currentGroup, groupUserToAdd, groupUsersToRemove);
    }

    useEffect(() => {
        if (!group) {
            return;
        }

        const isEdit = group && group.id;
        setInputStateChanged({
            name: isEdit,
            description: isEdit,
        });
        setCurrentGroup(group);
        setHasError(false);
        setGroupUserToAdd([]);
        setGroupUsersToRemove([]);
    }, [group]);

    useDebounce(() => {
        setHasError(!isGroupDetailsValid());
    }, [currentGroup], 200);

    const isEdit = group?.id;

    return (
        <Dialog
            className="create-edit-group-dialog"
            onClose={onClose}
            open={open}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>{isEdit ? "Edit" : "Create"} Group</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent className="create-edit-dialog-content">
                <TextField
                    required
                    fullWidth
                    className="text-input"
                    label="Group Name"
                    inputProps={{
                        maxLength: 128,
                    }}
                    value={currentGroup?.name || ""}
                    error={inputStateChanged.name && !currentGroup?.name}
                    helperText={
                        inputStateChanged.name && !currentGroup?.name
                            ? "Group name is required"
                            : ""
                    }
                    onChange={(ev) =>
                        setCurrentGroup((prev) => ({
                            ...prev,
                            name: ev.target.value,
                        }))
                    }
                    onBlur={() =>
                        setInputStateChanged((prev) => ({
                            ...prev,
                            name: true,
                        }))
                    }
                />
                <TextField
                    required
                    fullWidth
                    className="text-input"
                    label="Group Description"
                    inputProps={{
                        maxLength: 256,
                    }}
                    value={currentGroup?.description || ""}
                    error={
                        inputStateChanged.description &&
                        !currentGroup?.description
                    }
                    helperText={
                        inputStateChanged.description &&
                        !currentGroup?.description
                            ? "Group description is required"
                            : ""
                    }
                    onChange={(ev) =>
                        setCurrentGroup((prev) => ({
                            ...prev,
                            description: ev.target.value,
                        }))
                    }
                    onBlur={() =>
                        setInputStateChanged((prev) => ({
                            ...prev,
                            description: true,
                        }))
                    }
                />

                <Typography className="group-members-label" variant="h6">
                    Group Members
                </Typography>

                <UserSearch
                    addUser={handleAddUser}
                    users={currentGroup?.groupusers}
                />

                <div className="group-members-list">
                    {currentGroup?.groupusers?.map((user) => (
                        <Chip
                            key={`member-chip-${user.email}`}
                            className="member-chip"
                            label={user.email}
                            variant="outlined"
                            onDelete={() => handleRemoveUser(user)}
                        />
                    ))}
                </div>

                {groupUsersToRemove.length > 0 && (
                    <MuiAlert
                        severity="info"
                        className="member-removal-info-alert"
                    >
                        <div>
                            The following {groupUsersToRemove.length === 1 ? 'user' : 'users'} will be removed from the group
                            and will lose access to shared rules, templates, and
                            signatures. This will break any pesonal rules that
                            use templates and signatures shared with this group.
                        </div>

                        <ul>
                        {groupUsersToRemove.map((user) => (
                            <li key={`remove-user-${user.email}`}>{user.email}</li>
                        ))}
                        </ul>
                    </MuiAlert>
                )}

                <FormControlLabel
                    className="checkbox-input"
                    control={
                        <Checkbox
                            checked={
                                currentGroup?.allow_user_sharing || false
                            }
                            onChange={(ev) => {
                                setCurrentGroup((prev) => ({
                                    ...prev,
                                    allow_user_sharing: ev.target.checked,
                                }));
                            }}
                        />
                    }
                    label="Allow members to share rules, templates, and signatures with group"
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={hasError}>
                    {isEdit ? "Save" : "Create"}
                </Button>
            </DialogActions>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarDetails.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
}

// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import { STARTER_PLAN_MAX_RULES } from "../../helpers/constants";
import SearchBar from "../../components/SearchBar";
import RuleList from "./RuleList";
import CreateEditRule from "./CreateEditRule";
import { handleAttachments } from "../../helpers/ruleHelpers";

import "./styles.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Rules() {
    useDocumentTitle("Toggles Hub | Rules");
    const initialLoad = useRef(true);
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { isOrgUser, isOrgAdmin, user, organization, datastore, hasActiveSubscription } = useAuth();
    const [currentTab, setCurrentTab] = useState("personal");
    const [rules, setRules] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({
        severity: "success",
        message: "",
    });
    const [ruleToCreateOrEdit, setRuleToCreateOrEdit] = useState(null);
    const [ruleToDelete, setRuleToDelete] = useState(null);
    const [sharedRuleToDelete, setSharedRuleToDelete] = useState(null);
    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [availableSignatures, setAvailableSignatures] = useState([]);

    const fetchPersonalRules = async (query) => {
        try {
            const data = await datastore.api.getPersonalRules(query);

            setRules(data.rules);
            setTotalCount(data.count);
        } catch (err) {
            console.error("Error loading rules: ", err);
        }
    };

    const fetchGroupRules = async (query) => {
        try {
            const data = await datastore.api.getGroupRules(query);

            setRules(data.rules);
            setTotalCount(data.count);
        } catch (err) {
            console.error("Error loading rules: ", err);
        }
    };

    const fetchOrganizationRules = async (query) => {
        try {
            const data = await datastore.api.getOrganizationRules(query);

            setRules(data.rules);
            setTotalCount(data.count);
        } catch (err) {
            console.error("Error loading rules: ", err);
        }
    };

    const fetchOrgUnsharedRules = async (query) => {
        try {
            const data = await datastore.api.getOrgUnsharedRules(query);

            setRules(data.rules);
            setTotalCount(data.count);
        } catch (err) {
            console.error("Error loading rules: ", err);
        }
    };

    const fetchRules = async ({ pageToFetch, query }) => {
        setLoading(true);
        const queryData = {
            from: (pageToFetch - 1) * pageSize,
            to: pageToFetch * pageSize - 1,
        };

        if (query) {
            queryData.q = query;
        }

        if (currentTab === "personal") {
            await fetchPersonalRules(queryData);
        } else if (currentTab === "group") {
            await fetchGroupRules(queryData);
        } else if (currentTab === "organization") {
            await fetchOrganizationRules(queryData);
        } else if (currentTab === 'unshared') {
            await fetchOrgUnsharedRules(queryData);
        }

        setLoading(false);
    };

    const fetchTemplates = async () => {
        try {
            // Need to load personal and all org shared templates
            const results = await Promise.all([
                datastore.api.getPersonalTemplates({
                    from: 0,
                    to: 1000,
                    enabled: true,
                }),
                datastore.api.getAllSharedTemplates({
                    from: 0,
                    to: 1000,
                    enabled: true,
                }),
            ]);

            // Combine and remove duplicates
            const data = results.reduce((acc, result) => {
                return acc.concat(
                    result.templates.filter(
                        (template) => !acc.find((t) => t.id === template.id)
                    )
                );
            }, []);

            setAvailableTemplates(data);
        } catch (error) {
            console.error("Error loading templates: ", error);
            setAvailableTemplates([]);
        }
    };

    const fetchSignatures = async () => {
        try {
            // Need to load personal and all org shared templates
            const results = await Promise.all([
                datastore.api.getPersonalSignatures({
                    from: 0,
                    to: 1000,
                    enabled: true,
                }),
                datastore.api.getAllSharedSignatures({
                    from: 0,
                    to: 1000,
                    enabled: true,
                }),
            ]);

            // Combine and remove duplicates
            const data = results.reduce((acc, result) => {
                return acc.concat(
                    result.signatures.filter(
                        (signature) => !acc.find((s) => s.id === signature.id)
                    )
                );
            }, []);

            setAvailableSignatures(data);
        } catch (error) {
            console.error("Error loading signatures: ", error);
            setAvailableSignatures([]);
        }
    };

    const loadPage = (pageToLoad) => {
        fetchRules({ pageToFetch: pageToLoad, query: searchQuery });
        setPage(pageToLoad);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    };

    const handleCreate = async () => {
        if (!hasActiveSubscription() && rules.length >= STARTER_PLAN_MAX_RULES) {
            // Show error message to upgrade to Premium
            setSnackbarDetails({
                severity: "warning",
                message: `You have reached the maximum number of rules for a free account. Upgrade to Premium to create unlimited rules.`,
            });
            setSnackbarOpen(true);
            return;
        }

        const newRule = {
            name: "",
            description: "",
            metadata: {
                trigger: "toggle",
                actions: [],
            },
            sharedrules: [],
            enabled: true,
        };

        if (currentTab === "organization") {
            newRule.sharedrules.push({
                rule_id: null,
                organization_id: organization.id,
                editable: false,
            });
        }
        
        setRuleToCreateOrEdit(newRule);
    };

    const handleEdit = (rule) => {
        setRuleToCreateOrEdit(rule)
    };

    const handleSave = async (rule) => {
        setIsSaving(true);

        const isNew = !rule.id;
        let savedRule;

        if (isNew) {
            const addedSharedRules = rule.sharedrules.map((r) => ({
                rule_id: r.id,
                user_id: r.users?.id,
                group_id: r.groups?.id,
                organization_id: r.organization_id,
                editable: r.editable,
            }));

            // Handle attachments
            let ruleWithAttachments = rule;
            try {
                const entityId = isOrgUser() ? organization.id : user.id;
                ruleWithAttachments = await handleAttachments(rule, false, entityId, datastore);
            } catch (err) {
                console.error("error creating new rule", err);
                setSnackbarDetails({
                    severity: "error",
                    message: `Error ${isNew ? "creating" : "updating"} "${
                        rule.name
                    }"`,
                });
                setSnackbarOpen(true);
                setIsSaving(false);
                return;
            }

            // Create
            try {
                const newRule = await datastore.api.createRule({
                    ...ruleWithAttachments,
                    sharedrules: addedSharedRules,
                });

                savedRule = newRule;
            } catch (error) {
                console.error("error creating new rule", error);
                setSnackbarDetails({
                    severity: "error",
                    message: `Error ${isNew ? "creating" : "updating"} "${
                        rule.name
                    }"`,
                });
                setSnackbarOpen(true);
                setIsSaving(false);
                return;
            }
        } else {
            const { addedSharedRules, removedSharedRules } = getSharedRuleChanges(rule);

            // Handle attachments
            let ruleWithAttachments = rule;
            try {
                const entityId = isOrgUser() ? organization.id : user.id;
                ruleWithAttachments = await handleAttachments(rule, true, entityId, datastore);
            } catch (err) {
                console.error("error updating existing rule", err);
                setSnackbarDetails({
                    severity: "error",
                    message: `Error ${isNew ? "creating" : "updating"} "${
                        rule.name
                    }"`,
                });
                setSnackbarOpen(true);
                setIsSaving(false);
                return;
            }

            // Update
            try {
                const updatedRule = await datastore.api.updateRule({
                    ...ruleWithAttachments,
                    added_sharedrules: addedSharedRules,
                    removed_sharedrules: removedSharedRules,
                });
                savedRule = updatedRule;
            } catch (error) {
                console.error("error updating existing rule", error);
                setSnackbarDetails({
                    severity: "error",
                    message: `Error ${isNew ? "creating" : "updating"} "${
                        rule.name
                    }"`,
                });
                setSnackbarOpen(true);
                setIsSaving(false);
                return;
            }
        }

        const ruleType = (!savedRule.sharedrules || savedRule.sharedrules.length === 0)
            ? savedRule.organization_id
                ? "unshared"
                : "personal"
            : savedRule.sharedrules[0].organization_id
                ? "organization"
                : "group";
        if (ruleType === currentTab) {
            setRules((prev) => {
                const index = prev.findIndex((r) => r.id === savedRule.id);
                if (index === -1) {
                    return [savedRule, ...prev];
                }

                const newRules = [...prev];
                newRules[index] = savedRule;
                return newRules;
            });
        } else {
            // Change currentTab to the type of the saved rule
            setCurrentTab(ruleType);
        }

        setSnackbarDetails({
            severity: "success",
            message: `Rule "${savedRule.name}" ${
                isNew ? "created" : "updated"
            } successfully`,
        });
        setSnackbarOpen(true);
        setIsSaving(false);
        setRuleToCreateOrEdit(null);
    };

    const getSharedRuleChanges = (changedRule) => {
        const original = ruleToCreateOrEdit.sharedrules;
        const changed = changedRule.sharedrules;

        const deleted = original.filter(
            (o) => !changed.some((c) => c.id === o.id)
        );
        const updated = changed.filter((c) =>
            original.some((o) => c.id === o.id && !isSharedRuleEqual(c, o))
        );
        const added = changed.filter(
            (c) => !original.some((o) => c.id === o.id)
        );

        let addedSharedRules = [];
        let removedSharedRules = [];

        [...added, ...updated].forEach((changedItem) => {
            const addedSharedRule = {
                rule_id: changedItem.rule_id,
                user_id: changedItem.users?.id,
                group_id: changedItem.groups?.id,
                organization_id: changedItem.organization_id,
                editable: changedItem.editable,
            };

            if (changedItem.id) {
                addedSharedRule.id = changedItem.id;
            }
            
            addedSharedRules.push(addedSharedRule);
        });

        deleted.forEach((originalItem) => {
            removedSharedRules.push({
                id: originalItem.id,
                rule_id: originalItem.rule_id,
                user_id: originalItem.users?.id,
                group_id: originalItem.groups?.id,
                organization_id: originalItem.organization_id,
                editable: originalItem.editable,
            });
        });

        return { addedSharedRules, removedSharedRules };
    };

    const isSharedRuleEqual = (sr1, sr2) => {
        const { id: id1, ...rest1 } = sr1;
        const { id: id2, ...rest2 } = sr2;

        // Set related entity ids
        rest1.user_id = sr1.users?.id;
        rest1.group_id = sr1.groups?.id;
        rest1.organization_id = sr1.organization_id || null;

        rest2.user_id = sr2.users?.id;
        rest2.group_id = sr2.groups?.id;
        rest2.organization_id = sr2.organization_id || null;

        // Delete expanded fields (organizations, users, groups)
        delete rest1.organizations;
        delete rest1.users;
        delete rest1.groups;
        delete rest2.organizations;
        delete rest2.users;
        delete rest2.groups;

        return Object.entries(rest2).every(
            ([key, value]) => rest1.hasOwnProperty(key) && rest1[key] === value
        );
    };

    const handleCancelCreateOrEdit = () => {
        setRuleToCreateOrEdit(null);
    };

    const handleDeleteConfirm = async () => {
        if (ruleToDelete) {
            await deleteRule();
        } else if (sharedRuleToDelete) {
            await deleteSharedRule();
        }
    };

    const handleDeleteCancel = () => {
        setRuleToDelete(null);
        setSharedRuleToDelete(null);
    };

    const handleDeleteRule = (ruleId) => {
        setRuleToDelete(ruleId);
    };

    const deleteRule = async () => {
        try {
            const ruleItemToDelete = rules.find((r) => r.id === ruleToDelete);
            const isPersonalRule = !ruleItemToDelete.organization_id && (!ruleItemToDelete.sharedrules || ruleItemToDelete.sharedrules.length === 0);
            await datastore.api.deleteRule(ruleToDelete, isPersonalRule);
        } catch (error) {
            console.error("error deleting rule", error);
            setRuleToDelete(null);
            setSnackbarDetails({
                severity: "error",
                message: `Error deleting rule`,
            });
            setSnackbarOpen(true);
            return;
        }

        setSnackbarDetails({
            severity: "success",
            message: `Rule deleted successfully`,
        });
        setSnackbarOpen(true);

        setRules((prev) => prev.filter((r) => r.id !== ruleToDelete));
        setRuleToDelete(null);
    };

    const handleDeleteSharedRule = (ruleId, sharedRuleId) => {
        setSharedRuleToDelete({ rule: ruleId, shared: sharedRuleId });
    };

    const deleteSharedRule = async () => {
        try {
            await datastore.api.deleteSharedRule(sharedRuleToDelete.shared);
        } catch (error) {
            console.error("error deleting shared rule", error);
            setSharedRuleToDelete(null);
            setSnackbarDetails({
                severity: "error",
                message: `Error deleting shared rule`,
            });
            setSnackbarOpen(true);
            return;
        }

        setSnackbarDetails({
            severity: "success",
            message: `Shared rule deleted successfully`,
        });
        setSnackbarOpen(true);

        setRules((prev) => {
            const index = prev.findIndex((r) => r.id === sharedRuleToDelete.rule);
            if (index === -1) {
                return prev;
            }

            const newRules = [...prev];
            newRules[index].sharedrules = newRules[index].sharedrules.filter(
                (r) => r.id !== sharedRuleToDelete.shared
            );

            if (newRules[index].sharedrules.length === 0) {
                newRules.splice(index, 1);
            }
            
            return newRules;
        });

        setSharedRuleToDelete(null);
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setSnackbarOpen(false);
    };

    useEffect(() => {
        async function loadData() {
            await Promise.all([
                fetchRules({ pageToFetch: page, query: searchQuery }),
                fetchTemplates(),
                fetchSignatures(),
            ]);
        }

        loadData();
    }, []);

    useDebounce(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
        } else {
            setPage(1);
            fetchRules({ pageToFetch: 1, query: searchQuery })
        }
    }, [searchQuery], 500);

    useEffect(() => {
        setPage(1);
        fetchRules({ pageToFetch: 1, query: searchQuery });
    }, [currentTab]);

    return (
        <Container className="rules-container table-view" maxWidth={false}>
            <Box className="list-nav" sx={{ width: "100%" }}>
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                >
                    <Tab value="personal" label="Personal" iconPosition="end" />
                    {organization && (
                        <Tab value="group" label="Group" iconPosition="end" />
                    )}
                    {organization && (
                        <Tab
                            value="organization"
                            label="Organization"
                            iconPosition="end"
                        />
                    )}
                    {organization && isOrgAdmin() && (
                        <Tab
                            value="unshared"
                            label="Unshared"
                            iconPosition="end"
                        />
                    )}
                </Tabs>
            </Box>

            <Grid className="table-actions" container spacing={2}>
                <Grid item sm={9} xs={12}>
                    <SearchBar
                        setSearchTerm={setSearchQuery}
                        placeholder={`Search ${currentTab} rules`}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() => handleCreate()}
                    >
                        Create Rule
                    </Button>
                </Grid>
            </Grid>

            <RuleList
                rules={rules}
                templates={availableTemplates}
                signatures={availableSignatures}
                loading={loading}
                isPersonal={currentTab === "personal"}
                setPage={loadPage}
                totalCount={totalCount}
                page={page}
                pageSize={pageSize}
                handleEdit={handleEdit}
                handleDelete={handleDeleteRule}
                handleRemoveSharedRule={handleDeleteSharedRule}
            />

            {!!ruleToCreateOrEdit && (
                <CreateEditRule
                    rule={ruleToCreateOrEdit}
                    allAvailableTemplates={availableTemplates}
                    allAvailableSignatures={availableSignatures}
                    organization={organization}
                    open={ruleToCreateOrEdit !== null}
                    onClose={handleCancelCreateOrEdit}
                    onSave={handleSave}
                    isSaving={isSaving}
                />
            )}

            <Dialog
                open={ruleToDelete !== null || sharedRuleToDelete !== null}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {ruleToDelete
                            ? "Are you sure you wish to delete this rule"
                            : "Are you sure you wish to remove this rule from the group"}
                        ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarDetails.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

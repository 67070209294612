// @ts-nocheck
import React, { useState, useEffect, useRef, useMemo } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useAuth } from "../../../hooks/useAuth";
import RTEditor from "../../../components/RTEditor/RTEditor";
import ContentPreview from "../../../components/ContentPreview/ContentPreview";
import RTEInstructions from "../../../components/RTEIntstructions/RTEInstructions";
import { parseVariables } from "../../../components/VariableTypes/VariableTypes";
import VariableTypeSelection from "../../../components/VariableTypes/VariableTypeSelection";
import useDebounce from "../../../hooks/useDebounce";

import "./styles.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CreateEditTemplate = ({
    open,
    onClose,
    onSave,
    isSaving,
    template,
    organization,
}) => {
    const { datastore, user, isOrgUser, isOrgAdmin } = useAuth();
    const initialLoad = useRef(true);
    const [currentTemplate, setCurrentTemplate] = useState(
        JSON.parse(JSON.stringify(template))
    );
    const [availableGroups, setAvailableGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [isPersonal, setIsPersonal] = useState(true);
    const [isSharedWithGroups, setIsSharedWithGroups] = useState(false);
    const [isSharedWithOrg, setIsSharedWithOrg] = useState(false);
    const [isSharedEditable, setIsSharedEditable] = useState(
        template?.sharedtemplates[0]?.editable || false
    );
    const [activeStep, setActiveStep] = useState(0);
    const [hasError, setHasError] = useState(false);
    const [inputStateChanged, setInputStateChanged] = useState({
        name: false,
        description: false,
    });
    const [hasVariableErrors, setHasVariableErrors] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({
        severity: "success",
        message: "",
    });
    const dialogRef = useRef(null);

    const isEdit = useMemo(() => {
        return !!currentTemplate && !!currentTemplate.id;
    }, [currentTemplate]);

    const isTemplateOwner = useMemo(() => {
        return (
            !!currentTemplate &&
            ((currentTemplate.user_id && currentTemplate.user_id === user.id) ||
                (isOrgAdmin() && !currentTemplate.user_id))
        );
    }, [currentTemplate, user]);

    const isPremiumExample = useMemo(() => {
        return !!currentTemplate && currentTemplate.metadata?.premium_example;
    });

    const isSharingAvailable = useMemo(() => {
        return !isPremiumExample && (isOrgAdmin() || (isOrgUser() && (isTemplateOwner || !currentTemplate?.id)));
    }, [currentTemplate, user, isTemplateOwner]);

    const canEditTemplate = useMemo(() => {
        return !isEdit || (!isPremiumExample && (isTemplateOwner || isSharedEditable));
    }, [isEdit, user, isSharedEditable]);

    const isOrgUnshared = useMemo(() => {
        return !!currentTemplate && currentTemplate.organization_id && (!currentTemplate.sharedtemplates || currentTemplate.sharedtemplates.length === 0);
    }, [currentTemplate]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const loadGroups = async () => {
        try {
            const results = await datastore.api.getGroups({
                select: "id, name, allow_user_sharing",
                from: 0,
                to: 1000,
            });

            if (isOrgAdmin()) {
                // Org Admins can share with any group
                setAvailableGroups(results.groups);
                return;
            }

            // Filter out groups without sharing enabled
            const filteredGroups = results.groups.filter(
                (group) => group.allow_user_sharing
            );

            setAvailableGroups(filteredGroups);
        } catch (error) {
            console.error("Error loading groups: ", error);
            return [];
        }
    };

    const handleTemplateEnabledToggle = (ev) => {
        const updatedTemplate = { ...currentTemplate };
        updatedTemplate.enabled = ev.target.checked;
        setCurrentTemplate(updatedTemplate);
    };

    const handlePersonalTemplateToggle = (ev) => {
        setIsPersonal(ev.target.checked);

        if (ev.target.checked) {
            refreshToPersonalTemplate();
        }
    };

    const hanldeSharedWithGroupsToggles = (ev) => {
        if (ev.target.checked) {
            // Remove all shared templates
            setIsPersonal(false);
            setIsSharedWithOrg(false);
        }

        setIsSharedWithGroups(ev.target.checked);

        if (!ev.target.checked) {
            refreshToPersonalTemplate();
        }
    };

    const handleSharedWithOrgToggle = (ev) => {
        if (ev.target.checked) {
            // Remove all shared templates
            const updatedTemplate = { ...currentTemplate };
            updatedTemplate.sharedtemplates = [];
            setCurrentTemplate(updatedTemplate);
            setSelectedGroups([]);
            setIsSharedEditable(false);
            setIsPersonal(false);
            setIsSharedWithGroups(false);
        }

        setIsSharedWithOrg(ev.target.checked);

        if (!ev.target.checked) {
            refreshToPersonalTemplate();
        }
    };

    const refreshToPersonalTemplate = () => {
        // Remove all shared templates
        const updatedTemplate = { ...currentTemplate };
        updatedTemplate.sharedtemplates = [];
        setCurrentTemplate(updatedTemplate);
        setIsSharedEditable(false);
        setSelectedGroups([]);
        setIsSharedWithOrg(false);
        setIsSharedWithGroups(false);
    };

    const getOrCreateSharedTemplate = (newShared) => {
        // Check template.sharedtemplates for a matching sharedtemplate (minus "id" field)
        const existingSharedTemplate = currentTemplate.sharedtemplates.find((st) => {
            const stCopy = { ...st };
            delete stCopy.id;
            delete stCopy.user_id;
            delete stCopy.group_id;
            return (
                stCopy.organization_id === newShared.organization_id &&
                JSON.stringify(stCopy.groups) ===
                    JSON.stringify(newShared.groups) &&
                stCopy.editable === newShared.editable &&
                stCopy.template_id === newShared.template_id
            );
        });

        return existingSharedTemplate ? existingSharedTemplate : newShared;
    };

    const handleClose = () => {
        if (!isSaving) {
            onClose();
        }
    };

    const handleSave = () => {
        if (!isTemplateDetailsValid() || !isTemplateValid()) {
            setSnackbarDetails({
                severity: "error",
                message: `Please fill in all required fields to create this template`,
            });
            setSnackbarOpen(true);
            return;
        }

        const validTemplate = { ...currentTemplate };

        // Parse out variables from the template content
        validTemplate.variables = getTemplateVariables();

        onSave(validTemplate);
    };

    const getTemplateVariables = () => {
        if (!currentTemplate.metadata || !currentTemplate.metadata.variables) {
            return [];
        }

        return currentTemplate.metadata.variables.map((variable) => variable.name);
    };

    const isTemplateDetailsValid = () => {
        return (
            currentTemplate &&
            currentTemplate.name &&
            currentTemplate.description
        );
    };

    const isTemplateValid = () => {
        let isValid = currentTemplate.html_content && currentTemplate.html_content.length > 0;

        if (currentTemplate.metadata && currentTemplate.metadata.variables) {
            // Check if any variables are "dropdown" type and have no options or options that are empty strings
            const invalidDropdowns = currentTemplate.metadata.variables.filter(
                (variable) =>
                    variable.type === "dropdown" &&
                    (!variable.options || variable.options.some((o) => !o))
            );

            if (invalidDropdowns.length > 0) {
                isValid = false;
            }

            if (hasVariableErrors) {
                isValid = false;
            }
        }

        return isValid;
    };

    const groupTemplateHasGroups = () => {
        if (!isSharedWithGroups) {
            return true;
        }

        return selectedGroups.length > 0;
    };

    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    useEffect(() => {
        loadGroups();
    }, []);

    useEffect(() => {
        if (!template) {
            return;
        }

        const isEdit = !!template && !!template.id;
        setInputStateChanged({
            name: isEdit,
            description: isEdit,
        });
        setCurrentTemplate(JSON.parse(JSON.stringify(template)));
        setActiveStep(0);
        setHasError(false);

        // Determine groups template is shared with and set selectedGroups
        if (template.sharedtemplates?.length > 0) {
            const sGroups = [];
            let sharedWithOrg = false;
            template.sharedtemplates.forEach((sr) => {
                if (sr.groups) {
                    sGroups.push(sr.groups);
                } else if (sr.organization_id) {
                    sharedWithOrg = true;
                }
            });

            setIsPersonal(
                !!template.user_id && template.sharedtemplates?.length === 0
            );
            setSelectedGroups(sGroups);
            setIsSharedWithOrg(sharedWithOrg);
            setIsSharedWithGroups(sGroups.length > 0);
            setIsSharedEditable(template.sharedtemplates[0].editable);
        } else {
            setSelectedGroups([]);
            setIsPersonal(!isOrgUnshared);
            setIsSharedWithOrg(false);
            setIsSharedEditable(false);
        }
    }, [template]);

    useEffect(() => {
        // Set on all sharedtemplates
        const updatedTemplate = { ...currentTemplate };
        updatedTemplate.sharedtemplates.forEach((sr) => {
            sr.editable = isSharedEditable;
        });
        setCurrentTemplate(updatedTemplate);
    }, [isSharedEditable]);

    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }

        if (isSharedWithOrg) {
            const updatedTemplate = { ...currentTemplate };
            updatedTemplate.sharedtemplates = [
                getOrCreateSharedTemplate({
                    template_id: currentTemplate.id,
                    organization_id: organization.id,
                    groups: null,
                    editable: isSharedEditable,
                }),
            ];
            setSelectedGroups([]);
            setCurrentTemplate(updatedTemplate);
        } else {
            const updatedTemplate = { ...currentTemplate };
            updatedTemplate.sharedtemplates = [];
            setCurrentTemplate(updatedTemplate);
        }
    }, [isSharedWithOrg]);

    useEffect(() => {
        if (!isPersonal && !isSharedWithGroups && !isSharedWithOrg) {
            setIsPersonal(!isOrgUnshared);
            refreshToPersonalTemplate();
        }
    }, [isPersonal, isSharedWithGroups, isSharedWithOrg, isOrgUnshared]);

    useEffect(() => {
        // Parse variables from templateContent
        const variables = parseVariables(currentTemplate.html_content);

        const newVariableData = variables.map((variable) => {
            return {
                name: variable,
                type: "text",
                default: "",
            };
        });

        // Create a new set of variableData and use existing data if matching name
        const updatedVariableData = newVariableData.map((variable) => {
            const existingVariable = currentTemplate.metadata?.variables?.find(
                (v) => v.name === variable.name
            );
            return existingVariable ? existingVariable : variable;
        });

        setCurrentTemplate((prev) => ({
            ...prev,
            metadata: {
                ...prev.metadata,
                variables: updatedVariableData,
            },
        }));
    }, [currentTemplate?.html_content]);

    useDebounce(
        () => {
            setHasError(
                !isTemplateDetailsValid() ||
                    !isTemplateValid() ||
                    !groupTemplateHasGroups()
            );
        },
        [currentTemplate, selectedGroups, isSharedWithGroups, isSharedWithOrg],
        300
    );

    if (!currentTemplate) {
        return null;
    }

    return (
        <Dialog
            className="create-edit-template-dialog"
            onClose={handleClose}
            open={open}
            fullWidth
            maxWidth="lg"
            ref={dialogRef}
        >
            <DialogTitle>{isEdit ? "Edit" : "Create"} Template</DialogTitle>

            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent className="create-edit-dialog-content">
                {template?.sharedtemplates?.length > 0 &&
                    isEdit &&
                    canEditTemplate && (
                        <MuiAlert className="shared-warning" severity="warning">
                            Any changes to this template will affect all{" "}
                            {isSharedWithOrg ? "users" : "groups"} it is shared
                            with
                        </MuiAlert>
                    )}
                {isEdit && !canEditTemplate && (
                    <MuiAlert className="shared-warning" severity="warning">
                        You do not have permission to edit this {isPremiumExample ? 'example' : 'shared'} template.
                    </MuiAlert>
                )}
                <Stepper activeStep={activeStep} orientation="vertical">
                    {/* STEP: DETAILS */}
                    <Step key="step-details">
                        <StepLabel>Template Details</StepLabel>
                        <StepContent className="step-content">
                            <TextField
                                required
                                fullWidth
                                className="text-input"
                                label="Template Name"
                                inputProps={{
                                    maxLength: 128,
                                }}
                                value={currentTemplate?.name || ""}
                                disabled={!canEditTemplate}
                                error={
                                    inputStateChanged.name &&
                                    !currentTemplate.name
                                }
                                helperText={
                                    inputStateChanged.name &&
                                    !currentTemplate.name
                                        ? "Template name is required"
                                        : ""
                                }
                                onChange={(ev) =>
                                    setCurrentTemplate((prev) => ({
                                        ...prev,
                                        name: ev.target.value,
                                    }))
                                }
                                onBlur={() =>
                                    setInputStateChanged((prev) => ({
                                        ...prev,
                                        name: true,
                                    }))
                                }
                            />
                            <TextField
                                required
                                fullWidth
                                className="text-input"
                                label="Template Description"
                                inputProps={{
                                    maxLength: 256,
                                }}
                                value={currentTemplate?.description || ""}
                                disabled={!canEditTemplate}
                                error={
                                    inputStateChanged.description &&
                                    !currentTemplate.description
                                }
                                helperText={
                                    inputStateChanged.description &&
                                    !currentTemplate.description
                                        ? "Template description is required"
                                        : ""
                                }
                                onChange={(ev) =>
                                    setCurrentTemplate((prev) => ({
                                        ...prev,
                                        description: ev.target.value,
                                    }))
                                }
                                onBlur={() =>
                                    setInputStateChanged((prev) => ({
                                        ...prev,
                                        description: true,
                                    }))
                                }
                            />
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        disabled={
                                            !isTemplateDetailsValid() ||
                                            isSaving
                                        }
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>

                    {/* STEP: Template Content */}
                    <Step key="step-template-content">
                        <StepLabel>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                Template Content
                                <RTEInstructions />
                                {currentTemplate?.html_content && (
                                    <ContentPreview
                                        htmlString={
                                            currentTemplate.html_content
                                        }
                                    />
                                )}
                            </Box>
                        </StepLabel>
                        <StepContent className="step-content">
                            {/* Template Editor */}
                            <RTEditor
                                content={currentTemplate.html_content}
                                saveContent={(htmlContent) => {
                                    setCurrentTemplate((prev) => ({
                                        ...prev,
                                        html_content: htmlContent,
                                    }));
                                }}
                                modalContainer={dialogRef}
                                canEdit={canEditTemplate}
                                datastore={datastore}
                                uploadEntityId={
                                    isOrgUser() ? organization.id : user.id
                                }
                            />
                            <VariableTypeSelection
                                variables={currentTemplate?.metadata?.variables}
                                canEdit={canEditTemplate}
                                handleChanges={(updatedData) => {
                                    setCurrentTemplate((prev) => ({
                                        ...prev,
                                        metadata: {
                                            ...prev.metadata,
                                            variables: updatedData,
                                        },
                                    }));
                                }}
                                setIsValid={(isValid) => setHasVariableErrors(!isValid)}
                            />
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={
                                            isSharingAvailable
                                                ? handleNext
                                                : handleSave
                                        }
                                        disabled={
                                            !isTemplateValid() || isSaving
                                        }
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {isSharingAvailable
                                            ? "Next"
                                            : isEdit
                                            ? "Save Template"
                                            : "Create Template"}
                                    </Button>
                                    <Button
                                        onClick={handleBack}
                                        disabled={isSaving}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>

                    {/* STEP: SHARING */}
                    {isSharingAvailable && (
                        <Step key="step-sharing">
                            <StepLabel>Template Sharing</StepLabel>
                            <StepContent className="step-content">
                                <FormControlLabel
                                    className="org-shared-toggle"
                                    control={
                                        <Switch
                                            checked={currentTemplate?.enabled}
                                            onChange={
                                                handleTemplateEnabledToggle
                                            }
                                            inputProps={{
                                                "aria-label":
                                                    "Template enabled toggle switch",
                                            }}
                                        />
                                    }
                                    label="Template enabled"
                                />
                                {(!currentTemplate?.id ||
                                    (isTemplateOwner &&
                                        !currentTemplate?.organization_id) ||
                                    (!isSharedWithGroups &&
                                        !isSharedWithOrg)) &&
                                    !isOrgUnshared && (
                                        <FormControlLabel
                                            className="org-shared-toggle"
                                            control={
                                                <Switch
                                                    checked={isPersonal}
                                                    onChange={
                                                        handlePersonalTemplateToggle
                                                    }
                                                    disabled={
                                                        !isSharedWithGroups &&
                                                        !isSharedWithOrg
                                                    }
                                                    inputProps={{
                                                        "aria-label":
                                                            "Peronsal template toggle switch",
                                                    }}
                                                />
                                            }
                                            label="Personal Template"
                                        />
                                    )}

                                {isOrgAdmin() && (
                                    <FormControlLabel
                                        className="org-shared-toggle"
                                        control={
                                            <Switch
                                                checked={
                                                    isSharedWithOrg || false
                                                }
                                                disabled={
                                                    isEdit &&
                                                    isSharedWithOrg &&
                                                    template.sharedtemplates
                                                        .length > 0
                                                }
                                                onChange={
                                                    handleSharedWithOrgToggle
                                                }
                                                inputProps={{
                                                    "aria-label":
                                                        "Shared template with entire organization toggle switch",
                                                }}
                                            />
                                        }
                                        label="Share template with entire organization"
                                    />
                                )}

                                <FormControlLabel
                                    className="org-shared-toggle"
                                    control={
                                        <Switch
                                            checked={
                                                isSharedWithGroups || false
                                            }
                                            disabled={
                                                isEdit &&
                                                isSharedWithGroups &&
                                                template.sharedtemplates
                                                    .length > 0
                                            }
                                            onChange={
                                                hanldeSharedWithGroupsToggles
                                            }
                                            inputProps={{
                                                "aria-label":
                                                    "Shared template with groups toggle switch",
                                            }}
                                        />
                                    }
                                    label="Share template with groups"
                                />

                                {isSharedWithGroups && (
                                    <>
                                        <FormControlLabel
                                            className="allow-editing-toggle"
                                            control={
                                                <Switch
                                                    checked={
                                                        isSharedEditable ||
                                                        false
                                                    }
                                                    onChange={(ev) => {
                                                        setIsSharedEditable(
                                                            ev.target.checked
                                                        );
                                                    }}
                                                    inputProps={{
                                                        "aria-label":
                                                            "Allow editing of template by group members toggle switch",
                                                    }}
                                                    disabled={
                                                        isEdit &&
                                                        !isTemplateOwner &&
                                                        template.sharedtemplates
                                                            .length > 0
                                                    }
                                                />
                                            }
                                            label="Allow editing by shared group members"
                                        />

                                        <Autocomplete
                                            className="group-members-list"
                                            multiple
                                            disableCloseOnSelect
                                            disableClearable={isEdit}
                                            getOptionLabel={(option) =>
                                                typeof option === "string"
                                                    ? option
                                                    : option.name
                                            }
                                            options={availableGroups}
                                            noOptionsText="No groups found"
                                            value={selectedGroups}
                                            getOptionDisabled={(option) =>
                                                !isOrgAdmin() &&
                                                isTemplateOwner &&
                                                template.sharedtemplates.some(
                                                    (r) =>
                                                        r.groups?.id ===
                                                        option.id
                                                )
                                            }
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.id === value.id}
                                            onChange={(event, newValue) => {
                                                const updatedTemplate = {
                                                    ...currentTemplate,
                                                };

                                                const updatedSharedTemplates =
                                                    newValue.map((group) =>
                                                        getOrCreateSharedTemplate(
                                                            {
                                                                template_id:
                                                                    currentTemplate.id,
                                                                groups: group,
                                                                organization_id:
                                                                    null,
                                                                editable:
                                                                    isSharedEditable,
                                                            }
                                                        )
                                                    );

                                                updatedTemplate.sharedtemplates =
                                                    updatedSharedTemplates;
                                                setCurrentTemplate(
                                                    updatedTemplate
                                                );

                                                setSelectedGroups(newValue);
                                            }}
                                            renderTags={(
                                                tagValue,
                                                getTagProps
                                            ) =>
                                                tagValue.map(
                                                    (option, index) => {
                                                        const {
                                                            key,
                                                            ...tagProps
                                                        } = getTagProps({
                                                            index,
                                                        });
                                                        return (
                                                            <Chip
                                                                key={key}
                                                                label={
                                                                    option.name
                                                                }
                                                                {...tagProps}
                                                                disabled={
                                                                    !isOrgAdmin() &&
                                                                    !isTemplateOwner &&
                                                                    template.sharedtemplates.some(
                                                                        (r) =>
                                                                            r
                                                                                .groups
                                                                                ?.id ===
                                                                            option.id
                                                                    )
                                                                }
                                                            />
                                                        );
                                                    }
                                                )
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Share template with group"
                                                    fullWidth
                                                />
                                            )}
                                            renderOption={(
                                                props,
                                                option,
                                                { selected }
                                            ) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={
                                                            <CheckBoxOutlineBlankIcon fontSize="small" />
                                                        }
                                                        checkedIcon={
                                                            <CheckBoxIcon fontSize="small" />
                                                        }
                                                        style={{
                                                            marginRight: 8,
                                                        }}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </li>
                                            )}
                                        />
                                    </>
                                )}

                                <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleSave}
                                            disabled={hasError || isSaving}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {isEdit
                                                ? "Save Template"
                                                : "Create Template"}
                                        </Button>
                                        <Button
                                            onClick={handleBack}
                                            disabled={isSaving}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    )}
                </Stepper>
            </DialogContent>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarDetails.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default CreateEditTemplate;

// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import SearchBar from "../../components/SearchBar";
import GroupList from "./GroupList";
import CreateEditGroup from "./CreateEditGroup";

import "./styles.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Groups() {
    useDocumentTitle("Toggles Hub | Groups");
    const initialLoad = useRef(true);
    const [loading, setLoading] = useState(false);
    const { organization, datastore } = useAuth();
    const [groups, setGroups] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({
        severity: "success",
        message: "",
    });
    const [groupToCreateOrEdit, setGroupToCreateOrEdit] = useState(null);
    const [deletionType, setDeletionType] = useState(null);
    const [groupToDelete, setGroupToDelete] = useState(null);
    const [sharedRuleToDelete, setSharedRuleToDelete] = useState(null);
    const [sharedTemplateToDelete, setSharedTemplateToDelete] = useState(null);
    const [sharedSignatureToDelete, setSharedSignatureToDelete] = useState(null);

    const fetchGroups = async ({ pageToFetch, query }) => {
        setLoading(true);
        const queryData = {
            from: (pageToFetch - 1) * pageSize,
            to: pageToFetch * pageSize - 1,
        };

        if (query) {
            queryData.q = query;
        }

        try {
            const data = await datastore.api.getGroups(queryData);
            setGroups(data.groups);
            setTotalCount(data.count);
        } catch (error) {
            // TODO: Handle error
            console.error("error", error);
        }

        setLoading(false);
    };

    const loadPage = (pageToLoad) => {
        fetchGroups({ pageToFetch: pageToLoad, query: searchQuery });
        setPage(pageToLoad);
    };

    const handleCreate = async () => {
        setGroupToCreateOrEdit({
            name: '',
            description: '',
            organization_id: organization.id,
            allow_user_sharing: false,
            groupusers: [],
        });
    };

    const handleEdit = (group) => {
        setGroupToCreateOrEdit(group)
    };

    const handleSave = async (group, membersToAdd, membersToRemove) => {
        const isNew = !group.id;
        let savedGroup;

        if (isNew) {
            // Create
            try {
                const newGroup = await datastore.api.createGroup({
                    ...group,
                    groupusers: membersToAdd.map((u) => u.id),
                });
                savedGroup = newGroup;
            } catch (error) {
                console.error("error creating new group", error);
                setSnackbarDetails({
                    severity: "error",
                    message: `Error ${isNew ? "creating" : "updating"} "${
                        group.name
                    }"`,
                });
                setSnackbarOpen(true);
                return;
            }
        } else {
            // Update
            try {
                const updatedGroup = await datastore.api.updateGroup({
                    ...group,
                    added_groupusers: membersToAdd.map((u) => u.id),
                    removed_groupusers: membersToRemove.map((u) => u.id),
                });
                savedGroup = updatedGroup;
            } catch(error) {
                console.error("error creating new group", error);
                setSnackbarDetails({
                    severity: "error",
                    message: `Error ${isNew ? "creating" : "updating"} "${
                        group.name
                    }"`,
                });
                setSnackbarOpen(true);
                return;
            }
        }

        setGroups((prev) => {
            const index = prev.findIndex((g) => g.id === savedGroup.id);
            if (index === -1) {
                return [...prev, savedGroup];
            }

            const newGroups = [...prev];
            newGroups[index] = savedGroup;
            return newGroups;
        });

        setSnackbarDetails({
            severity: "success",
            message: `Group "${savedGroup.name}" ${
                isNew ? "created" : "updated"
            } successfully`,
        });
        setSnackbarOpen(true);
        setGroupToCreateOrEdit(null);
    }

    const handleCancelCreateOrEdit = () => {
        setGroupToCreateOrEdit(null);
    };

    const handleDeleteConfirm = async () => {
        if (groupToDelete) {
            await deleteGroup();
        } else if (sharedRuleToDelete) {
            await deleteSharedRule();
        } else if (sharedTemplateToDelete) {
            await deleteSharedTemplate();
        } else if (sharedSignatureToDelete) {
            await deleteSharedSignature();
        }
    };

    const handleDeleteCancel = () => {
        setDeletionType(null);
        setGroupToDelete(null);
        setSharedRuleToDelete(null);
        setSharedTemplateToDelete(null);
        setSharedSignatureToDelete(null);
    };

    const handleDeleteGroup = (groupId) => {
        setDeletionType('group');
        setGroupToDelete(groupId);
    };

    const deleteGroup = async () => {
        try {
            await datastore.api.deleteGroup(groupToDelete);
        } catch (error) {
            console.error("error deleting group", error);
            setGroupToDelete(null);
            setSnackbarDetails({
                severity: "error",
                message: `Error deleting group`,
            });
            setSnackbarOpen(true);
            return;
        }

        setSnackbarDetails({
            severity: "success",
            message: `Group deleted successfully`,
        });
        setSnackbarOpen(true);

        setGroups((prev) => prev.filter((g) => g.id !== groupToDelete));
        setGroupToDelete(null);
    };

    const handleDeleteSharedRule = (groupId, sharedRuleId) => {
        setDeletionType('rule');
        setSharedRuleToDelete({ group: groupId, id: sharedRuleId });
    };

    const handleDeleteSharedTemplate = (groupId, sharedTemplateId) => {
        setDeletionType('template');
        setSharedTemplateToDelete({ group: groupId, id: sharedTemplateId });
    };

    const handleDeleteSharedSignature = (groupId, sharedSignatureId) => {
        setDeletionType('signature');
        setSharedSignatureToDelete({ group: groupId, id: sharedSignatureId });
    };

    const deleteSharedRule = async () => {
        try {
            await datastore.api.deleteSharedRule(sharedRuleToDelete.id);
        } catch (error) {
            console.error("error deleting shared rule", error);
            setSharedRuleToDelete(null);
            setSnackbarDetails({
                severity: "error",
                message: `Error deleting shared rule`,
            });
            setSnackbarOpen(true);
            return;
        }

        setSnackbarDetails({
            severity: "success",
            message: `Shared rule deleted successfully`,
        });
        setSnackbarOpen(true);

        setGroups((prev) => {
            const index = prev.findIndex((g) => g.id === sharedRuleToDelete.group);
            if (index === -1) {
                return prev;
            }

            const newGroups = [...prev];
            newGroups[index].sharedrules = newGroups[index].sharedrules.filter(
                (r) => r.id !== sharedRuleToDelete.id
            );
            return newGroups;
        });

        setSharedRuleToDelete(null);
    };

    const deleteSharedTemplate = async () => {
        try {
            await datastore.api.deleteSharedTemplate(sharedTemplateToDelete.id);
        } catch (error) {
            console.error("error deleting shared template", error);
            setSharedTemplateToDelete(null);
            setSnackbarDetails({
                severity: "error",
                message: `Error deleting shared template`,
            });
            setSnackbarOpen(true);
            return;
        }

        setSnackbarDetails({
            severity: "success",
            message: `Shared template deleted successfully`,
        });
        setSnackbarOpen(true);

        setGroups((prev) => {
            const index = prev.findIndex(
                (g) => g.id === sharedTemplateToDelete.group
            );
            if (index === -1) {
                return prev;
            }

            const newGroups = [...prev];
            newGroups[index].sharedtemplates = newGroups[index].sharedtemplates.filter(
                (r) => r.id !== sharedTemplateToDelete.id
            );
            return newGroups;
        });

        setSharedTemplateToDelete(null);
    };

    const deleteSharedSignature = async () => {
        try {
            await datastore.api.deleteSharedSignature(sharedSignatureToDelete.id);
        } catch (error) {
            console.error("error deleting shared signature", error);
            setSharedSignatureToDelete(null);
            setSnackbarDetails({
                severity: "error",
                message: `Error deleting shared signature`,
            });
            setSnackbarOpen(true);
            return;
        }

        setSnackbarDetails({
            severity: "success",
            message: `Shared signature deleted successfully`,
        });
        setSnackbarOpen(true);

        setGroups((prev) => {
            const index = prev.findIndex(
                (g) => g.id === sharedSignatureToDelete.group
            );
            if (index === -1) {
                return prev;
            }

            const newGroups = [...prev];
            newGroups[index].sharedsignatures = newGroups[
                index
            ].sharedsignatures.filter((r) => r.id !== sharedSignatureToDelete.id);
            return newGroups;
        });

        setSharedSignatureToDelete(null);
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (organization) {
            fetchGroups({ pageToFetch: page, query: searchQuery });
        }
    }, []);

    useDebounce(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
        } else {
            setPage(1);
            fetchGroups({ pageToFetch: 1, query: searchQuery })
        }
    }, [searchQuery], 500);

    return (
        <Container className="groups-container table-view" maxWidth={false}>
            <Grid className="table-actions" container spacing={2}>
                <Grid item sm={9} xs={12}>
                    <SearchBar
                        setSearchTerm={setSearchQuery}
                        placeholder="Search groups..."
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() => handleCreate()}
                    >
                        Create Group
                    </Button>
                </Grid>
            </Grid>

            <GroupList
                groups={groups}
                loading={loading}
                setPage={loadPage}
                totalCount={totalCount}
                page={page}
                pageSize={pageSize}
                handleEdit={handleEdit}
                handleDelete={handleDeleteGroup}
                handleRemoveRule={handleDeleteSharedRule}
                handleRemoveTemplate={handleDeleteSharedTemplate}
                handleRemoveSignature={handleDeleteSharedSignature}
            />

            <CreateEditGroup
                group={groupToCreateOrEdit}
                open={groupToCreateOrEdit !== null}
                onClose={handleCancelCreateOrEdit}
                onSave={handleSave}
            />

            <Dialog
                open={
                    groupToDelete !== null ||
                    sharedRuleToDelete !== null ||
                    sharedTemplateToDelete !== null ||
                    sharedSignatureToDelete !== null
                }
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm Delete
                </DialogTitle>
                <DialogContent dividers>
                    {deletionType !== "rule" && (
                        <Typography variant="body1" sx={{ marginBottom: '20px' }} gutterBottom>
                            <b>Note</b>: Deletion of this{" "}
                            {groupToDelete
                                ? deletionType
                                : `shared ${deletionType}`}{" "}
                            will remove access {groupToDelete ? 'to the shared templates and signatures' : ''} for all users in the group. This
                            will break any pesonal rules that use{" "}
                            {groupToDelete
                                ? "templates and signatures shared with the group"
                                : `this shared ${deletionType}`}
                            .
                        </Typography>
                    )}
                    <Typography variant="body1" gutterBottom>
                        Are you sure you wish to delete this{" "}
                        {groupToDelete
                            ? deletionType
                            : `shared ${deletionType}`}
                        ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarDetails.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import SearchBar from "../../components/SearchBar";
import TemplateList from "./TemplateList";
import CreateEditTemplate from "./CreateEditTemplate";

import "./styles.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Templates() {
    useDocumentTitle("Toggles Hub | Templates");
    const initialLoad = useRef(true);
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { isOrgAdmin, user, setUser, organization, groups, datastore } = useAuth();
    const [currentTab, setCurrentTab] = useState("personal");
    const [templates, setTemplates] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({
        severity: "success",
        message: "",
    });
    const [templateToCreateOrEdit, setTemplateToCreateOrEdit] = useState(null);
    const [templateToDelete, setTemplateToDelete] = useState(null);
    const [sharedTemplateToDelete, setSharedTemplateToDelete] = useState(null);

    const fetchPersonalTemplates = async (query) => {
        try {
            const data = await datastore.api.getPersonalTemplates(query);

            setTemplates(data.templates);
            setTotalCount(data.count);
        } catch (err) {
            console.error("Error loading templates: ", err);
        }
    };

    const fetchGroupTemplates = async (query) => {
        try {
            const data = await datastore.api.getGroupTemplates(query);

            setTemplates(data.templates);
            setTotalCount(data.count);
        } catch (err) {
            console.error("Error loading templates: ", err);
        }
    };

    const fetchOrganizationTemplates = async (query) => {
        try {
            const data = await datastore.api.getOrganizationTemplates(query);

            setTemplates(data.templates);
            setTotalCount(data.count);
        } catch (err) {
            console.error("Error loading templates: ", err);
        }
    };

    const fetchOrgUnsharedTemplates = async (query) => {
        try {
            const data = await datastore.api.getOrgUnsharedTemplates(query);

            setTemplates(data.templates);
            setTotalCount(data.count);
        } catch (err) {
            console.error("Error loading templates: ", err);
        }
    };

    const fetchTemplates = async ({ pageToFetch, query }) => {
        setLoading(true);
        const queryData = {
            from: (pageToFetch - 1) * pageSize,
            to: pageToFetch * pageSize - 1,
        };

        if (query) {
            queryData.q = query;
        }

        if (currentTab === "personal") {
            await fetchPersonalTemplates(queryData);
        } else if (currentTab === "group") {
            await fetchGroupTemplates(queryData);
        } else if (currentTab === "organization") {
            await fetchOrganizationTemplates(queryData);
        } else if (currentTab === "unshared") {
            await fetchOrgUnsharedTemplates(queryData);
        }

        setLoading(false);
    };

    const loadPage = (pageToLoad) => {
        fetchTemplates({ pageToFetch: pageToLoad, query: searchQuery });
        setPage(pageToLoad);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    };

    const handleCreate = async () => {
        const newTemplate = {
            name: "",
            description: "",
            html_content: "",
            sharedtemplates: [],
            metadata: {
                variables: [],
            },
            enabled: true,
        };

        if (currentTab === "organization") {
            newTemplate.sharedtemplates.push({
                template_id: null,
                organization_id: organization.id,
                editable: false,
            });
        }

        setTemplateToCreateOrEdit(newTemplate);
    };

    const handleEdit = (template) => {
        setTemplateToCreateOrEdit(template);
    };

    const handleSave = async (template) => {
        setIsSaving(true);

        const isNew = !template.id;
        let savedTemplate;

        if (isNew) {
            const addedSharedTemplates = template.sharedtemplates.map((r) => ({
                template_id: r.id,
                user_id: r.users?.id,
                group_id: r.groups?.id,
                organization_id: r.organization_id,
                editable: r.editable,
            }));

            // Create
            try {
                const newTemplate = await datastore.api.createTemplate({
                    ...template,
                    sharedtemplates: addedSharedTemplates,
                });
                savedTemplate = newTemplate;
            } catch (error) {
                console.error("error creating new template", error);
                setSnackbarDetails({
                    severity: "error",
                    message: `Error ${isNew ? "creating" : "updating"} "${
                        template.name
                    }"`,
                });
                setSnackbarOpen(true);
                setIsSaving(false);
                return;
            }
        } else {
            const { addedSharedTemplates, removedSharedTemplates } =
                getSharedTemplateChanges(template);

            // Update
            try {
                const updatedTemplate = await datastore.api.updateTemplate({
                    ...template,
                    added_sharedtemplates: addedSharedTemplates,
                    removed_sharedtemplates: removedSharedTemplates,
                });
                savedTemplate = updatedTemplate;
            } catch (error) {
                console.error("error updating existing template", error);
                setSnackbarDetails({
                    severity: "error",
                    message: `Error ${isNew ? "creating" : "updating"} "${
                        template.name
                    }"`,
                });
                setSnackbarOpen(true);
                setIsSaving(false);
                return;
            }
        }

        let newMeVars = [];
        savedTemplate.metadata?.variables?.forEach((variable) => {
            if (variable.name.toLowerCase().indexOf("me::") === 0) {
                const varName = variable.name.substring(4);
                if (!user.metadata?.[varName]) {
                    newMeVars.push(varName);
                }
            }
        });

        if (newMeVars.length > 0) {
            try {
                let updatedUser = { ...user };
                newMeVars.forEach((varName) => {
                    (updatedUser.metadata ||= {})[varName] = "";
                });
                const savedUpdatedUser = await datastore.api.updateAccount(
                    updatedUser
                );
                setUser(savedUpdatedUser);
            } catch (error) {
                console.error(
                    "error updating user metadata with new 'me' variables",
                    error
                );
            }
        }

        const templateType =
            savedTemplate.sharedtemplates.length === 0
                ? "personal"
                : savedTemplate.sharedtemplates[0].organization_id
                ? "organization"
                : "group";
        if (templateType === currentTab) {
            setTemplates((prev) => {
                const index = prev.findIndex((r) => r.id === savedTemplate.id);
                if (index === -1) {
                    return [savedTemplate, ...prev];
                }

                const newRules = [...prev];
                newRules[index] = savedTemplate;
                return newRules;
            });
        } else {
            // Change currentTab to the type of the saved template
            setCurrentTab(templateType);
        }

        setSnackbarDetails({
            severity: "success",
            message: `Template "${savedTemplate.name}" ${
                isNew ? "created" : "updated"
            } successfully`,
        });
        setSnackbarOpen(true);
        setIsSaving(false);
        setTemplateToCreateOrEdit(null);
    };

    const getSharedTemplateChanges = (changedTemplate) => {
        const original = templateToCreateOrEdit.sharedtemplates;
        const changed = changedTemplate.sharedtemplates;

        const deleted = original.filter(
            (o) => !changed.some((c) => c.id === o.id)
        );
        const updated = changed.filter((c) =>
            original.some((o) => c.id === o.id && !isSharedTemplateEqual(c, o))
        );
        const added = changed.filter(
            (c) => !original.some((o) => c.id === o.id)
        );

        let addedSharedTemplates = [];
        let removedSharedTemplates = [];

        [...added, ...updated].forEach((changedItem) => {
            const addedSharedTemplate = {
                template_id: changedItem.template_id,
                user_id: changedItem.users?.id,
                group_id: changedItem.groups?.id,
                organization_id: changedItem.organization_id,
                editable: changedItem.editable,
            };

            if (changedItem.id) {
                addedSharedTemplate.id = changedItem.id;
            }

            addedSharedTemplates.push(addedSharedTemplate);
        });

        deleted.forEach((originalItem) => {
            removedSharedTemplates.push({
                id: originalItem.id,
                template_id: originalItem.template_id,
                user_id: originalItem.users?.id,
                group_id: originalItem.groups?.id,
                organization_id: originalItem.organization_id,
                editable: originalItem.editable,
            });
        });

        return { addedSharedTemplates, removedSharedTemplates };
    };

    const isSharedTemplateEqual = (st1, st2) => {
        const { id: id1, ...rest1 } = st1;
        const { id: id2, ...rest2 } = st2;

        // Set related entity ids
        rest1.user_id = st1.users?.id;
        rest1.group_id = st1.groups?.id;
        rest1.organization_id = st1.organization_id || null;

        rest2.user_id = st2.users?.id;
        rest2.group_id = st2.groups?.id;
        rest2.organization_id = st2.organization_id || null;

        // Delete expanded fields (organizations, users, groups)
        delete rest1.organizations;
        delete rest1.users;
        delete rest1.groups;
        delete rest2.organizations;
        delete rest2.users;
        delete rest2.groups;

        return Object.entries(rest2).every(
            ([key, value]) => rest1.hasOwnProperty(key) && rest1[key] === value
        );
    };

    const handleCancelCreateOrEdit = () => {
        setTemplateToCreateOrEdit(null);
    };

    const handleDeleteConfirm = async () => {
        if (templateToDelete) {
            await deleteTemplate();
        } else if (sharedTemplateToDelete) {
            await deleteSharedTemplate();
        }
    };

    const handleDeleteCancel = () => {
        setTemplateToDelete(null);
        setSharedTemplateToDelete(null);
    };

    const handleDeleteTemplate = (groupId) => {
        setTemplateToDelete(groupId);
    };

    const deleteTemplate = async () => {
        try {
            const templateItemToDelete = templates.find(
                (r) => r.id === templateToDelete
            );
            const isPersonalTemplate =
                !templateItemToDelete.organization_id &&
                (!templateItemToDelete.sharedtemplates ||
                    templateItemToDelete.sharedtemplates.length === 0);
            await datastore.api.deleteTemplate(
                templateToDelete,
                isPersonalTemplate
            );
        } catch (error) {
            console.error("error deleting template", error);
            setTemplateToDelete(null);
            setSnackbarDetails({
                severity: "error",
                message: `Error deleting template`,
            });
            setSnackbarOpen(true);
            return;
        }

        setSnackbarDetails({
            severity: "success",
            message: `Template deleted successfully`,
        });
        setSnackbarOpen(true);

        setTemplates((prev) => prev.filter((r) => r.id !== templateToDelete));
        setTemplateToDelete(null);
    };

    const handleDeleteSharedTemplate = (templateId, sharedTemplateId) => {
        setSharedTemplateToDelete({
            template: templateId,
            shared: sharedTemplateId,
        });
    };

    const deleteSharedTemplate = async () => {
        try {
            await datastore.api.deleteSharedTemplate(
                sharedTemplateToDelete.shared
            );
        } catch (error) {
            console.error("error deleting shared template", error);
            setSharedTemplateToDelete(null);
            setSnackbarDetails({
                severity: "error",
                message: `Error deleting shared template`,
            });
            setSnackbarOpen(true);
            return;
        }

        setSnackbarDetails({
            severity: "success",
            message: `Shared template deleted successfully`,
        });
        setSnackbarOpen(true);

        setTemplates((prev) => {
            const index = prev.findIndex(
                (r) => r.id === sharedTemplateToDelete.template
            );
            if (index === -1) {
                return prev;
            }

            const newTemplates = [...prev];
            newTemplates[index].sharedtemplates = newTemplates[
                index
            ].sharedtemplates.filter(
                (r) => r.id !== sharedTemplateToDelete.shared
            );

            if (newTemplates[index].sharedtemplates.length === 0) {
                newTemplates.splice(index, 1);
            }

            return newTemplates;
        });

        setSharedTemplateToDelete(null);
    };

    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    useEffect(() => {
        fetchTemplates({ pageToFetch: page, query: searchQuery });
    }, []);

    useDebounce(
        () => {
            if (initialLoad.current) {
                initialLoad.current = false;
            } else {
                setPage(1);
                fetchTemplates({ pageToFetch: 1, query: searchQuery });
            }
        },
        [searchQuery],
        500
    );

    useEffect(() => {
        setPage(1);
        fetchTemplates({ pageToFetch: 1, query: searchQuery });
    }, [currentTab]);

    return (
        <Container className="templates-container table-view" maxWidth={false}>
            <Box className="list-nav" sx={{ width: "100%" }}>
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                >
                    <Tab value="personal" label="Personal" iconPosition="end" />
                    {organization && (
                        <Tab value="group" label="Group" iconPosition="end" />
                    )}
                    {organization && (
                        <Tab
                            value="organization"
                            label="Organization"
                            iconPosition="end"
                        />
                    )}
                    {organization && isOrgAdmin() && (
                        <Tab
                            value="unshared"
                            label="Unshared"
                            iconPosition="end"
                        />
                    )}
                </Tabs>
            </Box>

            <Grid className="table-actions" container spacing={2}>
                <Grid item sm={9} xs={12}>
                    <SearchBar
                        setSearchTerm={setSearchQuery}
                        placeholder={`Search ${currentTab} templates`}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() => handleCreate()}
                    >
                        Create Template
                    </Button>
                </Grid>
            </Grid>

            <TemplateList
                templates={templates}
                loading={loading}
                isPersonal={currentTab === "personal"}
                setPage={loadPage}
                totalCount={totalCount}
                page={page}
                pageSize={pageSize}
                handleEdit={handleEdit}
                handleDelete={handleDeleteTemplate}
                handleRemoveSharedTemplate={handleDeleteSharedTemplate}
            />

            {!!templateToCreateOrEdit && (
                <CreateEditTemplate
                    template={templateToCreateOrEdit}
                    organization={organization}
                    groups={groups}
                    open={templateToCreateOrEdit !== null}
                    onClose={handleCancelCreateOrEdit}
                    onSave={handleSave}
                    isSaving={isSaving}
                />
            )}

            <Dialog
                open={
                    templateToDelete !== null || sharedTemplateToDelete !== null
                }
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {templateToDelete
                            ? "Are you sure you wish to delete this template"
                            : "Are you sure you wish to remove this template from the group"}
                        ? Note that this will break any rules that use this
                        template.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarDetails.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

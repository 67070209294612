// @ts-nocheck
import AdminDataStore from "./admin";
import PublicDataStore from "./public";
import UserDataStore from "./user";

// Create a new class to handle all data store operations
export default class DataStore {
    public user: any;
    public isAdmin: boolean;
    public isOwner: boolean;
    public api: any;

    constructor(user, isAdmin = false, isOwner = false) {
        this.user = user;
        this.isAdmin = isAdmin;
        this.isOwner = isOwner;

        if (!user) {
            this.api = new PublicDataStore();
        } else if (isAdmin) {
            this.api = new AdminDataStore(user, isOwner);
        } else {
            this.api = new UserDataStore(user);
        }
    }
}
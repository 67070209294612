// @ts-nocheck
import React from "react";
import SignupWithPayment from "./SignupWithPayment";
import SignupWithTrial from "./SignupWithTrial";
import { useAuth } from "../../hooks/useAuth";

import "./styles.scss";

function Signup({ interval, seats, completePayment, type }) {
    const { subscription, getSubscription } = useAuth();
    
    const trialUsed = subscription?.trial_ends_at;

    if (!trialUsed) {
        return (
            <SignupWithTrial
                interval={interval}
                seats={seats}
                completePayment={completePayment}
                type={type}
            />
        );
    } else {
        return (
            <SignupWithPayment
                interval={interval}
                seats={seats}
                completePayment={completePayment}
                type={type}
            />
        );
    }
}

export default Signup;

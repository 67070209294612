// @ts-nocheck
import React, { useState, useEffect, memo } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { VALIDATION_REGEXES } from "../../helpers/constants";
import dayjs from "dayjs";

export const VARIABLE_TYPES = [
    { value: "text", label: "Text" },
    { value: "number", label: "Number" },
    { value: "date", label: "Date" },
    { value: "time", label: "Time" },
    { value: "phone", label: "Phone" },
    { value: "email", label: "Email" },
    { value: "url", label: "URL" },
    { value: "dropdown", label: "Dropdown" },
    { value: "title", label: "Title" },
];

const TITLE_TYPE_OPTIONS = [
    { value: "", label: "No default" },
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Miss", label: "Miss" },
    { value: "Ms.", label: "Ms." },
    { value: "Dr.", label: "Dr." },
];

export const parseVariables = (content) => {
    // Regular expression to match variables within double curly braces
    const variableRegex = /{{\s*([^{}]+)\s*}}/g;

    // Array to store found variables
    const variables = [];
    let match;
    // Loop through matches and extract variable names
    while ((match = variableRegex.exec(content)) !== null) {
        variables.push(match[1].trim());
    }

    return [...new Set(variables)];
};

const TextVariable = memo(({ variable, isMeVariable, setValue, canEdit }) => {
    return (
        <TextField
            fullWidth
            size="small"
            label="Default Value"
            disabled={!canEdit || isMeVariable}
            value={variable.default}
            onChange={(e) => {
                setValue(e.target.value);
            }}
        />
    );
});

const NumberVariable = memo(({ variable, isMeVariable, setValue, canEdit }) => {
    return (
        <TextField
            fullWidth
            size="small"
            label="Default Value"
            type="number"
            disabled={!canEdit || isMeVariable}
            value={variable.default}
            onChange={(e) => {
                setValue(e.target.value);
            }}
        />
    );
});

const DateVariable = memo(({ variable, isMeVariable, setValue, setValid, canEdit }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                slotProps={{ textField: { fullWidth: true, size: "small" } }}
                label="Default Value"
                disabled={!canEdit || isMeVariable}
                value={variable.default ? dayjs(variable.default) : null}
                onChange={(newValue) => setValue(newValue)}
                onError={(err) => {
                    setValid(variable.name, !err || err.length === 0);
                }}
            />
        </LocalizationProvider>
    );
});

const TimeVariable = memo(({ variable, isMeVariable, setValue, setValid, canEdit }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                slotProps={{ textField: { fullWidth: true, size: "small" } }}
                label="Default Value"
                disabled={!canEdit || isMeVariable}
                value={variable.default ? dayjs(variable.default) : null}
                onChange={(newValue) => setValue(newValue)}
                onError={(err) => {
                    setValid(variable.name, !err || err.length === 0);
                }}
            />
        </LocalizationProvider>
    );
});

const PhoneVariable = memo(({ variable, isMeVariable, setValue, setValid, canEdit }) => {
    const [inputStateChanged, setInputStateChanged] = useState(false);
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        if (variable?.default.length === 0) {
            setIsValid(true);
            return;
        }

        const isUpdatedValid = matchIsValidTel(variable.default, {});
        setIsValid(isUpdatedValid);
    }, [variable?.default]);

    useEffect(() => {
        setValid(variable.name, isValid);
    }, [isValid]);

    return (
        <MuiTelInput
            fullWidth
            size="small"
            label="Default Value"
            disabled={!canEdit || isMeVariable}
            value={variable.default}
            onChange={(value) => {
                setValue(value);
            }}
            error={
                inputStateChanged &&
                variable.default.length > 0 &&
                !isValid
            }
            helperText={
                inputStateChanged &&
                variable.default.length > 0 &&
                !isValid
                    ? "Valid phone number is required"
                    : ""
            }
            onBlur={() => setInputStateChanged(true)}
        />
    );
});

const EmailVariable = memo(({ variable, isMeVariable, setValue, setValid, canEdit }) => {
    const [inputStateChanged, setInputStateChanged] = useState(false);

    useEffect(() => {
        const isUpdatedValid = variable?.default?.length === 0 || VALIDATION_REGEXES.email.test(variable.default);
        setValid(variable.name, isUpdatedValid);
    }, [variable?.default]);

    return (
        <TextField
            fullWidth
            size="small"
            label="Default Value"
            inputProps={{
                maxLength: 128,
            }}
            value={variable.default}
            disabled={!canEdit || isMeVariable}
            onChange={(e) => {
                setValue(e.target.value);
            }}
            error={
                inputStateChanged &&
                variable.default.length > 0 &&
                !VALIDATION_REGEXES.email.test(variable.default)
            }
            helperText={
                inputStateChanged &&
                variable.default.length > 0 &&
                !VALIDATION_REGEXES.email.test(variable.default)
                    ? "Valid email address is required"
                    : ""
            }
            onBlur={() => setInputStateChanged(true)}
        />
    );
});

const UrlVariable = memo(({ variable, isMeVariable, setValue, setValid, canEdit }) => {
    const [inputStateChanged, setInputStateChanged] = useState(false);

    useEffect(() => {
        const isUpdatedValid = variable?.default?.length === 0 || VALIDATION_REGEXES.url.test(variable.default);
        setValid(variable.name, isUpdatedValid);
    }, [variable?.default]);

    return (
        <TextField
            fullWidth
            size="small"
            label="Default Value"
            inputProps={{
                maxLength: 2083,
            }}
            value={variable.default}
            disabled={!canEdit || isMeVariable}
            onChange={(e) => {
                setValue(e.target.value);
            }}
            error={
                inputStateChanged &&
                variable.default.length > 0 &&
                !VALIDATION_REGEXES.url.test(variable.default)
            }
            helperText={
                inputStateChanged &&
                variable.default.length > 0 &&
                !VALIDATION_REGEXES.url.test(variable.default)
                    ? "Valid url is required"
                    : ""
            }
            onBlur={() => setInputStateChanged(true)}
        />
    );
});

const TitleVariable = memo(({ variable, isMeVariable, setValue, canEdit }) => {
    return (
        <TextField
            fullWidth
            select
            size="small"
            label="Default Value"
            disabled={!canEdit || isMeVariable}
            value={variable.default}
            onChange={(e) => {
                setValue(e.target.value);
            }}
        >
            {TITLE_TYPE_OPTIONS.map((option) => (
                <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{ whiteSpace: "normal" }}
                >
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
});

const DropdownVariable = memo(({ variable, isMeVariable, setValue, canEdit }) => {
    return (
        <TextField
            fullWidth
            select
            size="small"
            label="Default Value"
            disabled={!canEdit || isMeVariable}
            value={variable.default}
            onChange={(e) => {
                setValue(e.target.value);
            }}
        >
            <MenuItem key={`dropdown-option-default`} value={""}>
                No default
            </MenuItem>
            {variable.options.map((option, idx) => (
                <MenuItem
                    key={`dropdown-option-${idx}`}
                    value={option}
                    sx={{ whiteSpace: "normal" }}
                >
                    {option}
                </MenuItem>
            ))}
        </TextField>
    );
});

const VariableInputMap = {
    text: TextVariable,
    number: NumberVariable,
    date: DateVariable,
    time: TimeVariable,
    phone: PhoneVariable,
    email: EmailVariable,
    url: UrlVariable,
    title: TitleVariable,
    dropdown: DropdownVariable,
};

export const getVariableInput = ({ variable, canEdit, isMeVariable, setValueFn, setValidFn }) => {
    if (!variable) {
        return null;
    }

    const VariableInput = VariableInputMap[variable.type];
    return (
        <VariableInput
            variable={variable}
            canEdit={canEdit}
            isMeVariable={isMeVariable}
            setValue={setValueFn}
            setValid={setValidFn}
        />
    );
};

// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { useAuth } from "../hooks/useAuth";

import logo from "../assets/logo-dark_260x139.png";
import "./AuthConfirm.scss";

export default function AuthConfirm() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { verifyToken } = useAuth();
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    // Validate auth token from url and redirect if successful
    // redirect to login if unsucessful after N seconds

    useEffect(() => {
        async function validate() {
            const token = searchParams.get("token");
            const email = searchParams.get("email");

            if (token) {
                setLoading(true);

                const { data, error } = await verifyToken(email, token);

                setLoading(false);

                if (error) {
                    setHasError(true);
                    // Redirect to login page
                    setTimeout(() => {
                        navigate("/login");
                    }, 4000);

                    return;
                }

                navigate("/rules");
                return;
            }
        }

        validate();
    }, [searchParams]);

    return (
        <Container
            className="auth-confirm-container"
            component="main"
            maxWidth="xs"
        >
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <img className="logo" src={logo} alt="Toggles Logo" />

                <Alert className="auth-confirm-message" severity="warning">
                    Validating authentication request - please wait...
                </Alert>

                {hasError && (
                    <Alert className="auth-confirm-message" severity="error">
                        Something went wrong. You will be redirected to the Login
                        page shortly to try again.
                    </Alert>
                )}
            </Box>
        </Container>
    );
}

import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
} from "@stripe/react-stripe-js";
import Layout from './Layout';
import NotFound from './pages/NotFound';
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import AuthConfirm from "./pages/AuthConfirm";
import PremiumAuthConfirm from "./pages/PremiumAuthConfirm";
import Account from "./pages/Account";
import OrgSettings from "./pages/OrgSettings";
import Users from "./pages/Users";
import Groups from "./pages/Groups";
import Rules from "./pages/Rules";
import Templates from "./pages/Templates";
import Signatures from "./pages/Signatures";
import Subscription from "./pages/Subscription";
import { AdminRoute } from "./components/routing/AdminRoute";
import { SubscribedRoute } from "./components/routing/SubscribedRoute";
import { ProtectedRoute } from "./components/routing/ProtectedRoute";
import { AuthProvider } from "./hooks/useAuth";
import { IS_PRODUCTION, REACT_APP_STRIPE_KEY } from "./helpers/constants";

const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);

// Initialize React Ga with your tracking ID
ReactGA.initialize("G-PZ93057VZ7");

export default function App() {
    const location = useLocation();
    useEffect(() => {
        if (IS_PRODUCTION) {
            ReactGA.send({
                hitType: "pageview",
                page: location.pathname + location.search,
            });
        }
    }, [location]);

    useEffect(() => {
        if (!IS_PRODUCTION) {
            console.log('Running in DEVELOPMENT MODE');
        }
    }, []);

    return (
        <div className="app-container">
            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/auth-confirm" element={<AuthConfirm />} />
                    <Route
                        path="/premium-auth-confirm"
                        element={<PremiumAuthConfirm />}
                    />
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute>
                                <Layout />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            index
                            element={<Navigate to="/rules" replace />}
                        />

                        <Route path="/account" element={<Account />} />

                        <Route
                            path="/org-settings"
                            element={
                                <AdminRoute>
                                    <OrgSettings />
                                </AdminRoute>
                            }
                        />

                        <Route
                            path="/users"
                            element={
                                <AdminRoute>
                                    <Users />
                                </AdminRoute>
                            }
                        />

                        <Route
                            path="/groups"
                            element={
                                <AdminRoute>
                                    <Groups />
                                </AdminRoute>
                            }
                        />

                        <Route path="/rules" element={<Rules />} />

                        <Route
                            path="/templates"
                            element={
                                <SubscribedRoute>
                                    <Templates />
                                </SubscribedRoute>
                            }
                        />

                        <Route
                            path="/signatures"
                            element={
                                <SubscribedRoute>
                                    <Signatures />
                                </SubscribedRoute>
                            }
                        />

                        <Route
                            path="/subscription"
                            element={
                                <Elements stripe={stripePromise}>
                                    <Subscription />
                                </Elements>
                            }
                        />

                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </AuthProvider>
        </div>
    );
}

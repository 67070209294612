// @ts-nocheck
import React, { useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

const TYPE_CONTENT = {
    deleteInvite: {
        title: 'Confirm Delete',
        message: 'Are you sure you wish to delete this user\'s invitation to your organization?',
        cancelText: 'Cancel',
        confirmText: 'Delete',
    },
    resendInvite: {
        title: 'Confirm Resend',
        message: 'Are you sure you wish to resend this user\'s invitation to your organization?',
        cancelText: 'Cancel',
        confirmText: 'Resend',
    },
    deactivateUser: {
        title: 'Confirm Deactivate',
        message: 'Are you sure you wish to deactivate this user? They will no longer be able to access your organization\'s data.',
        cancelText: 'Cancel',
        confirmText: 'Deactivate',
    },
    reactivateUser: {
        title: 'Confirm Reactivate',
        message: 'Are you sure you wish to reactivate this user? They will be able to access your organization\'s data again.',
        cancelText: 'Cancel',
        confirmText: 'Reactivate',
    },
    removeAdmin: {
        title: 'Confirm Remove Admin',
        message: 'Are you sure you wish to remove this user as an admin?',
        cancelText: 'Cancel',
        confirmText: 'Remove Admin',
    },
    addAdmin: {
        title: 'Confirm Add Admin',
        message: 'Are you sure you wish to add this user as an admin?',
        cancelText: 'Cancel',
        confirmText: 'Add Admin',
    },
}

export default function ConfirmDialog({ item, handleClose, handleConfirm, type }) {
    const isOpen = useMemo(() => {
        return !!item;
    });

    if (!isOpen) {
        return;
    }

    if (!TYPE_CONTENT[type]) {
        console.log('Invalid type for ConfirmDialog');
        return;
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {TYPE_CONTENT[type].title}
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1" gutterBottom>
                    {TYPE_CONTENT[type].message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    {TYPE_CONTENT[type].cancelText}
                </Button>
                <Button onClick={() => handleConfirm(item)} autoFocus>
                    {TYPE_CONTENT[type].confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// @ts-nocheck
import React, { useState, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import "./styles.scss";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

const AttachmentInput = ({ canEdit, onChange, maxFileSize }) => {
    const [errorMessage, setErrorMessage] = useState("");

    const handleAttachmentSelected = async (ev) => {
        const file = ev.target.files[0];

        if (!file) {
            // TODO: Show error message
            setErrorMessage("No file selected");
            onChange(null);
            return;
        }

        if (file.size > maxFileSize) {
            setErrorMessage(
                `File size exceeds maximum of ${maxFileSize / 1024 / 1024}MB`
            );
            onChange(null);
            return;
        }

        setErrorMessage("");

        const fileDataStr = await fileToBase64(file);

        const dataParts = fileDataStr.split("base64,");
        const dataType = dataParts[0].replace("data:", "");
        const fileData = dataParts[1];

        onChange({
            storage: true,
            name: file.name,
            data: fileData,
            type: dataType,
            size: file.size,
        });
    };

    return (
        <div className="upload-attachment-input">
            <Button
                className="upload-attachment-btn"
                component="label"
                disabled={!canEdit}
                role={undefined}
                variant="contained"
                startIcon={<CloudUploadIcon />}
            >
                Select Attachment
                <VisuallyHiddenInput
                    type="file"
                    onChange={handleAttachmentSelected}
                />
            </Button>

            {!!errorMessage && (
                <Typography
                    className="upload-attachment-error"
                    variant="caption"
                    color="error"
                    display="block"
                    gutterBottom
                >
                    {errorMessage}
                </Typography>
            )}
        </div>
    );
};

export default AttachmentInput;

// @ts-nocheck
import React from "react";
import { useTheme } from "@mui/material";
import {
    MenuButtonAddTable,
    MenuButtonBold,
    MenuButtonBulletedList,
    MenuButtonEditLink,
    MenuButtonHighlightColor,
    MenuButtonImageUpload,
    MenuButtonIndent,
    MenuButtonItalic,
    MenuButtonOrderedList,
    MenuButtonRedo,
    MenuButtonTaskList,
    MenuButtonTextColor,
    MenuButtonUnderline,
    MenuButtonUndo,
    MenuButtonUnindent,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectFontFamily,
    MenuSelectFontSize,
    MenuSelectTextAlign,
    isTouchDevice,
} from "mui-tiptap";
import { uploadImages } from "./helpers";

export default function EditorMenuControls({ uploadEntityId, datastore }) {
    const theme = useTheme();
    return (
        <MenuControlsContainer>
            <MenuSelectFontFamily
                options={[
                    {
                        label: "Arial",
                        value: "Arial, Helvetica, sans-serif",
                    },
                    {
                        label: "Times New Roman",
                        value: "Times New Roman, Times, serif",
                    },
                    {
                        label: "Courier New",
                        value: "Courier New, Courier, monospace",
                    },
                    { label: "Georgia", value: "Georgia, serif" },
                    {
                        label: "Palatino Linotype",
                        value: "Palatino Linotype, Book Antiqua, Palatino, serif",
                    },
                    {
                        label: "Verdana",
                        value: "Verdana, Geneva, sans-serif",
                    },
                    {
                        label: "Tahoma",
                        value: "Tahoma, Geneva, sans-serif",
                    },
                    {
                        label: "Trebuchet MS",
                        value: "Trebuchet MS, sans-serif",
                    },
                ]}
            />

            <MenuDivider />

            <MenuSelectFontSize />

            <MenuDivider />

            <MenuButtonBold />

            <MenuButtonItalic />

            <MenuButtonUnderline />

            <MenuDivider />

            {/*
            
            <MenuButtonTextColor
                defaultTextColor={theme.palette.text.primary}
                swatchColors={[
                    { value: "#000000", label: "Black" },
                    { value: "#ffffff", label: "White" },
                    { value: "#888888", label: "Grey" },
                    { value: "#ff0000", label: "Red" },
                    { value: "#ff9900", label: "Orange" },
                    { value: "#ffff00", label: "Yellow" },
                    { value: "#00d000", label: "Green" },
                    { value: "#0000ff", label: "Blue" },
                ]}
            />

            <MenuButtonHighlightColor
                swatchColors={[
                    { value: "#595959", label: "Dark grey" },
                    { value: "#dddddd", label: "Light grey" },
                    { value: "#ffa6a6", label: "Light red" },
                    { value: "#ffd699", label: "Light orange" },
                    // Plain yellow matches the browser default `mark` like when using Cmd+Shift+H
                    { value: "#ffff00", label: "Yellow" },
                    { value: "#99cc99", label: "Light green" },
                    { value: "#90c6ff", label: "Light blue" },
                    { value: "#8085e9", label: "Light purple" },
                ]}
            />

            <MenuDivider />
            
            */}

            <MenuSelectTextAlign />

            <MenuDivider />

            <MenuButtonOrderedList />

            <MenuButtonBulletedList />

            <MenuButtonTaskList />

            {/* On touch devices, we'll show indent/unindent buttons, since they're
      unlikely to have a keyboard that will allow for using Tab/Shift+Tab. These
      buttons probably aren't necessary for keyboard users and would add extra
      clutter. */}
            {isTouchDevice() && (
                <>
                    <MenuButtonIndent />

                    <MenuButtonUnindent />
                </>
            )}

            <MenuDivider />

            <MenuButtonEditLink />

            
            <MenuButtonImageUpload
                onUploadFiles={async (files) => {
                    const uploadResults = await uploadImages(files, uploadEntityId, datastore);

                    return uploadResults.map((image) => ({
                        src: image.path,
                        alt: image.name,
                    }));
                }}
            />
           

            <MenuButtonAddTable />

            <MenuDivider />

            <MenuButtonUndo />
            <MenuButtonRedo />
        </MenuControlsContainer>
    );
}

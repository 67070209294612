// @ts-nocheck
import { useMemo } from "react";
import { createClient } from "@supabase/supabase-js";
import {
    REACT_APP_SUPABASE_URL,
    REACT_APP_SUPABASE_KEY,
    REACT_APP_SUPABASE_AUTH_STORAGE_KEY,
} from "../helpers/constants";

let supabase;

export const getSupabaseClient = () => {
    if (supabase) {
        return supabase;
    }

    supabase = createClient(REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY, {
        auth: {
            storageKey: `${REACT_APP_SUPABASE_AUTH_STORAGE_KEY}}`,
        },
    });

    return supabase;
};

const useSupabase = () => useMemo(getSupabaseClient, []);

export default useSupabase;

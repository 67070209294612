// @ts-nocheck
import React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import "./styles.scss";

const MeVariables = ({ variables, setVariableValue }) => {
    if (!variables) {
        return null;
    }

    return (
        <div className="me-variables-container">
            <Typography className="column-title" variant="h5" component="h2">
                Manage "ME" Variables
            </Typography>
            {Object.keys(variables).map((key) => (
                <TextField
                    key={`me-var-input-${key}`}
                    fullWidth
                    className="text-input"
                    label={key}
                    value={variables[key] || ""}
                    onChange={(ev) =>
                        setVariableValue(key, ev.target.value)
                    }
                />
            ))}
        </div>
    );
};

export default MeVariables;

// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAuth } from "../../hooks/useAuth";
import { SUBSCRIPTION_STATUS_LABELS } from "../../helpers/constants";

import "./styles.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function OrgSettings() {
    const {
        organization,
        setOrganization,
        subscription,
        datastore,
        isOrgOwner,
    } = useAuth();
    let currentOrg = useRef(organization);
    const [orgHasChanges, setOrgHasChanges] = useState(false);
    const [orgSettings, setOrgSettings] = useState({
        fullName: "",
        billingEmail: "",
        domains: [],
        features: {
            rules: {
                enabled: true,
                isDefault: true,
                personal: true,
                group: true,
                organization: true,
                sharing: true,
            },
            templates: {
                enabled: true,
                isDefault: false,
                personal: true,
                group: true,
                organization: true,
                sharing: true,
            },
            signatures: {
                enabled: true,
                isDefault: false,
                personal: true,
                group: true,
                organization: true,
                sharing: true,
            },
        },
    });
    const [orgSettingsInputChanged, setOrgSettingsInputChanged] = useState({
        fullName: false,
        billingEmail: false,
        domains: false,
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({
        severity: "success",
        message: "",
    });

    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleSaveOrg = async () => {
        try {
            const updatedOrg = await datastore.api.updateOrganization({
                full_name: orgSettings.fullName,
                branding: {
                    ...currentOrg.current.branding,
                    features: orgSettings.features,
                },
            });

            setOrgSettings({
                fullName: updatedOrg.full_name || "",
                billingEmail: updatedOrg.billing_email || "",
                domains: updatedOrg.domains || [],
                features: updatedOrg.branding.features || {
                    rules: {
                        enabled: true,
                        isDefault: true,
                        personal: true,
                        group: true,
                        organization: true,
                        sharing: true,
                    },
                    templates: {
                        enabled: true,
                        isDefault: false,
                        personal: true,
                        group: true,
                        organization: true,
                        sharing: true,
                    },
                    signatures: {
                        enabled: true,
                        isDefault: false,
                        personal: true,
                        group: true,
                        organization: true,
                        sharing: true,
                    },
                },
            });

            setOrganization(updatedOrg);

            currentOrg.current = updatedOrg;

            setSnackbarDetails({
                severity: "success",
                message: "Account updated successfully",
            });
            setSnackbarOpen(true);
        } catch (error) {
            console.error("error updating acocunt", error);
            setSnackbarDetails({
                severity: "error",
                message: "Error updating account - please try again",
            });
            setSnackbarOpen(true);
            return;
        }
    };

    const openStripePortal = async () => {
        try {
            // Get Portal url for user
            const data = await datastore.api.getStripePortalSession(
                organization.id
            );

            // Open new tab to data.session.url
            window.open(data.session.url, "_blank").focus();
        } catch (err) {
            console.log("Error opening Stripe Portal: ", err);
            setSnackbarDetails({
                severity: "error",
                message: `Error opening Stripe Portal -- try again`,
            });
            setSnackbarOpen(true);
        }
    };

    useEffect(() => {
        const hasChanges =
            orgSettings.fullName !== currentOrg.current.full_name ||
            JSON.stringify(orgSettings.features) !==
                JSON.stringify(currentOrg.current.branding.features);
        setOrgHasChanges(hasChanges);
    }, [orgSettings]);

    useEffect(() => {
        if (organization) {
            setOrgSettings({
                fullName: organization.full_name || "",
                billingEmail: organization.billing_email || "",
                domains: organization.domains || [],
                features: organization.branding.features || {
                    rules: {
                        enabled: true,
                        isDefault: true,
                        personal: true,
                        group: true,
                        organization: true,
                        sharing: true,
                    },
                    templates: {
                        enabled: true,
                        isDefault: false,
                        personal: true,
                        group: true,
                        organization: true,
                        sharing: true,
                    },
                    signatures: {
                        enabled: true,
                        isDefault: false,
                        personal: true,
                        group: true,
                        organization: true,
                        sharing: true,
                    },
                },
            });
        }
    }, [organization]);

    return (
        <Container className="org-settings-container" maxWidth={false}>
            <Grid container spacing={4}>
                <Grid item sm={12} sx={{ marginBottom: '20px' }}>
                    <Typography
                        className="page-title"
                        variant="h4"
                        component="h2"
                    >
                        Organization Settings
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        required
                        fullWidth
                        className="text-input"
                        label="Company Name"
                        inputProps={{
                            maxLength: 64,
                        }}
                        value={orgSettings?.fullName || ""}
                        error={
                            orgSettingsInputChanged.fullName &&
                            !orgSettings.fullName
                        }
                        disabled={!isOrgOwner()}
                        helperText={
                            orgSettingsInputChanged.fullName &&
                            !orgSettings.fullName
                                ? "Company name is required"
                                : ""
                        }
                        onChange={(ev) =>
                            setOrgSettings((prev) => ({
                                ...prev,
                                fullName: ev.target.value,
                            }))
                        }
                        onBlur={() =>
                            setOrgSettingsInputChanged((prev) => ({
                                ...prev,
                                fullName: true,
                            }))
                        }
                    />
                    <TextField
                        disabled
                        fullWidth
                        className="text-input"
                        label="Billing Email"
                        value={orgSettings?.billingEmail || ""}
                    />
                    <TextField
                        disabled
                        fullWidth
                        className="text-input"
                        label="Account Created"
                        value={
                            new Date(
                                organization.created_at
                            ).toLocaleDateString() || ""
                        }
                    />

                    <div className="features-section">
                        <Typography
                            className="column-title"
                            variant="h5"
                            component="h2"
                        >
                            Enabled Features
                        </Typography>

                        <FormControlLabel
                            className="feature-checkbox"
                            control={
                                <Checkbox
                                    checked={orgSettings.features.rules.enabled}
                                    onChange={(ev) =>
                                        setOrgSettings((prev) => {
                                            const updatedOrg = {
                                                ...prev,
                                                features: { ...prev.features },
                                            };
                                            updatedOrg.features.rules = {
                                                ...updatedOrg.features.rules,
                                                enabled: ev.target.checked,
                                            };
                                            return updatedOrg;
                                        })
                                    }
                                />
                            }
                            label="Rules"
                        />

                        <FormControlLabel
                            className="feature-checkbox"
                            control={
                                <Checkbox
                                    checked={
                                        orgSettings.features.templates.enabled
                                    }
                                    onChange={(ev) =>
                                        setOrgSettings((prev) => {
                                            const updatedOrg = {
                                                ...prev,
                                                features: { ...prev.features },
                                            };
                                            updatedOrg.features.templates = {
                                                ...updatedOrg.features
                                                    .templates,
                                                enabled: ev.target.checked,
                                            };
                                            return updatedOrg;
                                        })
                                    }
                                />
                            }
                            label="Templates"
                        />

                        <FormControlLabel
                            className="feature-checkbox"
                            control={
                                <Checkbox
                                    checked={
                                        orgSettings.features.signatures.enabled
                                    }
                                    onChange={(ev) =>
                                        setOrgSettings((prev) => {
                                            const updatedOrg = {
                                                ...prev,
                                                features: { ...prev.features },
                                            };
                                            updatedOrg.features.signatures = {
                                                ...updatedOrg.features
                                                    .signatures,
                                                enabled: ev.target.checked,
                                            };
                                            return updatedOrg;
                                        })
                                    }
                                />
                            }
                            label="Signatures"
                        />
                    </div>

                    {orgHasChanges && (
                        <Button
                            fullWidth
                            size="large"
                            className="save-button"
                            variant="contained"
                            onClick={handleSaveOrg}
                        >
                            Update Settings
                        </Button>
                    )}
                </Grid>
                <Grid item sm={6} xs={12}>
                    <div className="subscription-section">
                        <Typography
                            className="column-title"
                            variant="h5"
                            component="h2"
                        >
                            Subscription Details
                        </Typography>

                        <Typography
                            className="subscription-detail"
                            variant="subtitle2"
                        >
                            Type: Business Pro
                        </Typography>
                        <Typography
                            className="subscription-detail"
                            variant="subtitle2"
                        >
                            Next billing date:{" "}
                            {new Date(
                                subscription.ends_at
                            ).toLocaleDateString() || ""}
                        </Typography>
                        <Typography
                            className="subscription-detail"
                            variant="subtitle2"
                        >
                            Status:{" "}
                            {SUBSCRIPTION_STATUS_LABELS[subscription.status]}
                        </Typography>
                        <Typography
                            className="subscription-detail"
                            variant="subtitle2"
                        >
                            Subscription Seats: {subscription.seats}
                        </Typography>
                        {isOrgOwner() && (
                            <Button
                                className="subscription-manage-btn"
                                variant="text"
                                onClick={openStripePortal}
                            >
                                Manage Subscription
                            </Button>
                        )}
                    </div>
                </Grid>
            </Grid>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarDetails.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

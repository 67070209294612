// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAuth } from "../../hooks/useAuth";
import { SUBSCRIPTION_STATUS_LABELS } from "../../helpers/constants";
import MeVariables from "../../components/MeVariables/MeVariables";

import "./styles.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PremiumAccount() {
    const { user, setUser, subscription, datastore } = useAuth();
    let currentUser = useRef(user);
    const [hasChanges, setHasChanges] = useState(false);
    const [userSettings, setUserSettings] = useState({
        fullName: "",
        email: "",
    });
    const [userMeVariables, setUserMeVariables] = useState({});
    const [userSettingsInputChanged, setUserSettingsInputChanged] = useState({
        fullName: false,
        email: false,
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({
        severity: "success",
        message: "",
    });

    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleSave = async () => {
        try {
            const updatedUser = await datastore.api.updateAccount({
                ...currentUser.current,
                full_name: userSettings.fullName,
                metadata: userMeVariables,
            });

            setUserSettings({
                fullName: updatedUser.full_name || "",
                email: updatedUser.email || "",
                metadata: userMeVariables,
            });

            setUser(updatedUser);
            currentUser.current = updatedUser;

            setSnackbarDetails({
                severity: "success",
                message: "Account updated successfully",
            });
            setSnackbarOpen(true);
        } catch (error) {
            console.error("error updating acocunt", error);
            setSnackbarDetails({
                severity: "error",
                message: "Error updating account - please try again",
            });
            setSnackbarOpen(true);
            return;
        }
    };

    const openStripePortal = async () => {
        try {
            // Get Portal url for user
            const data = await datastore.api.getStripePortalSession(user.id);

            // Open new tab to data.session.url
            window.open(data.session.url, "_blank").focus();
        } catch (err) {
            console.log("Error opening Stripe Portal: ", err);
            setSnackbarDetails({
                severity: "error",
                message: `Error opening Stripe Portal -- try again`,
            });
            setSnackbarOpen(true);
        }
    };

    useEffect(() => {
        let hasChanges =
            userSettings.fullName !== currentUser.current.full_name ||
            JSON.stringify(userMeVariables) !==
                JSON.stringify(currentUser.current.metadata);

        setHasChanges(hasChanges);
    }, [userSettings, userMeVariables]);

    useEffect(() => {
        if (user) {
            setUserSettings({
                fullName: user.full_name || "",
                email: user.email || "",
            });
            setUserMeVariables(user.metadata);
        }
    }, [user]);

    return (
        <Container className="account-container" maxWidth={false}>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <Typography
                        className="column-title"
                        variant="h5"
                        component="h2"
                    >
                        Account Settings
                    </Typography>
                    <TextField
                        fullWidth
                        className="text-input"
                        label="Full Name"
                        inputProps={{
                            maxLength: 64,
                        }}
                        value={userSettings?.fullName || ""}
                        error={
                            userSettingsInputChanged.fullName &&
                            !userSettings.fullName
                        }
                        helperText={
                            userSettingsInputChanged.fullName &&
                            !userSettings.fullName
                                ? "Full name is required"
                                : ""
                        }
                        onChange={(ev) =>
                            setUserSettings((prev) => ({
                                ...prev,
                                fullName: ev.target.value,
                            }))
                        }
                        onBlur={() =>
                            setUserSettingsInputChanged((prev) => ({
                                ...prev,
                                fullName: true,
                            }))
                        }
                    />
                    <TextField
                        disabled
                        fullWidth
                        className="text-input"
                        label="Email"
                        value={userSettings?.email || ""}
                    />
                    <TextField
                        disabled
                        fullWidth
                        className="text-input"
                        label="Account Created"
                        value={
                            new Date(user.created_at).toLocaleDateString() || ""
                        }
                    />

                    <MeVariables
                        variables={userMeVariables}
                        setVariableValue={(key, value) => {
                            setUserMeVariables((prev) => {
                                const updatedVariables = { ...prev };
                                updatedVariables[key] = value;
                                return updatedVariables;
                            });
                        }}
                    />

                    {hasChanges && (
                        <Button
                            fullWidth
                            size="large"
                            className="save-button"
                            variant="contained"
                            onClick={handleSave}
                        >
                            Update Account
                        </Button>
                    )}
                </Grid>
                <Grid item sm={6} xs={12}>
                    <div className="subscription-section mt-0">
                        <Typography
                            className="column-title"
                            variant="h5"
                            component="h2"
                        >
                            Subscription Details
                        </Typography>

                        <Typography
                            className="subscription-detail"
                            variant="subtitle2"
                        >
                            Next billing date:{" "}
                            {new Date(
                                subscription.ends_at
                            ).toLocaleDateString() || ""}
                        </Typography>
                        <Typography
                            className="subscription-detail"
                            variant="subtitle2"
                        >
                            Status:{" "}
                            {SUBSCRIPTION_STATUS_LABELS[subscription.status]}
                        </Typography>
                        <Button
                            className="subscription-manage-btn"
                            variant="text"
                            onClick={openStripePortal}
                        >
                            Manage Subscription
                        </Button>
                    </div>
                </Grid>
            </Grid>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarDetails.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

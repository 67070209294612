// @ts-nocheck
import React, { useState } from "react";
import { Popper, Paper, Typography, useTheme } from "@mui/material";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

import "./styles.scss";

const RTEInstructions = () => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div
            className="rte-instructions-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <HelpCenterIcon className="instructions-icon-btn" />
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement="right"
                sx={{ zIndex: theme.zIndex.tooltip }}
            >
                <Paper
                    sx={{
                        padding: theme.spacing(2),
                        border: "1px solid #cccccc",
                        borderRadius: "4px",
                        background: "#f9f9f9",
                    }}
                >
                    <div className="rte-instructions">
                        <Typography variant="h6" color="primary" gutterBottom>
                            How to use the editor
                        </Typography>
                        <Typography
                            className="section-label"
                            variant="subtitle2"
                            gutterBottom
                        >
                            Formatting text
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            The editor supports rich text editing. You can
                            format text using the toolbar above the editor. You
                            can also paste text with formatting into the editor.
                        </Typography>
                        <Typography
                            className="section-label"
                            variant="subtitle2"
                            gutterBottom
                        >
                            Images
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Currently, all images must be hosted on the web and
                            inserted by highlighting and copying the image in a
                            browser and then pasting it into the editor. In the
                            near future, we will support uploading and drag &
                            dropping of images.
                        </Typography>
                        <Typography
                            className="section-label"
                            variant="subtitle2"
                            gutterBottom
                        >
                            Variables
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Variables are supported in templates and signatures!
                            To define a variable, insert{" "}
                            <span className="highlight">{`{{some_variable_name}}`}</span>{" "}
                            into the editor. When you toggle on a rule that
                            inserts this content, you will be prompted to enter
                            a value for this variable and the value will replace
                            this text.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            In addition, there are "personal" variables that can
                            be defined. These variables will have their values
                            saved to your account and can be reused in other
                            templates. To define a personal variable, insert{" "}
                            <span className="highlight">{`{{me::some_variable_name}}`}</span>{" "}
                            into the editor.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            If a value has already been defined, it will be
                            pre-filled into the input field when you toggle on a
                            rule that inserts this content. If a value has not
                            been defined, you will be prompted to enter a value
                            for this variable. You can change the values simply
                            by updating the value when propted to enter values
                            for all variables when the rule is toggled on.
                        </Typography>
                        <Typography
                            className="section-label"
                            variant="subtitle2"
                            gutterBottom
                        >
                            Testing your content
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Note that all email providers render emails slightly
                            differently. We recommend testing your content in
                            the email provider you use to send emails to ensure
                            that the content is rendered as expected. We will do
                            our best to continually improve the editor to render
                            make the content look as close as possible to the
                            final result, but there may be slight differences.
                        </Typography>
                    </div>
                </Paper>
            </Popper>
        </div>
    );
};

export default RTEInstructions;

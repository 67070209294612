// @ts-nocheck
import { v4 as uuidv4 } from "uuid";

export const uploadImages = async (images, uploadEntityId, datastore) => {
    const uploadResults = await Promise.all(
        images.map(async (image) => {
            const imageGUIDFull = uuidv4();
            const imageGUID = imageGUIDFull.split("-")[0];
            const imagePath = `${uploadEntityId}/content-images/${imageGUID}-${image.name}`;

            try {
                const uploadResult = await datastore.api.uploadTemplateImage(
                    imagePath,
                    image,
                    {
                        contentType: image.type,
                        cacheControl: "2592000",
                        upsert: false,
                    }
                );

                return {
                    path: uploadResult.publicUrl,
                    name: image.name,
                };
            } catch (error) {
                console.error(`Error uploading image ${image.name}: `, error);
                return;
            }
        })
    );

    return uploadResults.filter((result) => !!result && !!result.path);
};

// @ts-nocheck
import { getSupabaseClient } from "../hooks/useSupabase";
import { SB_FUNCTIONS } from "../helpers/constants";

export default class AdminDataStore {
    public type: string;
    private supabase: any;
    private isOwner: boolean;

    constructor(user, isOwner = false) {
        this.supabase = getSupabaseClient();
        this.isOwner = isOwner;
        this.user = user;
        this.type = "admin";
    }

    // Org Domain Check -- DELETE
    public async checkDomain(domain) {
        const { data: domainCheck, error: domainCheckError } =
            await this.supabase.functions.invoke(
                `${SB_FUNCTIONS.ORG_DOMAIN_CHECK}${domain}`,
                {
                    method: "GET",
                }
            );

        if (domainCheckError || !domainCheck) {
            console.error("Error checking domain: ", domainCheckError);
            throw new Error("Error checking domain");
        }

        return domainCheck;
    }

    public async updateAccount(updatedUser) {
        if (!updatedUser || !updatedUser.id) {
            throw new Error("Error updating account - no user provided");
        }

        const { data, error } = await this.supabase
            .from("users")
            .update(updatedUser)
            .eq("id", updatedUser.id)
            .select();

        if (error) {
            console.log("Error updating account: ", error);
            throw new Error("Error updating account");
        }

        return data[0];
    }

    // Accounts (org)
    public async getManifest() {
        const { data: manifest, error } = await this.supabase.functions.invoke(
            SB_FUNCTIONS.ACCOUNTS_MANIFEST,
            {
                method: "GET",
            }
        );

        if (error) {
            console.log("Error fetching manifest: ", error);
            throw new Error("Error fetching manifest");
        }

        return manifest;
    }

    // Organizations
    public async getOrganization() {}

    public async updateOrganization(updatedOrg) {
        const { data, error } = await this.supabase.functions.invoke(
            SB_FUNCTIONS.ACCOUNTS,
            {
                method: "PUT",
                body: updatedOrg,
            }
        );

        if (error) {
            console.log("Error updating account: ", error);
            throw new Error("Error updating account");
        }

        return data;
    }

    // Subscriptions
    public async getSubscription(organizationId) {}

    public async createSubscriptionWithPayment(subscription) {
        const { data, error: funcError } = await this.supabase.functions.invoke(
            SB_FUNCTIONS.CREATE_SUBSCRIPTION,
            {
                body: subscription,
            }
        );

        if (funcError) {
            console.log(
                "Error creating subscription with payment: ",
                funcError
            );
            throw new Error("Error creating subscription with payment");
        }

        return data;
    }

    public async createSubscriptionWithTrial(subscription) {
        const { data, error: funcError } = await this.supabase.functions.invoke(
            SB_FUNCTIONS.CREATE_TRIAL_SUBSCRIPTION,
            {
                body: subscription,
            }
        );

        if (funcError) {
            console.log("Error creating subscription with trial: ", funcError);
            throw new Error("Error creating subscription with trial");
        }

        return data;
    }

    // Admin Users
    public async getAdminUser() {}

    public async createAdminUser(userId) {
        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.USERS}/admin/${userId}`,
            {
                method: "PUT",
            }
        );

        if (error) {
            console.log("Error setting user as admin: ", error);
            throw new Error("Error setting user as admin");
        }

        return data;
    }

    public async removeAdminUser(userId) {
        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.USERS}/admin/${userId}`,
            {
                method: "DELETE",
            }
        );

        if (error) {
            console.log("Error removing user as admin: ", error);
            throw new Error("Error removing user as admin");
        }

        return data;
    }

    // Organizaiton Users / Invites
    public async inviteUser(invite) {
        const { data, error } = await this.supabase.functions.invoke(
            SB_FUNCTIONS.ORGANIZATIONUSERS,
            {
                method: "POST",
                body: invite,
            }
        );

        if (error) {
            console.log("Error inviting user: ", error);
            throw new Error("Error inviting user");
        }

        return data;
    }

    public async deleteInvite(inviteId) {
        const { error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.ORGANIZATIONUSERS}/${inviteId}`,
            {
                method: "DELETE",
            }
        );

        if (error) {
            console.log("Error deleting invite: ", error);
            throw new Error("Error deleting invite");
        }
    }

    public async resendInvite(inviteId) {
        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.ORGANIZATIONUSERS}/${inviteId}`,
            {
                method: "POST",
                body: {
                    id: inviteId,
                },
            }
        );

        if (error) {
            console.log("Error resending invite: ", error);
            throw new Error("Error resending invite");
        }

        return data;
    }

    public async deactivateUser(orgUserId) {
        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.ORGANIZATIONUSERS}/${orgUserId}`,
            {
                method: "PUT",
                body: {
                    status: "inactive",
                },
            }
        );

        if (error) {
            console.log("Error deactivating user: ", error);
            throw new Error("Error deactivating user");
        }

        return data;
    }

    public async reactivateUser(orgUserId) {
        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.ORGANIZATIONUSERS}/${orgUserId}`,
            {
                method: "PUT",
                body: {
                    status: "active",
                },
            }
        );

        if (error) {
            console.log("Error reactivating user: ", error);
            throw new Error("Error reactivating user");
        }

        return data;
    }

    // Users
    public async getUsers(query) {
        const queryString = new URLSearchParams(query).toString();
        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.USERS}?${queryString}`,
            {
                method: "GET",
                query,
            }
        );

        if (error) {
            console.log("Error fetching users from API: ", error);
            throw new Error("Error fetching users from API");
        }

        return data;
    }

    // Groups
    public async getGroups(query) {
        const queryString = new URLSearchParams(query).toString();
        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.GROUPS}?${queryString}`,
            {
                method: "GET",
                query,
            }
        );

        if (error) {
            console.log("Error fetching groups from API: ", error);
            throw new Error("Error fetching groups from API");
        }

        return data;
    }

    public async createGroup(group) {
        const { data, error } = await this.supabase.functions.invoke(
            SB_FUNCTIONS.GROUPS,
            {
                method: "POST",
                body: group,
            }
        );

        if (error) {
            console.log("Error creating group: ", error);
            throw new Error("Error creating group");
        }

        return data;
    }

    public async updateGroup(updatedGroup) {
        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.GROUPS}/${updatedGroup.id}`,
            {
                method: "PUT",
                body: updatedGroup,
            }
        );

        if (error) {
            console.log("Error updating group: ", error);
            throw new Error("Error updating group");
        }

        return data;
    }

    public async deleteGroup(groupId) {
        const { error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.GROUPS}/${groupId}`,
            {
                method: "DELETE",
            }
        );

        if (error) {
            console.log("Error deleting group: ", error);
            throw new Error("Error deleting group");
        }
    }

    // Rules
    public async getPersonalRules(query) {
        let selectFields =
            "*, sharedrules(id, rule_id, organization_id, editable, users(id, email), groups(id, name))";

        if (query?.select) {
            selectFields = query?.select;
        }

        const queryBuilder = this.supabase
            .from("rules")
            .select(selectFields, { count: "exact" })
            .eq("user_id", this.user.id)
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log("Error fetching rules from API: ", error);
            throw new Error("Error fetching rules from API");
        }

        return { rules: data, count };
    }

    public async getGroupRules(query) {
        const queryString = new URLSearchParams(query).toString();
        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.GET_GROUP_RULES}?${queryString}`,
            {
                method: "GET",
                query,
            }
        );

        if (error) {
            console.log("Error fetching group rules from API: ", error);
            throw new Error("Error fetching group rules from API");
        }

        return data;
    }

    public async getOrganizationRules(query) {
        const queryBuilder = this.supabase
            .from("rules")
            .select(
                "*, users(email), sharedrules!inner(id, rule_id, organization_id, editable, users(id, email), groups(id, name))",
                { count: "exact" }
            )
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .not("sharedrules.organization_id", "is", null)
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log("Error fetching group rules from API: ", error);
            throw new Error("Error fetching group rules from API");
        }

        return { rules: data, count };
    }

    public async getOrgUnsharedRules(query) {
        const queryString = new URLSearchParams(query).toString();
        const { data, count, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.GET_UNSHARED_ORG_RULES}?${queryString}`,
            {
                method: "GET",
                query,
            }
        );

        if (error) {
            console.log("Error fetching unshared org rules from API: ", error);
            throw new Error("Error fetching unshared org rules from API");
        }

        return data;
    }

    public async createRule(rule) {
        if (!rule) {
            throw new Error("Error creating new rule - no rule provided");
        }

        const ruleToCreate = { ...rule };
        delete ruleToCreate.users;

        if (
            !rule.organization_id &&
            (!rule.sharedrules || rule.sharedrules.length === 0)
        ) {
            // No shared rules = Personal Rule
            return this.createPersonalRule(ruleToCreate);
        }

        const { data, error } = await this.supabase.functions.invoke(
            SB_FUNCTIONS.RULES,
            {
                method: "POST",
                body: ruleToCreate,
            }
        );

        if (error) {
            console.log("Error creating rule: ", error);
            throw new Error("Error creating rule");
        }

        return data;
    }

    public async createPersonalRule(rule) {
        if (!rule) {
            throw new Error("Error creating new rule - no rule provided");
        }

        let newRule = { ...rule, public: false, user_id: this.user.id };
        delete newRule.sharedrules;

        const { data, error } = await this.supabase
            .from("rules")
            .insert(newRule)
            .select();

        if (error) {
            console.log("Error saving new rule: ", error);
            throw new Error("Error saving new rule");
        }

        const createdRule = data[0];
        createdRule.sharedrules = [];

        return createdRule;
    }

    public async updateRule(updatedRule) {
        if (!updatedRule) {
            throw new Error("Error updating rule - no rule provided");
        }

        const ruleToUpdate = { ...updatedRule };
        delete ruleToUpdate.users;

        const isPersonalRule =
            !updatedRule.organization_id &&
            (!updatedRule.sharedrules ||
                updatedRule.sharedrules.length === 0) &&
            (!updatedRule.added_sharedrules ||
                updatedRule.added_sharedrules.length === 0) &&
            (!updatedRule.removed_sharedrules ||
                updatedRule.removed_sharedrules.length === 0);

        if (isPersonalRule) {
            // No shared rules = Personal Rule
            return this.updatePersonalRule(ruleToUpdate);
        }

        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.RULES}/${updatedRule.id}`,
            {
                method: "PUT",
                body: ruleToUpdate,
            }
        );

        if (error) {
            console.log("Error updating rule: ", error);
            throw new Error("Error updating rule");
        }

        return data;
    }

    public async updatePersonalRule(updatedRule) {
        if (!updatedRule || !updatedRule.id) {
            throw new Error("Error updating rule - no rule provided");
        }

        let changedRule = { ...updatedRule };

        delete changedRule.added_sharedrules;
        delete changedRule.removed_sharedrules;
        delete changedRule.sharedrules;

        const { error } = await this.supabase
            .from("rules")
            .update(changedRule)
            .eq("id", updatedRule.id);

        if (error) {
            console.log("Error updating rule: ", error);
            throw new Error("Error updating rule");
        }

        changedRule.sharedrules = [];
        return changedRule;
    }

    public async deleteRule(ruleId, isPersonal) {
        if (isPersonal) {
            return this.deletePersonalRule(ruleId);
        }

        const { error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.RULES}/${ruleId}`,
            {
                method: "DELETE",
            }
        );

        if (error) {
            console.log("Error deleting rule: ", error);
            throw new Error("Error deleting rule");
        }
    }

    public async deletePersonalRule(ruleId) {
        if (!ruleId) {
            throw new Error("Error deleting rule - no rule provided");
        }

        const { error } = await this.supabase
            .from("rules")
            .delete()
            .eq("id", ruleId);

        if (error) {
            console.log("Error deleting rule: ", error);
            throw new Error("Error deleting rule");
        }
    }

    // Shared Rules
    public async deleteSharedRule(srId) {
        const { error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.SHAREDRULES}/${srId}`,
            {
                method: "DELETE",
            }
        );

        if (error) {
            console.log("Error deleting shared rule: ", error);
            throw new Error("Error deleting shared rule");
        }
    }

    // Templates
    public async getPersonalTemplates(query) {
        let selectFields =
            "*, sharedtemplates(id, template_id, user_id, organization_id, editable, users(id, email), groups(id, name))";

        if (query?.select) {
            selectFields = query?.select;
        }

        const queryBuilder = this.supabase
            .from("templates")
            .select(selectFields, { count: "exact" })
            .eq("user_id", this.user.id)
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log("Error fetching templates from API: ", error);
            throw new Error("Error fetching templates from API");
        }

        return { templates: data, count };
    }

    public async getGroupTemplates(query) {
        const queryString = new URLSearchParams(query).toString();
        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.GET_GROUP_TEMPLATES}?${queryString}`,
            {
                method: "GET",
                query,
            }
        );

        if (error) {
            console.log("Error fetching group templates from API: ", error);
            throw new Error("Error fetching group templates from API");
        }

        return data;
    }

    public async getOrganizationTemplates(query) {
        const queryBuilder = this.supabase
            .from("templates")
            .select(
                "*, users(email), sharedtemplates!inner(id, template_id, organization_id, editable, users(id, email), groups(id, name))",
                { count: "exact" }
            )
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .not("sharedtemplates.organization_id", "is", null)
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log("Error fetching group templates from API: ", error);
            throw new Error("Error fetching group templates from API");
        }

        return { templates: data, count };
    }

    public async getOrgUnsharedTemplates(query) {
        const queryString = new URLSearchParams(query).toString();
        const { data, count, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.GET_UNSHARED_ORG_TEMPLATES}?${queryString}`,
            {
                method: "GET",
                query,
            }
        );

        if (error) {
            console.log(
                "Error fetching unshared org templates from API: ",
                error
            );
            throw new Error("Error fetching unshared org templates from API");
        }

        return data;
    }

    public async getAllSharedTemplates(query) {
        const queryString = new URLSearchParams(query).toString();
        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.GET_ORG_SHARED_TEMPLATES}?${queryString}`,
            {
                method: "GET",
                query,
            }
        );

        if (error) {
            console.log(
                "Error fetching all organization's shared templates from API: ",
                error
            );
            throw new Error(
                "Error fetching all organization's shared templates from API"
            );
        }

        return data;
    }

    public async createTemplate(template) {
        if (!template) {
            throw new Error(
                "Error creating new template - no template provided"
            );
        }

        const templateToCreate = { ...template };
        delete templateToCreate.users;

        if (
            !template.organization_id &&
            (!template.sharedtemplates || template.sharedtemplates.length === 0)
        ) {
            // No shared templates = Personal Template
            return this.createPersonalTemplate(templateToCreate);
        }

        const { data, error } = await this.supabase.functions.invoke(
            SB_FUNCTIONS.TEMPLATES,
            {
                method: "POST",
                body: templateToCreate,
            }
        );

        if (error) {
            console.log("Error creating template: ", error);
            throw new Error("Error creating template");
        }

        return data;
    }

    public async createPersonalTemplate(template) {
        if (!template) {
            throw new Error(
                "Error creating new template - no template provided"
            );
        }

        let newTemplate = { ...template, public: false, user_id: this.user.id };
        delete newTemplate.sharedtemplates;

        const { data, error } = await this.supabase
            .from("templates")
            .insert(newTemplate)
            .select();

        if (error) {
            console.log("Error saving new template: ", error);
            throw new Error("Error saving new template");
        }

        const createdTemplate = data[0];
        createdTemplate.sharedtemplates = [];

        return createdTemplate;
    }

    public async updateTemplate(updatedTemplate) {
        if (!updatedTemplate) {
            throw new Error("Error updating template - no template provided");
        }

        const templateToUpdate = { ...updatedTemplate };
        delete templateToUpdate.users;

        const isPersonalTemplate =
            !updatedTemplate.organization_id &&
            (!updatedTemplate.sharedtemplates ||
                updatedTemplate.sharedtemplates.length === 0) &&
            (!updatedTemplate.added_sharedtemplates ||
                updatedTemplate.added_sharedtemplates.length === 0) &&
            (!updatedTemplate.removed_sharedtemplates ||
                updatedTemplate.removed_sharedtemplates.length === 0);

        if (isPersonalTemplate) {
            // No shared templates = Personal Template
            return this.updatePersonalTemplate(templateToUpdate);
        }

        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.TEMPLATES}/${updatedTemplate.id}`,
            {
                method: "PUT",
                body: templateToUpdate,
            }
        );

        if (error) {
            console.log("Error updating template: ", error);
            throw new Error("Error updating template");
        }

        return data;
    }

    public async updatePersonalTemplate(updatedTemplate) {
        if (!updatedTemplate || !updatedTemplate.id) {
            throw new Error("Error updating template - no template provided");
        }

        let changedTemplate = { ...updatedTemplate };

        delete changedTemplate.added_sharedtemplates;
        delete changedTemplate.removed_sharedtemplates;
        delete changedTemplate.sharedtemplates;

        const { error } = await this.supabase
            .from("templates")
            .update(changedTemplate)
            .eq("id", updatedTemplate.id);

        if (error) {
            console.log("Error updating template: ", error);
            throw new Error("Error updating template");
        }

        changedTemplate.sharedtemplates = [];
        return changedTemplate;
    }

    public async deleteTemplate(templateId, isPersonal) {
        if (isPersonal) {
            return this.deletePersonalTemplate(templateId);
        }

        const { error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.TEMPLATES}/${templateId}`,
            {
                method: "DELETE",
            }
        );

        if (error) {
            console.log("Error deleting template: ", error);
            throw new Error("Error deleting template");
        }
    }

    public async deletePersonalTemplate(templateId) {
        if (!templateId) {
            throw new Error("Error deleting template - no template provided");
        }

        const { error } = await this.supabase
            .from("templates")
            .delete()
            .eq("id", templateId);

        if (error) {
            console.log("Error deleting template: ", error);
            throw new Error("Error deleting template");
        }
    }

    // Shared Templates
    public async deleteSharedTemplate(stId) {
        const { error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.SHAREDTEMPLATES}/${stId}`,
            {
                method: "DELETE",
            }
        );

        if (error) {
            console.log("Error deleting shared template: ", error);
            throw new Error("Error deleting shared template");
        }
    }

    // Signatures
    public async getPersonalSignatures(query) {
        let selectFields =
            "*, sharedsignatures(id, organization_id, editable, users(id, email), groups(id, name))";

        if (query?.select) {
            selectFields = query?.select;
        }

        const queryBuilder = this.supabase
            .from("signatures")
            .select(selectFields, { count: "exact" })
            .eq("user_id", this.user.id)
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log("Error fetching signatures from API: ", error);
            throw new Error("Error fetching signatures from API");
        }

        return { signatures: data, count };
    }

    public async getGroupSignatures(query) {
        const queryString = new URLSearchParams(query).toString();
        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.GET_GROUP_SIGNATURES}?${queryString}`,
            {
                method: "GET",
                query,
            }
        );

        if (error) {
            console.log("Error fetching group signatures from API: ", error);
            throw new Error("Error fetching group signatures from API");
        }

        return data;
    }

    public async getOrganizationSignatures(query) {
        const queryBuilder = this.supabase
            .from("signatures")
            .select(
                "*, users(email), sharedsignatures!inner(id, signature_id, organization_id, editable, users(id, email), groups(id, name))",
                { count: "exact" }
            )
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .not("sharedsignatures.organization_id", "is", null)
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log("Error fetching group signatures from API: ", error);
            throw new Error("Error fetching group signatures from API");
        }

        return { signatures: data, count };
    }

    public async getOrgUnsharedSignatures(query) {
        const queryString = new URLSearchParams(query).toString();
        const { data, count, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.GET_UNSHARED_ORG_SIGNATURES}?${queryString}`,
            {
                method: "GET",
                query,
            }
        );

        if (error) {
            console.log(
                "Error fetching unshared org signatures from API: ",
                error
            );
            throw new Error("Error fetching unshared org signatures from API");
        }

        return data;
    }

    public async getAllSharedSignatures(query) {
        const queryString = new URLSearchParams(query).toString();
        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.GET_ORG_SHARED_SIGNATURES}?${queryString}`,
            {
                method: "GET",
                query,
            }
        );

        if (error) {
            console.log(
                "Error fetching all organization's shared templates from API: ",
                error
            );
            throw new Error(
                "Error fetching all organization's shared templates from API"
            );
        }

        return data;
    }

    public async createSignature(signature) {
        if (!signature) {
            throw new Error(
                "Error creating new signature - no signature provided"
            );
        }

        const signatureToCreate = { ...signature };
        delete signatureToCreate.users;

        if (
            !signature.organization_id &&
            (!signature.sharedsignatures ||
                signature.sharedsignatures.length === 0)
        ) {
            // No shared signatures = Personal Signature
            return this.createPersonalSignature(signatureToCreate);
        }

        const { data, error } = await this.supabase.functions.invoke(
            SB_FUNCTIONS.SIGNATURES,
            {
                method: "POST",
                body: signatureToCreate,
            }
        );

        if (error) {
            console.log("Error creating signature: ", error);
            throw new Error("Error creating signature");
        }

        return data;
    }

    public async createPersonalSignature(signature) {
        if (!signature) {
            throw new Error(
                "Error creating new signature - no signature provided"
            );
        }

        let newSignature = {
            ...signature,
            public: false,
            user_id: this.user.id,
        };
        delete newSignature.sharedsignatures;

        const { data, error } = await this.supabase
            .from("signatures")
            .insert(newSignature)
            .select();

        if (error) {
            console.log("Error saving new signature: ", error);
            throw new Error("Error saving new signature");
        }

        const createdSignature = data[0];
        createdSignature.sharedsignatures = [];

        return createdSignature;
    }

    public async updateSignature(updatedSignature) {
        if (!updatedSignature) {
            throw new Error("Error updating signature - no signature provided");
        }

        const signatureToUpdate = { ...updatedSignature };
        delete signatureToUpdate.users;

        const isPersonalSignature =
            !updatedSignature.organization_id &&
            (!updatedSignature.sharedsignatures ||
                updatedSignature.sharedsignatures.length === 0) &&
            (!updatedSignature.added_sharedsignatures ||
                updatedSignature.added_sharedsignatures.length === 0) &&
            (!updatedSignature.removed_sharedsignatures ||
                updatedSignature.removed_sharedsignatures.length === 0);

        if (isPersonalSignature) {
            // No shared signatures = Personal Signature
            return this.updatePersonalSignature(signatureToUpdate);
        }

        const { data, error } = await this.supabase.functions.invoke(
            `${SB_FUNCTIONS.SIGNATURES}/${updatedSignature.id}`,
            {
                method: "PUT",
                body: signatureToUpdate,
            }
        );

        if (error) {
            console.log("Error updating signature: ", error);
            throw new Error("Error updating signature");
        }

        return data;
    }

    public async updatePersonalSignature(updatedSignature) {
        if (!updatedSignature || !updatedSignature.id) {
            throw new Error("Error updating signature - no signature provided");
        }

        let changedSignature = { ...updatedSignature };

        delete changedSignature.added_sharedsignatures;
        delete changedSignature.removed_sharedsignatures;
        delete changedSignature.sharedsignatures;

        const { error } = await this.supabase
            .from("signatures")
            .update(changedSignature)
            .eq("id", updatedSignature.id);

        if (error) {
            console.log("Error updating signature: ", error);
            throw new Error("Error updating signature");
        }

        changedSignature.sharedsignatures = [];
        return changedSignature;
    }

    public async deleteSignature(signatureId, isPersonal) {
        if (isPersonal) {
            return this.deletePersonalSignature(signatureId);
        }

        const { error } = this.supabase.functions.invoke(
            `${SB_FUNCTIONS.SIGNATURES}/${signatureId}`,
            {
                method: "DELETE",
            }
        );

        if (error) {
            console.log("Error deleting signature: ", error);
            throw new Error("Error deleting signature");
        }
    }

    public async deletePersonalSignature(signatureId) {
        if (!signatureId) {
            throw new Error("Error deleting signature - no signature provided");
        }

        const { error } = await this.supabase
            .from("signatures")
            .delete()
            .eq("id", signatureId);

        if (error) {
            console.log("Error deleting signature: ", error);
            throw new Error("Error deleting signature");
        }
    }

    // Shared Signatures
    public deleteSharedSignature(ssId) {
        const { error } = this.supabase.functions.invoke(
            `${SB_FUNCTIONS.SHAREDSIGNATURES}/${ssId}`,
            {
                method: "DELETE",
            }
        );

        if (error) {
            console.log("Error deleting shared signature: ", error);
            throw new Error("Error deleting shared signature");
        }
    }

    // Storage
    public async uploadAttachment(filePath, fileData, attachment) {
        const { error, data } = await this.supabase.storage
            .from("uploads")
            .upload(filePath, fileData, attachment);

        if (error) {
            console.log("Error uploading attachment: ", error);
            throw new Error("Error uploading attachment");
        }

        return data;
    }

    public async uploadTemplateImage(filePath, imageData, image) {
        const { error, data } = await this.supabase.storage
            .from("public-uploads")
            .upload(filePath, imageData, image);

        if (error) {
            console.log("Error uploading image: ", error);
            throw new Error("Error uploading image");
        }

        // Get public url
        const { data: urlData } = this.supabase.storage
            .from("public-uploads")
            .getPublicUrl(filePath);

        return {
            ...data,
            publicUrl: urlData.publicUrl,
        };
    }

    // Stripe
    public async getStripePortalSession(organizationId) {
        const { data, error } = await this.supabase.functions.invoke(
            SB_FUNCTIONS.STRIPE_PORTAL_SESSION,
            {
                body: {
                    organization: organizationId,
                },
            }
        );

        if (error) {
            console.log("Error fetching Stripe portal session: ", error);
            throw new Error("Error fetching Stripe portal session");
        }

        return data;
    }
}

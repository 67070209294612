import { TableHeader } from "@tiptap/extension-table-header";

const TableHeaderImproved = TableHeader.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            style: {
                default: null,
                renderHTML: (attributes) => {
                    if (!attributes.style) {
                        return {};
                    }

                    return {
                        style: attributes.style,
                    };
                },
                parseHTML: (element) => {
                    const style = element.getAttribute("style") || "";
                    return style;
                },
            },
        };
    },
});

export default TableHeaderImproved;

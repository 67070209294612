// @ts-nocheck
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";

// This component is used to protect routes that require a user to be logged in.
export const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {
        isOrgUser,
        isOrgAdmin,
        authUser,
        datastore,
        getUser,
        getOrganization,
        getAdminUser,
        getSubscription,
        logout,
    } = useAuth();

    useEffect(() => {
        async function loadData() {
            console.log(">>> datastore: ", datastore);
            console.log(">>> isOrgAdmin: ", isOrgAdmin());
            console.log(">>> isOrgUser: ", isOrgUser());

            try {
                await getUser();

                if (isOrgUser()) {
                    await getOrganization();
                }

                if (isOrgAdmin()) {
                    await getAdminUser();
                }

                await getSubscription();
            } catch (err) {
                console.error(err);
            }
        }

        console.log("Checking user permissions for protected route");
        loadData();
    }, []);

    useEffect(() => {
        const redirectPath = sessionStorage.getItem("redirectPath");
        if (redirectPath) {
            sessionStorage.removeItem("redirectPath");
            navigate(redirectPath);
        }
    }, []);

    useEffect(() => {
        async function handlePathChangeCheck() {
            if (!authUser) {
                console.error(">>> User is not loaded");
                await logout();
                // Store the current location in session storage
                sessionStorage.setItem("redirectPath", location.pathname);
                navigate("/login");
                return;
            }
        }

        handlePathChangeCheck();
    }, [authUser, location?.pathname])

    if (!datastore || !datastore.api || datastore.api.type === "public") {
        // Waiting to load completely
        return null;
    }

    return children;
};

// @ts-nocheck
import React, { useState } from "react";
import Link from "@mui/material/Link";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { useAuth } from "../hooks/useAuth";
import { VALIDATION_REGEXES } from "../helpers/constants";

import logo from "../assets/logo-dark_260x139.png";
import "./Login.scss";

export default function Login() {
    const { login } = useAuth();
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [email, setEmail] = useState("");

    const handleLogin = async (event) => {
        event.preventDefault();

        setLoading(true);
        setHasError(false);
        setEmailSent(false);

        const { error } = await login(email);

        setEmailSent(!error);
        setHasError(!!error);
        setLoading(false);
    };

    return (
        <Container className="login-container" component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <img className="logo" src={logo} alt="Toggles Logo" />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyPress={(ev) => {
                        if (
                            ev.key === "Enter" &&
                            !emailSent &&
                            VALIDATION_REGEXES.email.test(email)
                        ) {
                            ev.preventDefault();
                            handleLogin(ev);
                        }
                    }}
                />
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 4 }}
                    onClick={handleLogin}
                    disabled={
                        emailSent || !VALIDATION_REGEXES.email.test(email)
                    }
                    loading={loading}
                >
                    Send Login Link
                </LoadingButton>

                <Link
                    href="https://www.gettoggles.com/pricing"
                    underline="none"
                >
                    Don't have an account?
                </Link>

                {hasError && (
                    <Alert className="login-message" severity="error">
                        Something went wrong. Please try again.
                    </Alert>
                )}

                {emailSent && (
                    <Alert className="login-message" severity="success">
                        We've sent you an email with a link to sign in. Check
                        your inbox and click the link to continue.
                    </Alert>
                )}
            </Box>
        </Container>
    );
};
// @ts-nocheck
import React, { useState, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import BusinessIcon from "@mui/icons-material/Business";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { useAuth } from "../../../hooks/useAuth";
import { convertUTCToLocalTime } from "../../../helpers/timeHelpers";

import "./styles.scss";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

function SignatureRow({ signature, isPersonal, handleEdit, handleDelete, removeSharedSignature }) {
    const { user, isOrgUser, isOrgAdmin } = useAuth();
    const [open, setOpen] = useState(false);
    const isSignatureOwner = useMemo(() => {
        return !!signature && ((signature.user_id && signature.user_id === user.id) || (isOrgAdmin() && !signature.user_id))
    }, [signature, user]);
    const canEdit = useMemo(() => {
        return isSignatureOwner || isOrgAdmin() || signature.sharedsignatures[0]?.editable;
    }, [user, signature]);
    const canDelete = useMemo(() => {
        return isSignatureOwner;
    }, [user, signature]);

    const getSharedSignatureItem = (signatureId, sharedSignature) => {
        const isOrg = sharedSignature.organization_id != null; // checks if null and undefined with only double =
        const isGroup = !!sharedSignature.groups;
        const isUser = !!sharedSignature.users;

        let type;
        let name;
        let icon;

        if (isOrg) {
            type = 'organization'
            name = 'All users';
            icon = <BusinessIcon className="shared-signature-icon" />;
        } else if (isGroup) {
            type = 'group';
            name = sharedSignature.groups.name;
            icon = <GroupsIcon className="shared-signature-icon" />;
        } else if (isUser) {
            type = 'user';
            name = sharedSignature.users.email;
            icon = <PersonIcon className="shared-signature-icon" />;
        } else {
            return;
        }

        return (
            <div
                className="list-shared-signature"
                key={`list-shared-signature-${sharedSignature.id}`}
            >
                <div className="basic">
                    <div className="details">
                        <Typography className="signature-name" variant="subtitle2">
                            {icon}
                            {name}
                        </Typography>
                    </div>
                    <div className="item-actions">
                        {sharedSignature.editable && (
                            <Chip
                                className="action-count"
                                label={`Editable`}
                            />
                        )}
                        
                        {isOrgAdmin() && (
                            <Tooltip title={`Unshare signature with ${type}`}>
                                <span>
                                    <IconButton
                                        className="icon-button"
                                        aria-label="delete"
                                        disabled={!isOrgAdmin()}
                                        onClick={() =>
                                            removeSharedSignature(signatureId, sharedSignature.id)
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const getSignatureOwner = (signature) => {
        if (!!signature.user_id) {
            return signature.users?.email || "Unknown";
        }

        return "Organization";
    };

    const lastUpdatedDayJS = dayjs(convertUTCToLocalTime(signature?.updated_at || null));

    return (
        <>
            <TableRow
                key={signature.id}
                sx={{
                    "&:last-child td, &:last-child th": {
                        border: 0,
                    },
                }}
            >
                {isOrgUser() && (
                    <TableCell padding="checkbox">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </TableCell>
                )}
                <TableCell component="th" scope="row">
                    {signature.name}
                </TableCell>
                <TableCell align="left">
                    {isPersonal || !isOrgAdmin()
                        ? signature.description
                        : getSignatureOwner(signature)}
                </TableCell>
                <TableCell align="center">
                    <Tooltip
                        title={lastUpdatedDayJS.format("LLL")}
                        placement="right"
                    >
                        <span>{lastUpdatedDayJS.fromNow()}</span>
                    </Tooltip>
                </TableCell>
                <TableCell align="right">
                    {isOrgUser() && (
                        <Tooltip
                            title={`Signature is ${signature.enabled ? "enabled" : "disabled"}`}
                        >
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="Signature enabled or disbaled indicator"
                                    disabled
                                >
                                    {signature.enabled ? (
                                        <VisibilityIcon color="primary" />
                                    ) : (
                                        <VisibilityOffIcon />
                                    )}
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    <Tooltip
                        title={`${
                            canEdit
                                ? "Edit Signature"
                                : "You do not have permission to edit this signature"
                        }`}
                    >
                        <span>
                            <IconButton
                                className="icon-button"
                                aria-label="edit"
                                disabled={!canEdit}
                                onClick={() => handleEdit(signature)}
                            >
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={`${
                            canDelete
                                ? "Delete Signature"
                                : "You do not have permission to delete this signature"
                        }`}
                    >
                        <span>
                            <IconButton
                                className="icon-button"
                                aria-label="delete"
                                disabled={!canDelete}
                                onClick={() => handleDelete(signature.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
            </TableRow>

            {isOrgUser() && (
                <TableRow>
                    <TableCell style={{ padding: 0 }} colSpan={5}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <div className="group-signatures">
                                <Typography
                                    className="signatures-section-label"
                                    variant="subtitle"
                                    gutterBottom
                                    component="div"
                                >
                                    Shared with
                                </Typography>
                                {!signature.sharedsignatures || signature.sharedsignatures?.length === 0 ? (
                                    <Alert severity="warning">
                                        Signature is not shared with any users
                                        or groups
                                    </Alert>
                                ) : (
                                    <div className="list-container">
                                        {signature.sharedsignatures.map(
                                            (sharedSignature) =>
                                                getSharedSignatureItem(
                                                    signature.id,
                                                    sharedSignature
                                                )
                                        )}
                                    </div>
                                )}
                            </div>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default function SignatureList({
    signatures,
    loading,
    isPersonal,
    setPage,
    totalCount,
    page,
    pageSize,
    handleEdit,
    handleDelete,
    handleRemoveSharedSignature,
}) {
    const { isOrgUser, isOrgAdmin } = useAuth();
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getLoadingRow = () => {
        return (
            <TableRow>
                <TableCell colSpan={5} align="center">
                    <LoadingIndicator />
                </TableCell>
            </TableRow>
        );
    };

    const getRows = (isPersonal) => {
        if (loading) {
            return getLoadingRow();
        }

        if (signatures.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={5} align="center">
                        No signatures found
                    </TableCell>
                </TableRow>
            );
        }

        return signatures.map((signature) => (
            <SignatureRow
                key={`signature-row-${signature.id}`}
                signature={signature}
                isPersonal={isPersonal}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                removeSharedSignature={handleRemoveSharedSignature}
            />
        ));
    };

    return (
        <div className="data-list">
            <TableContainer className="data-table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="table-header">
                        <TableRow>
                            {isOrgUser() && <TableCell padding="checkbox" />}
                            <TableCell>Signature Name</TableCell>
                            <TableCell align="left">
                                {isPersonal || !isOrgAdmin()
                                    ? "Description"
                                    : "Owner"}
                            </TableCell>
                            <TableCell align="center">Last Updated</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{getRows(isPersonal)}</TableBody>
                </Table>
            </TableContainer>

            <Pagination
                className="table-paging"
                page={page}
                count={Math.ceil(totalCount / pageSize)}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
            />
        </div>
    );
}



// @ts-nocheck
import { createContext, useState, useEffect, useContext, useMemo } from "react";
import { Session } from "@supabase/supabase-js";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import useSupabase from "./useSupabase";
import { useLocalStorage } from "./useLocalStorage";
import LoadingIndicator from "../components/LoadingIndicator";
import {
    REACT_APP_AUTH_REDIRECT_URL,
    REACT_APP_PREM_SIGNUP_REDIRECT_URL,
    SB_FUNCTIONS,
    REACT_APP_STRIPE_PRICE_IDS,
} from "../helpers/constants";
import { convertUTCToLocalTime } from "../helpers/timeHelpers";
import DataStore from "../datastore";

const AuthContext = createContext({
    user: null,
    organization: null,
    adminuser: null,
    subscription: null,
    session: null,
    checkDomain: () => {},
    createPremiumSubscriptionWithTrial: () => {},
    signupOrg: () => {},
    signup: () => {},
    login: () => {},
    verifyToken: () => {},
    logout: () => {},
});

export const AuthProvider = ({ children }) => {
    const supabase = useSupabase();
    const navigate = useNavigate();
    const [authUser, setAuthUser] = useLocalStorage("authUser", null);
    const [user, setUser] = useLocalStorage("user", null);
    const [organization, setOrganization] = useLocalStorage("organization", null);
    const [adminuser, setAdminuser] = useLocalStorage("adminuser", null);
    const [subscription, setSubscription] = useLocalStorage("subscription", null);
    const [session, setSession] = useState<Session | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const checkDomain = async (domain) => {
        const { data: domainCheck, error: domainCheckError } = await supabase.functions.invoke(
            `${SB_FUNCTIONS.ORG_DOMAIN_CHECK}${domain}`,
            {
                method: "GET",
            }
        );

        if (domainCheckError || !domainCheck) {
            console.error("Error checking domain: ", domainCheckError);
            throw new Error("Error checking domain");
        }

        return domainCheck;
    };

    const createPremiumSubscriptionWithTrial = async (userData) => {
        const { data, error: funcError } = await supabase.functions.invoke(
            SB_FUNCTIONS.CREATE_TRIAL_SUBSCRIPTION,
            {
                body: {
                    user: userData.id,
                    name: userData.fullName,
                    email: userData.email,
                    priceId: REACT_APP_STRIPE_PRICE_IDS.premium[userData.period],
                    interval: userData.period,
                },
            }
        );

        if (funcError || !data) {
            console.error("Error creating premium subscription with trial: ", funcError);
            throw new Error("Error creating premium subscription with trial");
        }

        return data;
    };

    const signup = async (email, userData) => {
        const { data, error } = await supabase.auth.signInWithOtp({
            email: email,
            options: {
                shouldCreateUser: true,
                data: userData || {},
                emailRedirectTo:
                    userData?.signupType !== "premium"
                        ? REACT_APP_AUTH_REDIRECT_URL
                        : REACT_APP_PREM_SIGNUP_REDIRECT_URL,
            },
        });

        if (error) {
            console.log("signup error: ", error);
        }

        return { data, error };
    };

    const signupOrg = async (data) => {
        const { data: newOrg, error: orgError } =
            await supabase.functions.invoke(SB_FUNCTIONS.ORG_SIGNUP, {
                method: "POST",
                body: {
                    billing_email: data.email,
                    full_name: data.companyName,
                    domains: [data.domain],
                    branding: {
                        iconUrl: "",
                        logoUrl: "",
                        longName: "",
                        description: "",
                        primaryColor: "",
                        secondaryColor: "",
                        supportBtnLink: "",
                        supportBtnText: "",
                        onboardingEnabled: false,
                        supportBtnEnabled: false,
                        features: {
                            rules: {
                                enabled: true,
                                personal: true,
                                group: true,
                                organization: true,
                                sharing: true,
                            },
                            templates: {
                                enabled: true,
                                personal: true,
                                group: true,
                                organization: true,
                                sharing: true,
                            },
                            signatures: {
                                enabled: true,
                                personal: true,
                                group: true,
                                organization: true,
                                sharing: true,
                            },
                        },
                    },
                },
            });
        
        if (orgError || !newOrg) {
            console.error("Error creating organization: ", orgError);
            throw new Error("Error creating organization");
        }

        return newOrg;
    };

    const login = async (email) => {
        const { data, error } = await supabase.auth.signInWithOtp({
            email: email,
            options: {
                shouldCreateUser: false,
                emailRedirectTo: REACT_APP_AUTH_REDIRECT_URL,
            },
        });

        if (error) {
            console.log("login error: ", error);
        }
        
        return { data, error };
    };

    const verifyToken = async (userEmail, token) => {
        const { data, error } = await supabase.auth.verifyOtp({
            email: userEmail,
            token,
            type: "email",
        });

        if (error) {
            console.log("verifyToken error: ", error);
        }

        return { data, error };
    };

    const logout = async () => {
        const { error } = await supabase.auth.signOut();
        
        if (error) {
            console.log("signout error: ", error);
        }

        if (!error) {
            setAuthUser(null);
            setUser(null);
            setOrganization(null);
            setAdminuser(null);
            setSession(null);
            setSubscription(null);
            navigate("/login", { replace: true });
        }

        return { error };
    };

    const getUser = async (skipCache) => {
        // Check localstorage first
        if (user && !skipCache) {
            return { data: user, error: null };
        }

        const { data, error } = await supabase.from("users").select();

        let fetchedUser = null;
        if (data && data[0]) {
            fetchedUser = data[0];
        }

        setUser(error ? null : fetchedUser);

        return { data: fetchedUser, error };
    };

    const getOrganization = async () => {
        // Check localstorage first
        if (organization) {
            return { data: organization, error: null };
        }

        const { data, error } = await supabase.from("organizations").select();

        let fetchedOrg = null;
        if (data && data[0]) {
            fetchedOrg = data[0];
        }

        setOrganization(error ? null : fetchedOrg);

        return { data: fetchedOrg, error };
    };

    const getAdminUser = async () => {
        // Check localstorage first
        if (adminuser) {
            return { data: adminuser, error: null };
        }

        const { data, error } = await supabase.from("adminusers").select();

        let fetchedAdminUser = null;
        if (data && data[0]) {
            fetchedAdminUser = data[0];
        }

        setAdminuser(error ? null : fetchedAdminUser);

        return { data: fetchedAdminUser, error };
    };

    const shouldFetchSubscription = () => {
        if (!subscription) {
            return true;
        }

        const now = new Date();
        const subscriptionEndDate = convertUTCToLocalTime(subscription?.ends_at);

        // Check if now is within 1 day of subscription end date
        if (now > (subscriptionEndDate - 86400000)) {
            return true;
        }

        return false;
    }

    const getSubscription = async () => {
        // Check localstorage first
        if (!shouldFetchSubscription()) {
            return { data: subscription, error: null };
        }

        console.log('Fetching latest subscription data');
        const { data, error } = await supabase
            .from("subscriptions")
            .select("*");

        let fetchedSubscription = null;
        // Find active subscription
        if (data && data.length === 1) {
            fetchedSubscription = data[0];
        } else if (data && data.length > 1) {
            // Find active subscription or last updated
            const active = data.filter(
                (s) => s.status === "active" || s.status === "trialing"
            );
            if (active && active.length === 1) {
                fetchedSubscription = active[0];
            } else {
                fetchedSubscription = data.sort((a, b) => {
                    const aDate = new Date(a.updated_at);
                    const bDate = new Date(b.updated_at);
                    return bDate - aDate;
                })[0];
            }
        }

        setSubscription(error ? null : fetchedSubscription);

        return { data: fetchedSubscription, error };
    };

    const hasActiveSubscription = () => {
        return (
            subscription?.status === "active" ||
            subscription?.status === "trialing"
        );
    };

    const hasTrialSubscription = () => {
        return subscription?.status === "trialing";
    };

    const hasPausedSubscription = () => {
        return subscription?.status === "paused";
    };

    const hasCanceledSubscription = () => {
        return subscription?.status === "canceled";
    };

    const isOrgOwner = () => {
        return adminuser?.owner;
    };

    const isOrgAdmin = () => {
        return !!adminuser;
    };

    const isOrgUser = () => {
        return !!subscription?.organization_id;
    };

    const getOrgFeatures = () => {
        return organization?.branding?.features;
    };

    const getOrgFeature = (name) => {
        return organization?.branding?.features?.[name.toLowerCase()];
    };

    const isFeatureEnabled = (name) => {
        const feature = getOrgFeature(name);
        return !isOrgUser() || (!feature || feature.enabled);
    };

    const value = useMemo(
        () => ({
            datastore: new DataStore(user, isOrgAdmin(), isOrgOwner()),
            authUser,
            user,
            organization,
            adminuser,
            subscription,
            session,
            checkDomain,
            createPremiumSubscriptionWithTrial,
            signupOrg,
            signup,
            login,
            logout,
            verifyToken,
            getUser,
            setUser,
            getOrganization,
            setOrganization,
            getAdminUser,
            setAdminuser,
            getSubscription,
            setSubscription,
            hasActiveSubscription,
            hasTrialSubscription,
            hasPausedSubscription,
            hasCanceledSubscription,
            isOrgOwner,
            isOrgAdmin,
            isOrgUser,
            getOrgFeatures,
            getOrgFeature,
            isFeatureEnabled,
        }),
        [authUser, user, organization, adminuser, subscription, session]
    );

    useEffect(() => {
        const { data: listener } = supabase.auth.onAuthStateChange(
            (_event, session) => {
                setSession(session);
                setAuthUser(session?.user || null);
                setLoading(false);
            }
        );
        return () => {
            listener?.subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        // If session changes, fetch data
        if (session) {
            getUser();
            getOrganization();
            getAdminUser();
            getSubscription();
        }
    }, [session]);

    return (
        <AuthContext.Provider value={value}>
            {!loading ? children : (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}><LoadingIndicator /></Box>
            )}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

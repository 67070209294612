// @ts-nocheck
import React, { useState, useEffect, useRef, useMemo } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useAuth } from "../../../hooks/useAuth";
import RTEditor from "../../../components/RTEditor/RTEditor";
import ContentPreview from "../../../components/ContentPreview/ContentPreview";
import RTEInstructions from "../../../components/RTEIntstructions/RTEInstructions";
import { parseVariables } from "../../../components/VariableTypes/VariableTypes";
import VariableTypeSelection from "../../../components/VariableTypes/VariableTypeSelection";
import useDebounce from "../../../hooks/useDebounce";

import "./styles.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CreateEditSignature = ({
    open,
    onClose,
    onSave,
    isSaving,
    signature,
    organization,
}) => {
    const { datastore, user, isOrgUser, isOrgAdmin } = useAuth();
    const initialLoad = useRef(true);
    const [currentSignature, setCurrentSignature] = useState(
        JSON.parse(JSON.stringify(signature))
    );
    const [availableGroups, setAvailableGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [isPersonal, setIsPersonal] = useState(true);
    const [isSharedWithGroups, setIsSharedWithGroups] = useState(false);
    const [isSharedWithOrg, setIsSharedWithOrg] = useState(false);
    const [isSharedEditable, setIsSharedEditable] = useState(
        signature?.sharedsignatures[0]?.editable || false
    );
    const [activeStep, setActiveStep] = useState(0);
    const [hasError, setHasError] = useState(false);
    const [inputStateChanged, setInputStateChanged] = useState({
        name: false,
        description: false,
    });
    const [hasVariableErrors, setHasVariableErrors] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({
        severity: "success",
        message: "",
    });
    const dialogRef = useRef(null);

    const isEdit = useMemo(() => {
        return !!currentSignature && !!currentSignature.id;
    }, [currentSignature]);

    const isSignatureOwner = useMemo(() => {
        return (
            !!currentSignature &&
            ((currentSignature.user_id && currentSignature.user_id === user.id) ||
                (isOrgAdmin() && !currentSignature.user_id))
        );
    }, [currentSignature, user]);

    const isSharingAvailable = useMemo(() => {
        return isOrgAdmin() || (isOrgUser() && (isSignatureOwner || !currentSignature?.id));
    }, [currentSignature, user, isSignatureOwner]);

    const canEditSignature = useMemo(() => {
        return !isEdit || isSignatureOwner || isSharedEditable;
    }, [currentSignature, user, isSharedEditable]);

    const isOrgUnshared = useMemo(() => {
        return !!currentSignature && currentSignature.organization_id && (!currentSignature.sharedsignatures || currentSignature.sharedsignatures.length === 0);
    }, [currentSignature]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const loadGroups = async () => {
        try {
            const results = await datastore.api.getGroups({
                select: "id, name, allow_user_sharing",
                from: 0,
                to: 1000,
            });

            if (isOrgAdmin()) {
                // Org Admins can share with any group
                setAvailableGroups(results.groups);
                return;
            }

            // Filter out groups without sharing enabled
            const filteredGroups = results.groups.filter(
                (group) => group.allow_user_sharing
            );

            setAvailableGroups(filteredGroups);
        } catch (error) {
            console.error("Error loading groups: ", error);
            return [];
        }
    };

    const handleSignatureEnabledToggle = (ev) => {
        const updatedSignature = { ...currentSignature };
        updatedSignature.enabled = ev.target.checked;
        setCurrentSignature(updatedSignature);
    };

    const handlePersonalSignatureToggle = (ev) => {
        setIsPersonal(ev.target.checked);

        if (ev.target.checked) {
            refreshToPersonalSignature();
        }
    };

    const hanldeSharedWithGroupsToggles = (ev) => {
        if (ev.target.checked) {
            // Remove all shared signatures
            setIsPersonal(false);
            setIsSharedWithOrg(false);
        }

        setIsSharedWithGroups(ev.target.checked);

        if (!ev.target.checked) {
            refreshToPersonalSignature();
        }
    };

    const handleSharedWithOrgToggle = (ev) => {
        if (ev.target.checked) {
            // Remove all shared signatures
            const updatedSignature = { ...currentSignature };
            updatedSignature.sharedsignatures = [];
            setCurrentSignature(updatedSignature);
            setSelectedGroups([]);
            setIsSharedEditable(false);
            setIsPersonal(false);
            setIsSharedWithGroups(false);
        }

        setIsSharedWithOrg(ev.target.checked);

        if (!ev.target.checked) {
            refreshToPersonalSignature();
        }
    };

    const refreshToPersonalSignature = () => {
        // Remove all shared signatures
        const updatedSignature = { ...currentSignature };
        updatedSignature.sharedsignatures = [];
        setCurrentSignature(updatedSignature);
        setIsSharedEditable(false);
        setSelectedGroups([]);
        setIsSharedWithOrg(false);
        setIsSharedWithGroups(false);
    };

    const getOrCreateSharedSignature = (newShared) => {
        // Check signature.sharedsignatures for a matching sharedsignature (minus "id" field)
        const existingSharedSignature = currentSignature.sharedsignatures.find((ss) => {
            const ssCopy = { ...ss };
            delete ssCopy.id;
            delete ssCopy.user_id;
            delete ssCopy.group_id;
            return ssCopy.organization_id === newShared.organization_id
                && JSON.stringify(ssCopy.groups) === JSON.stringify(newShared.groups)
                && ssCopy.editable === newShared.editable
                && ssCopy.signature_id === newShared.signature_id;
        });

        return existingSharedSignature ? existingSharedSignature : newShared;
    };

    const handleClose = () => {
        if (!isSaving) {
            onClose();
        }
    };

    const handleSave = () => {
        if (!isSignatureDetailsValid() || !isSignatureValid()) {
            setSnackbarDetails({
                severity: "error",
                message: `Please fill in all required fields to create this signature`,
            });
            setSnackbarOpen(true);
            return;
        }

        const validSignature = { ...currentSignature };

        // Parse out variables from the signature content
        validSignature.variables = getSignatureVariables();

        onSave(validSignature);
    };

    const getSignatureVariables = () => {
        if (!currentSignature.metadata || !currentSignature.metadata.variables) {
            return [];
        }

        return currentSignature.metadata.variables.map((variable) => variable.name);
    };

    const isSignatureDetailsValid = () => {
        return currentSignature && currentSignature.name && currentSignature.description;
    };

    const isSignatureValid = () => {
        let isValid = currentSignature.html_content && currentSignature.html_content.length > 0;

        if (currentSignature.metadata && currentSignature.metadata.variables) {
            // Check if any variables are "dropdown" type and have no options or options that are empty strings
            const invalidDropdowns = currentSignature.metadata.variables.filter(
                (variable) =>
                    variable.type === "dropdown" &&
                    (!variable.options || variable.options.some((o) => !o))
            );

            if (invalidDropdowns.length > 0) {
                isValid = false;
            }

            if (hasVariableErrors) {
                isValid = false;
            }
        }

        return isValid;
    };

    const groupSignatureHasGroups = () => {
        if (!isSharedWithGroups) {
            return true;
        }

        return selectedGroups.length > 0;
    };

    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    useEffect(() => {
        loadGroups();
    }, []);

    useEffect(() => {
        if (!signature) {
            return;
        }

        const isEdit = !!signature && !!signature.id;
        setInputStateChanged({
            name: isEdit,
            description: isEdit,
        });
        setCurrentSignature(JSON.parse(JSON.stringify(signature)));
        setActiveStep(0);
        setHasError(false);

        // Determine groups signature is shared with and set selectedGroups
        if (signature.sharedsignatures?.length > 0) {
            const sGroups = [];
            let sharedWithOrg = false;
            signature.sharedsignatures.forEach((sr) => {
                if (sr.groups) {
                    sGroups.push(sr.groups);
                } else if (sr.organization_id) {
                    sharedWithOrg = true;
                }
            });

            setIsPersonal(!!signature.user_id && signature.sharedsignatures?.length === 0);
            setSelectedGroups(sGroups);
            setIsSharedWithOrg(sharedWithOrg);
            setIsSharedWithGroups(sGroups.length > 0);
            setIsSharedEditable(signature.sharedsignatures[0].editable);
        } else {
            setSelectedGroups([]);
            setIsPersonal(!isOrgUnshared);
            setIsSharedWithOrg(false);
            setIsSharedEditable(false);
        }
    }, [signature]);

    useEffect(() => {
        // Set on all sharedsignatures
        const updatedSignature = { ...currentSignature };
        updatedSignature.sharedsignatures.forEach((sr) => {
            sr.editable = isSharedEditable;
        });
        setCurrentSignature(updatedSignature);
    }, [isSharedEditable]);

    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }

        if (isSharedWithOrg) {
            const updatedSignature = { ...currentSignature };
            updatedSignature.sharedsignatures = [
                getOrCreateSharedSignature({
                    signature_id: currentSignature.id,
                    organization_id: organization.id,
                    groups: null,
                    editable: isSharedEditable,
                }),
            ];
            setSelectedGroups([]);
            setCurrentSignature(updatedSignature);
        } else {
            const updatedSignature = { ...currentSignature };
            updatedSignature.sharedsignatures = [];
            setCurrentSignature(updatedSignature);
        }
    }, [isSharedWithOrg]);

    useEffect(() => {
        if (!isPersonal && !isSharedWithGroups && !isSharedWithOrg) {
            setIsPersonal(!isOrgUnshared);
            refreshToPersonalSignature();
        }
    }, [isPersonal, isSharedWithGroups, isSharedWithOrg, isOrgUnshared]);

    useEffect(() => {
        // Parse variables from templateContent
        const variables = parseVariables(currentSignature.html_content);

        const newVariableData = variables.map((variable) => {
            return {
                name: variable,
                type: "text",
                default: "",
            };
        });

        // Create a new set of variableData and use existing data if matching name
        const updatedVariableData = newVariableData.map((variable) => {
            const existingVariable = currentSignature.metadata?.variables?.find(
                (v) => v.name === variable.name
            );
            return existingVariable ? existingVariable : variable;
        });

        setCurrentSignature((prev) => ({
            ...prev,
            metadata: {
                ...prev.metadata,
                variables: updatedVariableData,
            },
        }));
    }, [currentSignature?.html_content]);

    useDebounce(
        () => {
            setHasError(
                !isSignatureDetailsValid() ||
                    !isSignatureValid() ||
                    !groupSignatureHasGroups()
            );
        },
        [currentSignature, selectedGroups, isSharedWithGroups, isSharedWithOrg],
        300
    );

    if (!currentSignature) {
        return null;
    }

    return (
        <Dialog
            className="create-edit-signature-dialog"
            onClose={handleClose}
            open={open}
            fullWidth
            maxWidth="lg"
            ref={dialogRef}
        >
            <DialogTitle>{isEdit ? "Edit" : "Create"} Signature</DialogTitle>

            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent className="create-edit-dialog-content">
                {signature?.sharedsignatures?.length > 0 &&
                    isEdit &&
                    canEditSignature && (
                        <MuiAlert className="shared-warning" severity="warning">
                            Any changes to this signature will affect all{" "}
                            {isSharedWithOrg ? "users" : "groups"} it is shared
                            with
                        </MuiAlert>
                    )}
                {isEdit && !canEditSignature && (
                    <MuiAlert className="shared-warning" severity="warning">
                        You do not have permission to edit this shared
                        signature.
                    </MuiAlert>
                )}
                <Stepper activeStep={activeStep} orientation="vertical">
                    {/* STEP: DETAILS */}
                    <Step key="step-details">
                        <StepLabel>Signature Details</StepLabel>
                        <StepContent className="step-content">
                            <TextField
                                required
                                fullWidth
                                className="text-input"
                                label="Signature Name"
                                inputProps={{
                                    maxLength: 128,
                                }}
                                value={currentSignature?.name || ""}
                                disabled={!canEditSignature}
                                error={
                                    inputStateChanged.name &&
                                    !currentSignature.name
                                }
                                helperText={
                                    inputStateChanged.name &&
                                    !currentSignature.name
                                        ? "Signature name is required"
                                        : ""
                                }
                                onChange={(ev) =>
                                    setCurrentSignature((prev) => ({
                                        ...prev,
                                        name: ev.target.value,
                                    }))
                                }
                                onBlur={() =>
                                    setInputStateChanged((prev) => ({
                                        ...prev,
                                        name: true,
                                    }))
                                }
                            />
                            <TextField
                                required
                                fullWidth
                                className="text-input"
                                label="Signature Description"
                                inputProps={{
                                    maxLength: 256,
                                }}
                                value={currentSignature?.description || ""}
                                disabled={!canEditSignature}
                                error={
                                    inputStateChanged.description &&
                                    !currentSignature.description
                                }
                                helperText={
                                    inputStateChanged.description &&
                                    !currentSignature.description
                                        ? "Signature description is required"
                                        : ""
                                }
                                onChange={(ev) =>
                                    setCurrentSignature((prev) => ({
                                        ...prev,
                                        description: ev.target.value,
                                    }))
                                }
                                onBlur={() =>
                                    setInputStateChanged((prev) => ({
                                        ...prev,
                                        description: true,
                                    }))
                                }
                            />
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        disabled={
                                            !isSignatureDetailsValid() ||
                                            isSaving
                                        }
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>

                    {/* STEP: Signature Content */}
                    <Step key="step-signature-content">
                        <StepLabel>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                Signature Content
                                <RTEInstructions />
                                {currentSignature?.html_content && (
                                    <ContentPreview
                                        htmlString={
                                            currentSignature.html_content
                                        }
                                    />
                                )}
                            </Box>
                        </StepLabel>
                        <StepContent className="step-content">
                            {/* Signature Editor */}
                            <RTEditor
                                content={currentSignature.html_content}
                                saveContent={(htmlContent) => {
                                    setCurrentSignature((prev) => ({
                                        ...prev,
                                        html_content: htmlContent,
                                    }));
                                }}
                                modalContainer={dialogRef}
                                canEdit={canEditSignature}
                                datastore={datastore}
                                uploadEntityId={
                                    isOrgUser() ? organization.id : user.id
                                }
                            />
                            <VariableTypeSelection
                                variables={currentSignature?.metadata?.variables}
                                canEdit={canEditSignature}
                                handleChanges={(updatedData) => {
                                    setCurrentSignature((prev) => ({
                                        ...prev,
                                        metadata: {
                                            ...prev.metadata,
                                            variables: updatedData,
                                        },
                                    }));
                                }}
                                setIsValid={(isValid) => setHasVariableErrors(!isValid)}
                            />
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={
                                            isSharingAvailable
                                                ? handleNext
                                                : handleSave
                                        }
                                        disabled={
                                            !isSignatureValid() || isSaving
                                        }
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {isSharingAvailable
                                            ? "Next"
                                            : isEdit
                                            ? "Save Signature"
                                            : "Create Signature"}
                                    </Button>
                                    <Button
                                        onClick={handleBack}
                                        disabled={isSaving}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>

                    {/* STEP: SHARING */}
                    {isSharingAvailable && (
                        <Step key="step-sharing">
                            <StepLabel>Signature Sharing</StepLabel>
                            <StepContent className="step-content">
                                <FormControlLabel
                                    className="org-shared-toggle"
                                    control={
                                        <Switch
                                            checked={currentSignature?.enabled}
                                            onChange={
                                                handleSignatureEnabledToggle
                                            }
                                            inputProps={{
                                                "aria-label":
                                                    "Signature enabled toggle switch",
                                            }}
                                        />
                                    }
                                    label="Signature enabled"
                                />
                                {(!currentSignature?.id ||
                                    (isSignatureOwner &&
                                        !currentSignature?.organization_id) ||
                                    (!isSharedWithGroups &&
                                        !isSharedWithOrg)) &&
                                    !isOrgUnshared && (
                                        <FormControlLabel
                                            className="org-shared-toggle"
                                            control={
                                                <Switch
                                                    checked={isPersonal}
                                                    onChange={
                                                        handlePersonalSignatureToggle
                                                    }
                                                    disabled={
                                                        !isSharedWithGroups &&
                                                        !isSharedWithOrg
                                                    }
                                                    inputProps={{
                                                        "aria-label":
                                                            "Peronsal signature toggle switch",
                                                    }}
                                                />
                                            }
                                            label="Personal Signature"
                                        />
                                    )}

                                {isOrgAdmin() && (
                                    <FormControlLabel
                                        className="org-shared-toggle"
                                        control={
                                            <Switch
                                                checked={
                                                    isSharedWithOrg || false
                                                }
                                                disabled={
                                                    isEdit &&
                                                    isSharedWithOrg &&
                                                    signature.sharedsignatures
                                                        .length > 0
                                                }
                                                onChange={
                                                    handleSharedWithOrgToggle
                                                }
                                                inputProps={{
                                                    "aria-label":
                                                        "Shared signature with entire organization toggle switch",
                                                }}
                                            />
                                        }
                                        label="Share signature with entire organization"
                                    />
                                )}

                                <FormControlLabel
                                    className="org-shared-toggle"
                                    control={
                                        <Switch
                                            checked={
                                                isSharedWithGroups || false
                                            }
                                            disabled={
                                                isEdit &&
                                                isSharedWithGroups &&
                                                signature.sharedsignatures
                                                    .length > 0
                                            }
                                            onChange={
                                                hanldeSharedWithGroupsToggles
                                            }
                                            inputProps={{
                                                "aria-label":
                                                    "Shared signature with groups toggle switch",
                                            }}
                                        />
                                    }
                                    label="Share signature with groups"
                                />

                                {isSharedWithGroups && (
                                    <>
                                        <FormControlLabel
                                            className="allow-editing-toggle"
                                            control={
                                                <Switch
                                                    checked={
                                                        isSharedEditable ||
                                                        false
                                                    }
                                                    onChange={(ev) => {
                                                        setIsSharedEditable(
                                                            ev.target.checked
                                                        );
                                                    }}
                                                    inputProps={{
                                                        "aria-label":
                                                            "Allow editing of signature by group members toggle switch",
                                                    }}
                                                    disabled={
                                                        isEdit &&
                                                        !isSignatureOwner &&
                                                        signature
                                                            .sharedsignatures
                                                            .length > 0
                                                    }
                                                />
                                            }
                                            label="Allow editing by shared group members"
                                        />

                                        <Autocomplete
                                            className="group-members-list"
                                            multiple
                                            disableCloseOnSelect
                                            disableClearable={isEdit}
                                            getOptionLabel={(option) =>
                                                typeof option === "string"
                                                    ? option
                                                    : option.name
                                            }
                                            options={availableGroups}
                                            noOptionsText="No groups found"
                                            value={selectedGroups}
                                            getOptionDisabled={(option) =>
                                                !isOrgAdmin() &&
                                                !isSignatureOwner &&
                                                signature.sharedsignatures.some(
                                                    (r) =>
                                                        r.groups?.id ===
                                                        option.id
                                                )
                                            }
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.id === value.id}
                                            onChange={(event, newValue) => {
                                                const updatedSignature = {
                                                    ...currentSignature,
                                                };

                                                const updatedSharedSignatures =
                                                    newValue.map((group) =>
                                                        getOrCreateSharedSignature(
                                                            {
                                                                signature_id:
                                                                    currentSignature.id,
                                                                groups: group,
                                                                organization_id:
                                                                    null,
                                                                editable:
                                                                    isSharedEditable,
                                                            }
                                                        )
                                                    );

                                                updatedSignature.sharedsignatures =
                                                    updatedSharedSignatures;
                                                setCurrentSignature(
                                                    updatedSignature
                                                );

                                                setSelectedGroups(newValue);
                                            }}
                                            renderTags={(
                                                tagValue,
                                                getTagProps
                                            ) =>
                                                tagValue.map(
                                                    (option, index) => {
                                                        const {
                                                            key,
                                                            ...tagProps
                                                        } = getTagProps({
                                                            index,
                                                        });
                                                        return (
                                                            <Chip
                                                                key={key}
                                                                label={
                                                                    option.name
                                                                }
                                                                {...tagProps}
                                                                disabled={
                                                                    !isOrgAdmin() &&
                                                                    !isSignatureOwner &&
                                                                    signature.sharedsignatures.some(
                                                                        (r) =>
                                                                            r
                                                                                .groups
                                                                                ?.id ===
                                                                            option.id
                                                                    )
                                                                }
                                                            />
                                                        );
                                                    }
                                                )
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Share signature with group"
                                                    fullWidth
                                                />
                                            )}
                                            renderOption={(
                                                props,
                                                option,
                                                { selected }
                                            ) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={
                                                            <CheckBoxOutlineBlankIcon fontSize="small" />
                                                        }
                                                        checkedIcon={
                                                            <CheckBoxIcon fontSize="small" />
                                                        }
                                                        style={{
                                                            marginRight: 8,
                                                        }}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </li>
                                            )}
                                        />
                                    </>
                                )}

                                <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleSave}
                                            disabled={hasError || isSaving}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {isEdit
                                                ? "Save Signature"
                                                : "Create Signature"}
                                        </Button>
                                        <Button
                                            onClick={handleBack}
                                            disabled={isSaving}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    )}
                </Stepper>
            </DialogContent>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarDetails.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default CreateEditSignature;

// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAuth } from "../../hooks/useAuth";

import "./styles.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function StarterAccount() {
    const { user, setUser, datastore } = useAuth();
    let currentUser = useRef(user);
    const [hasChanges, setHasChanges] = useState(false);
    const [userSettings, setUserSettings] = useState({
        fullName: "",
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({
        severity: "success",
        message: "",
    });

    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleSave = async () => {
        try {
            const updatedUser = await datastore.api.updateAccount({
                ...currentUser.current,
                full_name: userSettings.fullName,
            });

            setUserSettings({
                fullName: updatedUser.full_name || "",
            });

            currentUser.current = updatedUser;
            setUser(updatedUser);

            setSnackbarDetails({
                severity: "success",
                message: "Account updated successfully",
            });
            setSnackbarOpen(true);
        } catch (error) {
            console.error("error updating acocunt", error);
            setSnackbarDetails({
                severity: "error",
                message: "Error updating account - please try again",
            });
            setSnackbarOpen(true);
            return;
        }
    };

    useEffect(() => {
        setHasChanges(
            userSettings.fullName !== (!!currentUser.current.full_name ? currentUser.current.full_name : '')
        );
    }, [userSettings]);

    useEffect(() => {
        if (user) {
            setUserSettings({
                fullName: user.full_name || "",
            });
        }
    }, [user]);

    return (
        <Container className="account-container" maxWidth={false}>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <Typography
                        className="column-title"
                        variant="h5"
                        component="h2"
                    >
                        Account Settings
                    </Typography>
                    <TextField
                        fullWidth
                        className="text-input"
                        label="Full Name"
                        inputProps={{
                            maxLength: 64,
                        }}
                        value={userSettings?.fullName || ""}
                        onChange={(ev) =>
                            setUserSettings((prev) => ({
                                ...prev,
                                fullName: ev.target.value,
                            }))
                        }
                    />
                    <TextField
                        disabled
                        fullWidth
                        className="text-input"
                        label="Email"
                        value={user?.email || ""}
                    />
                    <TextField
                        disabled
                        fullWidth
                        className="text-input"
                        label="Account Created"
                        value={
                            new Date(
                                user.created_at
                            ).toLocaleDateString() || ""
                        }
                    />

                    {hasChanges && (
                        <Button
                            fullWidth
                            size="large"
                            className="save-button"
                            variant="contained"
                            onClick={handleSave}
                        >
                            Update Account
                        </Button>
                    )}
                </Grid>
            </Grid>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarDetails.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

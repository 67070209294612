// @ts-nocheck
import React, { useEffect } from "react";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import NotFound from "../../pages/NotFound";
import { useAuth } from "../../hooks/useAuth";
import StarterAccount from "./StarterAccount";
import PremiumAccount from "./PremiumAccount";
import AdminAccount from "./AdminAccount";
import OrgUserAccount from "./OrgUserAccount";

import "./styles.scss";

export default function Account() {
    useDocumentTitle("Toggles Hub | Account");
    const { getUser, hasActiveSubscription, hasPausedSubscription, isOrgUser, isOrgAdmin } = useAuth();
    
    useEffect(() => {
        getUser(true);
    }, []);

    if (!hasActiveSubscription() && !hasPausedSubscription()) {
        return <StarterAccount />;
    }

    if ((hasActiveSubscription() || hasPausedSubscription()) && !isOrgUser()) {
        return <PremiumAccount />;
    }

    if ((hasActiveSubscription() || hasPausedSubscription()) && (isOrgUser() || isOrgAdmin())) {
        return <OrgUserAccount />;
    }

    // Redirect to 404 if none of the above conditions are met
    return <NotFound />;
}

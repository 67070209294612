// @ts-nocheck
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReplyIcon from "@mui/icons-material/Reply";
import NoEncryptionIcon from "@mui/icons-material/NoEncryption";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import LoadingIndicator from "../../../components/LoadingIndicator";

import "./styles.scss";

const USER_STATUSES = {
    active: 'Active',
    inactive: 'Inactive',
    pending: 'Pending',
};

export default function UserList({
    users,
    loading,
    setPage,
    totalCount,
    page,
    pageSize,
    addAdmin,
    removeAdmin,
    resendInvite,
    deleteInvite,
    deactivateUser,
    reactivateUser,
    isOwner,
}) {
    const handlePageChange = (event, value) => {
        setPage(value)
    };

    const getLoadingRow = () => {
        return (
            <TableRow>
                <TableCell colSpan={isOwner ? 5 : 4} align="center">
                    <LoadingIndicator />
                </TableCell>
            </TableRow>
        );
    };

    const getUserRoleText = (user) => {
        if (!user?.adminuser) {
            return "User";
        }

        return user.adminuser.owner ? "Owner" : "Admin";
    }

    const getUserRole = (user) => {
        if (!user?.adminuser) {
            return <Chip label="User" variant="outlined" />;
        }

        return user.adminuser.owner ? (
            <Chip label="Owner" color="success" />
        ) : (
            <Chip label="Admin" color="primary" />
        );
    };

    const getActions = (user) => {
        switch (user.organizationuser?.status) {
            case 'active':
                const userRole = getUserRoleText(user);
                
                if (userRole === 'Owner') {
                    return null;
                }

                return (
                    <>
                        {userRole === "User" ? (
                            <Tooltip title="Set as admin" placement="top">
                                <span>
                                    <IconButton
                                        className="icon-button"
                                        aria-label="Add Admin"
                                        onClick={() => addAdmin(user)}
                                    >
                                        <GroupAddIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Remove as admin" placement="top">
                                <span>
                                    <IconButton
                                        className="icon-button"
                                        aria-label="Remove Admin"
                                        onClick={() => removeAdmin(user)}
                                        disabled={userRole === "Owner"}
                                    >
                                        <GroupRemoveIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                        <Tooltip title="Deactivate User" placement="top">
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="Deactivate User"
                                    onClick={() => deactivateUser(user)}
                                >
                                    <NoEncryptionIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </>
                );
            case 'inactive':
                return (
                    <Tooltip title="Reactivate User" placement="top">
                        <span>
                            <IconButton
                                className="icon-button"
                                aria-label="Reactivate User"
                                onClick={() => reactivateUser(user)}
                            >
                                <EnhancedEncryptionIcon fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                );
            case 'pending':
                return (
                    <>
                        <Tooltip title="Resend Invite" placement="top">
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="Resend Invite"
                                    onClick={() => resendInvite(user)}
                                >
                                    <ReplyIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete Invite" placement="top">
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="Delete Invite"
                                    onClick={() => deleteInvite(user)}
                                >
                                    <DeleteForeverIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </>
                );
            default:
                return null;
        }
    };

    const getRows = () => {
        if (loading) {
            return getLoadingRow();
        }

        if (users.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={isOwner ? 5 : 4} align="center">
                        No users found
                    </TableCell>
                </TableRow>
            );
        }

        return users.map((user) => {
            const userRole = getUserRoleText(user);

            return (
                <TableRow
                    className="user-row"
                    key={user.id}
                    sx={{
                        "&:last-child td, &:last-child th": {
                            border: 0,
                        },
                    }}
                >
                    <TableCell component="th" scope="row">
                        {user.email.toLowerCase()}
                    </TableCell>
                    <TableCell align="left">{user.full_name}</TableCell>
                    <TableCell align="center">{getUserRole(user)}</TableCell>
                    <TableCell align="center" className={`status ${user.organizationuser?.status}`}>{USER_STATUSES[user.organizationuser?.status] || 'Active'}</TableCell>
                    <TableCell align="right">
                        {getActions(user)}
                    </TableCell>
                </TableRow>
            );
        });
    };

    return (
        <div className="data-list">
            <TableContainer className="data-table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="table-header">
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="center">Role</TableCell>
                            <TableCell align="center">Status</TableCell>
                            {isOwner && <TableCell align="right"></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>{getRows()}</TableBody>
                </Table>
            </TableContainer>

            <Pagination
                className="table-paging"
                page={page}
                count={Math.ceil(totalCount / pageSize)}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
            />
        </div>
    );
}



// @ts-nocheck
import { v4 as uuidv4 } from "uuid";
import { decode } from "base64-arraybuffer";

export const getActionAttachments = (actions) => {
    let { attachments } = actions.reduce(
        (acc, action) => {
            if (action.type === "attachment") {
                acc.attachments.push(...action.attachments);
            }
            return acc;
        },
        { attachments: [] }
    );

    return attachments;
};

export const getActionNonUploadedAttachments = (actions) => {
    let { attachments } = actions.reduce(
        (acc, action) => {
            if (action.type === "attachment") {
                action.attachments.forEach((attachment) => {
                    if (!attachment.id) {
                        acc.attachments.push(attachment);
                    }
                });
            }
            return acc;
        },
        { attachments: [] }
    );

    return attachments;
};

export const handleAttachments = async (rule, isUpdate, entityId, datastore) => {
    // If attachments, upload them to storage
    const attachments = !isUpdate
        ? getActionAttachments(rule.metadata.actions)
        : getActionNonUploadedAttachments(rule.metadata.actions);

    if (attachments && attachments.length > 0) {
        try {
            const uploadResults = await Promise.all(
                attachments.map(async (attachment) =>
                    uploadAttachment(entityId, attachment, datastore)
                )
            );
    
            // Update action attachments to include the new attachment paths
            rule.metadata.actions.forEach((action) => {
                if (action.type === "attachment") {
                    action.attachments.forEach((attachment) => {
                        const uploadResult = uploadResults.find(
                            (ur) => ur.name === attachment.name
                        );
                        if (uploadResult) {
                            attachment.url = uploadResult.path;
                            attachment.id = uploadResult.id;

                            delete attachment.data;
                        }
                    });
                }
            });

            return rule;
        } catch (err) {
            console.log("Error uploading attachment(s): ", err);
            throw new Error("Error uploading attachment(s) - rule not created");
        }
    } else {
        return rule;
    }
};

export const uploadAttachment = async (entityId, attachment, datastore) => {
    if (!attachment) {
        throw new Error("Error uploading attachment - no attachment provided");
    }

    if (!attachment.storage) {
        throw new Error(
            "Error uploading attachment - not to be stored in storage"
        );
    }

    if (!entityId) {
        throw new Error("Error uploading attachment - no entity ID provided");
    }

    const fileGUID = uuidv4();
    const filePath = `${entityId}/${fileGUID}`;
    const fileData = decode(attachment.data);

    await datastore.api.uploadAttachment(filePath, fileData, {
        contentType: attachment.type,
        cacheControl: "2592000",
        upsert: false,
    });

    return {
        id: fileGUID,
        path: filePath,
        name: attachment.name,
    };
};

// @ts-nocheck
export const convertMinutes = (minutes) => {
    if (minutes < 60) {
        return minutes + " minute" + (minutes > 1 ? "s" : ""); // Handle plural for minutes
    } else if (minutes < 1440) {
        return (
            Math.floor(minutes / 60) + " hour" + (minutes / 60 > 1 ? "s" : "")
        ); // Handle plural for hours
    } else {
        return (
            Math.floor(minutes / 1440) +
            " day" +
            (minutes / 1440 > 1 ? "s" : "")
        ); // Handle plural for days
    }
};

export const convertUTCToLocalTime = (utcDateString) => {
    if (!utcDateString) {
        return;
    }

    // Create a Date object from the UTC string
    const utcDate = new Date(utcDateString);

    // Get the time zone offset in minutes
    const offsetMinutes = utcDate.getTimezoneOffset();

    // Convert the offset from minutes to milliseconds
    const offsetMilliseconds = offsetMinutes * 60 * 1000;

    // Create a new Date object with the local time
    const localDate = new Date(utcDate.getTime() - offsetMilliseconds);

    // Return the formatted local time string
    return localDate;
};

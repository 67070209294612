// @ts-nocheck
import React, { useState, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import BusinessIcon from "@mui/icons-material/Business";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { useAuth } from "../../../hooks/useAuth";
import { convertUTCToLocalTime } from "../../../helpers/timeHelpers";

import "./styles.scss";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

function TemplateRow({ template, isPersonal, handleEdit, handleDelete, removeSharedTemplate }) {
    const { user, isOrgUser, isOrgAdmin } = useAuth();
    const [open, setOpen] = useState(false);
    const isTemplateOwner = useMemo(() => {
        return !!template && ((template.user_id && template.user_id === user.id) || (isOrgAdmin() && !template.user_id))
    }, [template, user]);
    const isPremiumExample = useMemo(() => {
        return !!template && template.metadata?.premium_example;
    });
    const canEdit = useMemo(() => {
        return isTemplateOwner || isOrgAdmin() || template.sharedtemplates[0]?.editable;
    }, [user, template]);
    const canDelete = useMemo(() => {
        return !isPremiumExample && isTemplateOwner;
    }, [user, template]);

    const getSharedTemplateItem = (templateId, sharedTemplate) => {
        const isOrg = sharedTemplate.organization_id != null; // checks if null and undefined with only double =
        const isGroup = !!sharedTemplate.groups;
        const isUser = !!sharedTemplate.users;

        let type;
        let name;
        let icon;

        if (isOrg) {
            type = 'organization'
            name = 'All users';
            icon = <BusinessIcon className="shared-template-icon" />;
        } else if (isGroup) {
            type = 'group';
            name = sharedTemplate.groups.name;
            icon = <GroupsIcon className="shared-template-icon" />;
        } else if (isUser) {
            type = 'user';
            name = sharedTemplate.users.email;
            icon = <PersonIcon className="shared-template-icon" />;
        } else {
            return;
        }

        return (
            <div
                className="list-shared-template"
                key={`list-shared-template-${sharedTemplate.id}`}
            >
                <div className="basic">
                    <div className="details">
                        <Typography className="template-name" variant="subtitle2">
                            {icon}
                            {name}
                        </Typography>
                    </div>
                    <div className="item-actions">
                        {sharedTemplate.editable && (
                            <Chip
                                className="action-count"
                                label={`Editable`}
                            />
                        )}
                        {isOrgAdmin() && (
                            <Tooltip title={`Unshare template with ${type}`}>
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="delete"
                                    disabled={!isOrgAdmin()}
                                    onClick={() =>
                                        removeSharedTemplate(templateId, sharedTemplate.id)
                                    }
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const getTemplateOwner = (template) => {
        if (!!template.user_id) {
            return template.users?.email || "Unknown";
        }

        return "Organization";
    };

    const lastUpdatedDayJS = dayjs(convertUTCToLocalTime(template?.updated_at || null));

    return (
        <>
            <TableRow
                key={template.id}
                sx={{
                    "&:last-child td, &:last-child th": {
                        border: 0,
                    },
                }}
            >
                {isOrgUser() && (
                    <TableCell padding="checkbox">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </TableCell>
                )}
                <TableCell component="th" scope="row">
                    {template.name}
                </TableCell>
                <TableCell align="left">
                    {isPersonal || !isOrgAdmin()
                        ? template.description
                        : getTemplateOwner(template)}
                </TableCell>
                <TableCell align="center">
                    <Tooltip
                        title={lastUpdatedDayJS.format("LLL")}
                        placement="right"
                    >
                        <span>{lastUpdatedDayJS.fromNow()}</span>
                    </Tooltip>
                </TableCell>
                <TableCell align="right">
                    {isPremiumExample && (
                        <Tooltip title="Example Premium Template">
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="Example Premium Template warning"
                                    disabled
                                >
                                    <InfoOutlinedIcon color="info" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    {isOrgUser() && (
                        <Tooltip
                            title={`Template is ${template.enabled ? "enabled" : "disabled"}`}
                        >
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="Template enabled or disabled indicator" 
                                    disabled
                                >
                                    {template.enabled ? (
                                        <VisibilityIcon color="primary" />
                                    ) : (
                                        <VisibilityOffIcon />
                                    )}
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    <Tooltip
                        title={`${
                            canEdit
                                ? "Edit Template"
                                : "You do not have permission to edit this template"
                        }`}
                    >
                        <span>
                            <IconButton
                                className="icon-button"
                                aria-label="edit"
                                disabled={!canEdit}
                                onClick={() => handleEdit(template)}
                            >
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={`${
                            canDelete
                                ? "Delete Template"
                                : "You do not have permission to delete this template"
                        }`}
                    >
                        <span>
                            <IconButton
                                className="icon-button"
                                aria-label="delete"
                                disabled={!canDelete}
                                onClick={() => handleDelete(template.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
            </TableRow>

            {isOrgUser() && (
                <TableRow>
                    <TableCell style={{ padding: 0 }} colSpan={5}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <div className="group-templates">
                                <Typography
                                    className="templates-section-label"
                                    variant="subtitle"
                                    gutterBottom
                                    component="div"
                                >
                                    Shared with
                                </Typography>
                                {!template.sharedtemplates ||
                                template.sharedtemplates?.length === 0 ? (
                                    <Alert severity="warning">
                                        Template is not shared with any users or
                                        groups
                                    </Alert>
                                ) : (
                                    <div className="list-container">
                                        {template.sharedtemplates.map(
                                            (sharedTemplate) =>
                                                getSharedTemplateItem(
                                                    template.id,
                                                    sharedTemplate
                                                )
                                        )}
                                    </div>
                                )}
                            </div>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default function TemplateList({
    templates,
    loading,
    isPersonal,
    setPage,
    totalCount,
    page,
    pageSize,
    handleEdit,
    handleDelete,
    handleRemoveSharedTemplate,
}) {
    const { isOrgUser, isOrgAdmin } = useAuth();
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getLoadingRow = () => {
        return (
            <TableRow>
                <TableCell colSpan={5} align="center">
                    <LoadingIndicator />
                </TableCell>
            </TableRow>
        );
    };

    const getRows = (isPersonal) => {
        if (loading) {
            return getLoadingRow();
        }

        if (templates.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={5} align="center">
                        No templates found
                    </TableCell>
                </TableRow>
            );
        }

        return templates.map((template) => (
            <TemplateRow
                key={`template-row-${template.id}`}
                template={template}
                isPersonal={isPersonal}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                removeSharedTemplate={handleRemoveSharedTemplate}
            />
        ));
    };

    return (
        <div className="data-list">
            <TableContainer className="data-table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="table-header">
                        <TableRow>
                            {isOrgUser() && <TableCell padding="checkbox" />}
                            <TableCell>Template Name</TableCell>
                            <TableCell align="left">
                                {isPersonal || !isOrgAdmin()
                                    ? "Description"
                                    : "Owner"}
                            </TableCell>
                            <TableCell align="center">Last Updated</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{getRows(isPersonal)}</TableBody>
                </Table>
            </TableContainer>

            <Pagination
                className="table-paging"
                page={page}
                count={Math.ceil(totalCount / pageSize)}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
            />
        </div>
    );
}



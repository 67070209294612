// @ts-nocheck
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import EmailIcon from "@mui/icons-material/Email";
import AbcIcon from "@mui/icons-material/Abc";
import LinkIcon from "@mui/icons-material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import { VALIDATION_REGEXES } from "./regexes";
import AttachmentInput from "../../../components/AttachmentInput/AttachmentInput";
import VariableHelpPopper from "../../../components/VariableHelpPopper/VariableHelpPopper";
import { useAuth } from "../../../hooks/useAuth";

const EditAction = ({
    action,
    index,
    canEdit,
    updateAction,
    availableTemplates,
    availableSignatures,
    hasSignatureAction,
    hasDelayAction,
    hasTemplateAction,
    hasLinkAction,
}) => {
    const { user, isFeatureEnabled, hasActiveSubscription } = useAuth();
    const [missingAction, setMissingAction] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateSearchInputValue, setTemplateSearchInputValue] =
        useState("Select template...");
    const [selectedSignature, setSelectedSignature] = useState(null);
    const [signatureSearchInputValue, setSignatureSearchInputValue] = useState(
        "Select signature..."
    );
    const [delayInterval, setDelayInterval] = useState("days");
    const [delayQuantity, setDelayQuantity] = useState(1);

    const handleTypeChange = (event, newType) => {
        let updatedAction;
        let shouldUpdate = true;

        switch (newType) {
            case "tag":
                updatedAction = {
                    type: newType,
                    tag: "",
                    location: "start",
                };
                break;
            case "recipient":
                updatedAction = {
                    type: newType,
                    recipientType: "to",
                    emails: [],
                };
                break;
            case "attachment":
                updatedAction = {
                    type: newType,
                    attachments: [],
                };
                break;
            case "link":
                updatedAction = {
                    type: newType,
                    text: "",
                    url: "",
                };
                break;
            case "template":
                updatedAction = {
                    type: newType,
                    templateId: "",
                };
                break;
            case "signature":
                updatedAction = {
                    type: newType,
                    signatureId: "",
                };
                break;
            case "delay":
                updatedAction = {
                    type: newType,
                    method: "schedule",
                };
                break;
            default:
                shouldUpdate = false;
                break;
        }

        if (shouldUpdate) {
            updateAction(updatedAction, index);
        }
    };

    const allowActionType = (type) => {
        switch (type) {
            case "template":
                if (!hasActiveSubscription()) {
                    return {
                        allow: false,
                        reason: 'Upgrade to Premium to use this feature',
                    };
                }

                if (!isFeatureEnabled('templates')) {
                    return {
                        allow: false,
                        reason: "Templates are disabled for your organization",
                    };
                }

                if (hasTemplateAction) {
                    return {
                        allow: false,
                        reason: "Only one template action is allowed",
                    };
                }

                if (hasLinkAction) {
                    return {
                        allow: false,
                        reason: "Cannot use a template with a link action. Include the link as part of your template.",
                    };
                }

                return {
                    allow: true,
                    reason: "",
                };
            case "signature":
                if (!hasActiveSubscription()) {
                    return {
                        allow: false,
                        reason: "Upgrade to Premium to use this feature",
                    };
                }
                
                if (!isFeatureEnabled('signatures')) {
                    return {
                        allow: false,
                        reason: "Signatures are disabled for your organization",
                    };
                }

                if (hasSignatureAction) {
                    return {
                        allow: false,
                        reason: "Only one signature action is allowed",
                    };
                }

                return {
                    allow: true,
                    reason: "",
                };
            case "link":
                if (hasTemplateAction) {
                    return {
                        allow: false,
                        reason: "Cannot use a link action with a template action. Include the link as part of your template",
                    };
                }

                return {
                    allow: true,
                    reason: "",
                };
            case 'attachment':
                if (!hasActiveSubscription()) {
                    return {
                        allow: false,
                        reason: 'Upgrade to Premium to use this feature',
                    };
                }

                return {
                    allow: true,
                    reason: "",
                };
            case "delay":
                if (hasDelayAction) {
                    return {
                        allow: false,
                        reason: "Only one delay action is allowed",
                    };
                }

                return {
                    allow: true,
                    reason: "",
                };
            case 'variables':
                if (!hasActiveSubscription()) {
                    return {
                        allow: false,
                        reason: 'Upgrade to Premium to use this feature',
                    };
                }

                return {
                    allow: true,
                    reason: "",
                };
            default:
                return {
                    allow: true,
                    reason: "",
                };
        }
    };

    const handleAttachmentSelected = async (file) => {
        if (!file || !file.data) {
            return;
        }

        updateAction(
            {
                ...action,
                attachments: [...action.attachments, file],
            },
            index
        );
    };

    const getGroupedAndSortedTemplates = () => {
        const { personal, personalShared, shared } = availableTemplates.reduce(
            (acc, template) => {
                if (!Array.isArray(template.sharedtemplates) || template.sharedtemplates.length === 0) {
                    acc.personal.push(template);
                } else {
                    if (template.user_id === user.id) {
                        acc.personalShared.push(template);
                    } else {
                        acc.shared.push(template);
                    }
                }
                return acc;
            },
            { personal: [], personalShared: [], shared: [] }
        );

        // Sort each list by name
        personal.sort((a, b) => a.name.localeCompare(b.name));
        personalShared.sort((a, b) => a.name.localeCompare(b.name));
        shared.sort((a, b) => a.name.localeCompare(b.name));

        return [ ...personal, ...personalShared, ...shared ];
    };

    const getGroupedAndSortedSignatures = () => {
        const { personal, personalShared, shared } = availableSignatures.reduce(
            (acc, signature) => {
                if (!Array.isArray(signature.sharedsignatures) || signature.sharedsignatures.length === 0) {
                    acc.personal.push(signature);
                } else {
                    if (signature.user_id === user.id) {
                        acc.personalShared.push(signature);
                    } else {
                        acc.shared.push(signature);
                    }
                }
                return acc;
            },
            { personal: [], personalShared: [], shared: [] }
        );

        // Sort each list by name
        personal.sort((a, b) => a.name.localeCompare(b.name));
        personalShared.sort((a, b) => a.name.localeCompare(b.name));
        shared.sort((a, b) => a.name.localeCompare(b.name));

        return [ ...personal, ...personalShared, ...shared ];
    };

    useEffect(() => {
        if (!action || action.type !== "delay" || action.method !== "delay") {
            return;
        }

        let delayMinutes = 0;
        switch (delayInterval) {
            case "minutes":
                delayMinutes = delayQuantity;
                break;
            case "hours":
                delayMinutes = delayQuantity * 60;
                break;
            case "days":
                delayMinutes = delayQuantity * 60 * 24;
                break;
            default:
                break;
        }

        updateAction({ ...action, delayMinutes }, index);
    }, [delayInterval, delayQuantity]);

    useEffect(() => {
        async function handleActionChange() {
            if (action.type === "attachment") {
                if (action.attachments.length === 0) {
                    setMissingAction(true);
                    return;
                }
            }

            if (action.type === "recipient") {
                if (action.emails.length === 0) {
                    setMissingAction(true);
                    return;
                }
            }

            if (action.type === "tag") {
                if (!action.tag) {
                    setMissingAction(true);
                    return;
                }
            }

            if (action.type === "link") {
                if (!action.text || !action.url) {
                    setMissingAction(true);
                    return;
                }
            }

            if (action.type === "template") {
                if (!action.templateId) {
                    setMissingAction(true);
                    setSelectedTemplate(null);
                    return;
                } else {
                    const template = availableTemplates.find(
                        (t) => t.id === action.templateId
                    );

                    if (!template) {
                        setMissingAction(true);
                        setSelectedTemplate(null);
                        return;
                    }

                    setSelectedTemplate(template);
                }
            }

            if (action.type === "signature") {
                if (!action.signatureId) {
                    setMissingAction(true);
                    setSelectedSignature(null);
                    return;
                } else {
                    const signature = availableSignatures.find(
                        (s) => s.id === action.signatureId
                    );

                    if (!signature) {
                        setMissingAction(true);
                        setSelectedSignature(null);
                        return;
                    }

                    setSelectedSignature(signature);
                }
            }

            if (action.type === "delay") {
                if (action.method === "delay" && (!action.delayMinutes || action.delayMinutes <= 0)) {
                    setMissingAction(true);
                    return;
                }
            }

            setMissingAction(false);
        }

        handleActionChange();
    }, [action, availableTemplates, availableSignatures]);

    useEffect(() => {
        if (!action) {
            return;
        }

        if (action.type === "delay") {
            if (action.method === "delay") {
                if (action.delayMinutes < 60) {
                    setDelayInterval("minutes");
                    setDelayQuantity(action.delayMinutes);
                } else if (action.delayMinutes < 1440) {
                    setDelayInterval("hours");
                    setDelayQuantity(action.delayMinutes / 60);
                } else {
                    setDelayInterval("days");
                    setDelayQuantity(action.delayMinutes / 1440);
                }
            }
        }
    }, []);

    if (!action) {
        return null;
    }

    const allowAttachmentAction = allowActionType("attachment");
    const allowTemplateAction = allowActionType("template");
    const allowSignatureAction = allowActionType("signature");
    const allowDelayAction = allowActionType("delay");
    const allowLinkAction = allowActionType("link");
    const allowVariableUse = allowActionType("variables");

    return (
        <div className={`action ${missingAction ? "error" : ""}`}>
            <IconButton
                className="delete-action-btn"
                onClick={() => updateAction(null, index)}
                aria-label="delete action"
                disabled={!canEdit}
            >
                <DeleteIcon />
            </IconButton>
            <Typography
                variant="subtitle2"
                className="action-field-label"
                gutterBottom
            >
                Action Type
            </Typography>
            <ToggleButtonGroup
                color="primary"
                className="toggle-group-input"
                value={action.type}
                size="small"
                exclusive
                disabled={!canEdit}
                onChange={handleTypeChange}
                aria-label="Action Type"
            >
                <Tooltip title="Add text to the beginning or end of the subject">
                    <span>
                        <ToggleButton
                            className="action-type-toggle-btn"
                            value="tag"
                        >
                            Subject Tag
                        </ToggleButton>
                    </span>
                </Tooltip>
                <Tooltip title="Add recipients to the email">
                    <span>
                        <ToggleButton
                            className="action-type-toggle-btn"
                            value="recipient"
                        >
                            Recipients
                        </ToggleButton>
                    </span>
                </Tooltip>
                <Tooltip
                    title={
                        allowLinkAction.reason ||
                        "Insert a link into the email body"
                    }
                >
                    <span>
                        <ToggleButton
                            className="action-type-toggle-btn"
                            value="link"
                            disabled={!allowLinkAction.allow}
                        >
                            Link
                        </ToggleButton>
                    </span>
                </Tooltip>
                <Tooltip
                    title={
                        allowDelayAction.reason ||
                        "Schedule the sending of the email"
                    }
                >
                    <span>
                        <ToggleButton
                            value="delay"
                            disabled={!allowDelayAction.allow}
                        >
                            Schedule Send
                        </ToggleButton>
                    </span>
                </Tooltip>
                <Tooltip
                    title={
                        allowTemplateAction.reason ||
                        "Insert a template into the email body"
                    }
                >
                    <span>
                        <ToggleButton
                            value="template"
                            disabled={!allowTemplateAction.allow}
                        >
                            Template
                        </ToggleButton>
                    </span>
                </Tooltip>
                <Tooltip
                    title={
                        allowAttachmentAction.reason ||
                        "Attach documents to the email"
                    }
                >
                    <span>
                        <ToggleButton
                            value="attachment"
                            disabled={!allowAttachmentAction.allow}
                        >
                            Attachments
                        </ToggleButton>
                    </span>
                </Tooltip>
                <Tooltip
                    title={
                        allowSignatureAction.reason ||
                        "Use a specific signature for the email"
                    }
                >
                    <span>
                        <ToggleButton
                            value="signature"
                            disabled={!allowSignatureAction.allow}
                        >
                            Signature
                        </ToggleButton>
                    </span>
                </Tooltip>
            </ToggleButtonGroup>

            {action.type === "tag" && (
                <>
                    <Typography
                        variant="subtitle2"
                        className="action-field-label"
                        gutterBottom
                    >
                        Tag Location
                    </Typography>
                    <ToggleButtonGroup
                        color="primary"
                        className="toggle-group-input mb-20"
                        value={action.location}
                        disabled={!canEdit}
                        size="small"
                        exclusive
                        onChange={(ev, newLocation) => {
                            if (newLocation) {
                                updateAction(
                                    { ...action, location: newLocation },
                                    index
                                );
                            }
                        }}
                        aria-label="Tag Location"
                    >
                        <ToggleButton
                            className="action-type-toggle-btn"
                            value="start"
                        >
                            Beginning of Subject
                        </ToggleButton>
                        <ToggleButton
                            className="action-type-toggle-btn"
                            value="end"
                        >
                            End of Subject
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {allowVariableUse.allow && (
                        <Alert severity="info" className="variable-info-alert">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                Use of variables is supported in the subject
                                tag. <VariableHelpPopper />
                            </Box>
                        </Alert>
                    )}
                    <TextField
                        required
                        fullWidth
                        className="text-input"
                        label="Subject Tag"
                        inputProps={{
                            maxLength: 64,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LocalOfferIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={action.tag}
                        disabled={!canEdit}
                        error={!action.tag}
                        helperText={
                            !action.tag ? "Subject tag text required" : ""
                        }
                        onChange={(ev) =>
                            updateAction(
                                { ...action, tag: ev.target.value },
                                index
                            )
                        }
                    />
                </>
            )}

            {action.type === "recipient" && (
                <>
                    <Typography
                        variant="subtitle2"
                        className="action-field-label"
                        gutterBottom
                    >
                        Recipient Type
                    </Typography>
                    <ToggleButtonGroup
                        color="primary"
                        className="toggle-group-input"
                        value={action.recipientType}
                        disabled={!canEdit}
                        size="small"
                        exclusive
                        onChange={(ev, newRecipientType) => {
                            if (newRecipientType) {
                                updateAction(
                                    {
                                        ...action,
                                        recipientType: newRecipientType,
                                    },
                                    index
                                );
                            }
                        }}
                        aria-label="Recipient Type"
                    >
                        <ToggleButton
                            className="action-type-toggle-btn"
                            value="to"
                        >
                            To
                        </ToggleButton>
                        <ToggleButton
                            className="action-type-toggle-btn"
                            value="cc"
                        >
                            Cc
                        </ToggleButton>
                        <ToggleButton
                            className="action-type-toggle-btn"
                            value="bcc"
                        >
                            Bcc
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Typography
                        variant="subtitle2"
                        className="action-field-label"
                        gutterBottom
                    >
                        Recipient emails
                    </Typography>
                    {action.emails.map((email, emailIdx) => (
                        <div
                            className={`action-input-container ${
                                !email || !VALIDATION_REGEXES.email.test(email)
                                    ? "error"
                                    : ""
                            }`}
                            key={`email-${emailIdx}`}
                        >
                            <TextField
                                required
                                fullWidth
                                className="action-input"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{
                                    maxLength: 100,
                                }}
                                key={`email-input-${emailIdx}`}
                                value={email}
                                disabled={!canEdit}
                                error={
                                    !email ||
                                    !VALIDATION_REGEXES.email.test(email)
                                }
                                helperText={
                                    !email ||
                                    !VALIDATION_REGEXES.email.test(email)
                                        ? "Valid email address required"
                                        : ""
                                }
                                onChange={(ev) => {
                                    const updatedEmails = [...action.emails];
                                    updatedEmails[emailIdx] = ev.target.value;
                                    updateAction(
                                        { ...action, emails: updatedEmails },
                                        index
                                    );
                                }}
                            />
                            <IconButton
                                className="delete-action-input-btn"
                                disabled={!canEdit}
                                onClick={() => {
                                    const updatedEmails = [...action.emails];
                                    updatedEmails.splice(emailIdx, 1);
                                    updateAction(
                                        { ...action, emails: updatedEmails },
                                        index
                                    );
                                }}
                                aria-label="delete email"
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    ))}
                    <Button
                        className="add-email"
                        disabled={!canEdit}
                        onClick={() =>
                            updateAction(
                                { ...action, emails: [...action.emails, ""] },
                                index
                            )
                        }
                    >
                        Add email
                    </Button>
                </>
            )}

            {action.type === "attachment" && (
                <>
                    <Typography
                        variant="subtitle2"
                        className="action-field-label"
                        gutterBottom
                    >
                        Attachment Details
                    </Typography>

                    <AttachmentInput
                        canEdit={canEdit}
                        onChange={handleAttachmentSelected}
                        maxFileSize={157286400}
                    />

                    {action.attachments.map((attachment, attachmentIdx) => (
                        <div
                            className={`attachment-details action-input-container ${
                                !attachment.name ||
                                !VALIDATION_REGEXES.filename.test(
                                    attachment.name
                                )
                                    ? "error"
                                    : ""
                            }`}
                            key={`attachment-details-${attachmentIdx}`}
                        >
                            <div className="action-input">
                                <TextField
                                    required
                                    fullWidth
                                    className="attachment-input"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AbcIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        maxLength: 128,
                                    }}
                                    value={attachment.name}
                                    disabled={!canEdit}
                                    error={
                                        !attachment.name ||
                                        !VALIDATION_REGEXES.filename.test(
                                            attachment.name
                                        )
                                    }
                                    helperText={
                                        !attachment.name ||
                                        !VALIDATION_REGEXES.filename.test(
                                            attachment.name
                                        )
                                            ? "Valid file name required"
                                            : ""
                                    }
                                    onChange={(ev) => {
                                        const updatedAttachments = [
                                            ...action.attachments,
                                        ];
                                        updatedAttachments[attachmentIdx].name =
                                            ev.target.value;
                                        updateAction(
                                            {
                                                ...action,
                                                attachments: updatedAttachments,
                                            },
                                            index
                                        );
                                    }}
                                />
                            </div>
                            <IconButton
                                className="delete-action-input-btn attachment"
                                disabled={!canEdit}
                                onClick={() => {
                                    const updatedAttachments = [
                                        ...action.attachments,
                                    ];
                                    updatedAttachments.splice(attachmentIdx, 1);
                                    updateAction(
                                        {
                                            ...action,
                                            attachments: updatedAttachments,
                                        },
                                        index
                                    );
                                }}
                                aria-label="delete attachment"
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    ))}
                </>
            )}

            {action.type === "link" && (
                <>
                    <TextField
                        required
                        fullWidth
                        className="text-input"
                        label="Link Text"
                        inputProps={{
                            maxLength: 500,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AbcIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={action.text}
                        disabled={!canEdit}
                        error={!action.text}
                        helperText={!action.text ? "Link text required" : ""}
                        onChange={(ev) =>
                            updateAction(
                                { ...action, text: ev.target.value },
                                index
                            )
                        }
                    />
                    <TextField
                        required
                        fullWidth
                        className="text-input"
                        label="Link URL"
                        inputProps={{
                            maxLength: 2083,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LinkIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={action.url}
                        disabled={!canEdit}
                        error={
                            !action.url ||
                            !VALIDATION_REGEXES.url.test(action.url)
                        }
                        helperText={
                            !action.url ||
                            !VALIDATION_REGEXES.url.test(action.url)
                                ? "Valid link url required (including http(s)://)"
                                : ""
                        }
                        onChange={(ev) =>
                            updateAction(
                                { ...action, url: ev.target.value },
                                index
                            )
                        }
                    />
                </>
            )}

            {action.type === "template" && (
                <>
                    {(!availableTemplates ||
                        availableTemplates.length === 0) && (
                        <Alert severity="info" className="variable-info-alert">
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                No templates available. Create a template using
                                the "Manage Templates" page.
                            </Box>
                        </Alert>
                    )}

                    {action.templateId && missingAction && (
                        <Alert severity="error" className="variable-info-alert">
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                The template for this action is no longer available or not enabled. Please select a new template or delete this action.
                            </Box>
                        </Alert>
                    )}

                    <Autocomplete
                        fullWidth
                        options={getGroupedAndSortedTemplates()}
                        getOptionLabel={(option) =>
                            option ? option.name : "Unknown"
                        }
                        renderInput={(params) => (
                            <TextField {...params} label="Select template..." />
                        )}
                        groupBy={(option) =>
                            option.sharedtemplates?.length > 0
                                ? option.user_id === user.id
                                    ? "Personal Shared Templates"
                                    : "Shared Templates"
                                : "Personal Templates"
                        }
                        value={selectedTemplate}
                        disabled={!canEdit}
                        onChange={(event, newValue) => {
                            updateAction(
                                { ...action, templateId: newValue?.id },
                                index
                            );
                            setSelectedTemplate(newValue);
                            setTemplateSearchInputValue(
                                newValue ? newValue.name : ""
                            );
                        }}
                        inputValue={templateSearchInputValue}
                        onInputChange={(event, newInputValue) => {
                            setTemplateSearchInputValue(newInputValue);
                        }}
                    />
                </>
            )}

            {action.type === "signature" && (
                <>
                    {(!availableSignatures ||
                        availableSignatures.length === 0) && (
                        <Alert severity="info" className="variable-info-alert">
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                No signatures available. Create a signature
                                using the "Manage Signatures" page.
                            </Box>
                        </Alert>
                    )}

                    {action.signatureId && missingAction && (
                        <Alert severity="error" className="variable-info-alert">
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                The signature for this action is no longer available or not enabled. Please select a new signature or delete this action.
                            </Box>
                        </Alert>
                    )}

                    <Autocomplete
                        fullWidth
                        options={getGroupedAndSortedSignatures()}
                        getOptionLabel={(option) =>
                            option ? option.name : "Unknown"
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select signature to use..."
                            />
                        )}
                        groupBy={(option) =>
                            option.sharedsignatures?.length > 0
                                ? option.user_id === user.id
                                    ? "Personal Shared Signatures"
                                    : "Shared Signatures"
                                : "Personal Signatures"
                        }
                        value={selectedSignature}
                        disabled={!canEdit}
                        onChange={(event, newValue) => {
                            updateAction(
                                { ...action, signatureId: newValue?.id },
                                index
                            );
                            setSelectedSignature(newValue);
                            setSignatureSearchInputValue(
                                newValue ? newValue.name : ""
                            );
                        }}
                        inputValue={signatureSearchInputValue}
                        onInputChange={(event, newInputValue) => {
                            setSignatureSearchInputValue(newInputValue);
                        }}
                    />
                </>
            )}

            {action.type === "delay" && (
                <>
                    <ToggleButtonGroup
                        color="primary"
                        className="toggle-group-input mb-20"
                        value={action.method}
                        disabled={!canEdit}
                        size="small"
                        exclusive
                        onChange={(ev, newMethod) => {
                            if (newMethod) {
                                const updatedAction = action;
                                if (newMethod === "schedule") {
                                    delete updatedAction.delayMinutes;
                                }

                                updateAction(
                                    { ...updatedAction, method: newMethod },
                                    index
                                );
                            }
                        }}
                        aria-label="Delay Type"
                    >
                        <Tooltip title="Schedule sending at specific day/time">
                            <span>
                                <ToggleButton
                                    className="action-type-toggle-btn"
                                    value="schedule"
                                >
                                    Schedule
                                </ToggleButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Delay sending by some defined time">
                            <span>
                                <ToggleButton
                                    className="action-type-toggle-btn"
                                    value="delay"
                                >
                                    Delay
                                </ToggleButton>
                            </span>
                        </Tooltip>
                    </ToggleButtonGroup>

                    {action.method === "schedule" && (
                        <>
                            <Alert
                                severity="info"
                                className="variable-info-alert"
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    A prompt to select a specific date and time
                                    to send will be shown when rule is toggled
                                    on.
                                </Box>
                            </Alert>
                        </>
                    )}

                    {action.method === "delay" && (
                        <>
                            <Alert
                                severity="info"
                                className="variable-info-alert"
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Sending of the email will be delayed by this
                                    amount of time after the rule is triggered.
                                </Box>
                            </Alert>
                            <TextField
                                required
                                className="delay-num-input"
                                label="Delay #"
                                type="number"
                                inputProps={{
                                    min: 1,
                                }}
                                value={delayQuantity}
                                disabled={!canEdit}
                                error={delayQuantity <= 0}
                                helperText={
                                    delayQuantity <= 0
                                        ? `Delay must be greater than 0 ${delayInterval}`
                                        : ""
                                }
                                onChange={(ev) =>
                                    setDelayQuantity(ev.target.value)
                                }
                            />
                            <FormControl>
                                <InputLabel id="delay-input-select-label">
                                    Interval
                                </InputLabel>
                                <Select
                                    labelId="delay-input-select-label"
                                    id="delay-input-select"
                                    value={delayInterval}
                                    disabled={!canEdit}
                                    label="Interval"
                                    onChange={(ev) =>
                                        setDelayInterval(ev.target.value)
                                    }
                                >
                                    <MenuItem value="minutes">Minutes</MenuItem>
                                    <MenuItem value="hours">Hours</MenuItem>
                                    <MenuItem value="days">Days</MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default EditAction;

// @ts-nocheck
import { Navigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useEffect, useState } from "react";

// This component is used to protect routes that require a user to have an active subscription.
export const SubscribedRoute = ({ children }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const { authUser, subscription, getOrganization, getAdminUser, getSubscription, datastore, logout } = useAuth();

    // useEffect(() => {
    //     async function loadData() {
    //         try {
    //             const { error: orgError } = await getOrganization();
    //             const { error: adminUserError } = await getAdminUser();
    //             const { error: subError } = await getSubscription();

    //             // Just log errors
    //             if (orgError) {
    //                 console.log('Error loading organization data: ', orgError);
    //             }

    //             if (adminUserError) {
    //                 console.log('Error loading admin user data: ', adminUserError);
    //             }

    //             if (subError) {
    //                 throw new Error('Failed to load user subscription');
    //             }
    //         } catch (err) {
    //             console.error(err);
    //             logout();
    //             return <Navigate to="/login" replace />;
    //         }
            
    //         setIsLoaded(true);
    //     }

    //     if (!isLoaded) {
    //         loadData();
    //     }
    // }, []);

    // if (!isLoaded) {
    //     return null;
    // }

    if (!authUser) {
        logout();
        return <Navigate to="/login" replace />;
    }

    if (!subscription) {
        console.log(">>> Subscribed User Route: No subscription found");
        return;
    }

    if (subscription.status === "canceled") {
        console.log(">>> Subscribed User Route: Subscription is canceled");

        if (!subscription.organization_id) {
            return location.pathname !== "/subscription" ? <Navigate to="/subscription" replace /> : children;
        }

        // TODO: Display a message to user that their organizations subscription is not active
        console.log(">>> Subscribed User Route: Organization subscription is not active")
        return;
    }

    if (subscription.status === "paused") {
        console.log(">>> Subscribed User Route: Subscription is paused");

        if (!subscription.organization_id) {
            return location.pathname !== "/account" ? <Navigate to="/account" replace /> : children;
        }
        
        // TODO: Display a message to user that their organizations subscription is paused
        console.log(">>> Subscribed User Route: Organization subscription is paused")
        return;
    }

    if (!datastore || !datastore.api || datastore.api.type === "public") {
        // Waiting to load completely
        return null;
    }

    return children;
};

// @ts-nocheck
import React, { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";

import "./styles.scss";

dayjs.extend(relativeTime);

const SubscriptionEndsBanner = memo(({ endDate, status }) => {
    if (!endDate || status === "active") {
        return null;
    }

    if (status === "canceled") {
        return (
            <Alert severity="info">
                Your subscription was canceled. Please re-subscribe if you wish to continue use of Toggles.{" "}
                <Link
                    color="primary"
                    variant="body2"
                    underline="none"
                    component={RouterLink}
                    to="/subscription"
                >
                    Subscribe
                </Link>
            </Alert>
        );
    }

    if (status === "paused") {
        return (
            <Alert severity="info">
                Your subscription is paused. Please use the "Manage
                Subscription" button on your{" "}
                <Link
                    color="primary"
                    variant="body2"
                    underline="none"
                    component={RouterLink}
                    to="/account"
                >
                    Account page
                </Link>{" "}
                to update your subscription.
            </Alert>
        );
    }

    const endDateString = dayjs(endDate).fromNow();
    return (
        <Alert severity="warning" className="subscription-end-banner">
            Your trial ends in {endDateString}. Ensure you have added a payment
            method using the "Manage Subscription" button on your{" "}
            <Link
                color="primary"
                variant="body2"
                underline="none"
                component={RouterLink}
                to="/account"
            >
                Account page
            </Link>{" "}
            to continue using Business Pro features.
        </Alert>
    );
});

export default SubscriptionEndsBanner;

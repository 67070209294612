// @ts-nocheck
import { getSupabaseClient } from "../hooks/useSupabase";
import { SB_FUNCTIONS } from "../helpers/constants";

// Create a new class to handle all data store operations
export default class UserDataStore {
    public type: string;
    private supabase: any;

    constructor(user) {
        this.supabase = getSupabaseClient();
        this.user = user;
        this.type = "user";
    }

    // Org Domain Check -- DELETE
    public async checkDomain(domain) {
        const { data: domainCheck, error: domainCheckError } =
            await this.supabase.functions.invoke(
                `${SB_FUNCTIONS.ORG_DOMAIN_CHECK}${domain}`,
                {
                    method: "GET",
                }
            );

        if (domainCheckError || !domainCheck) {
            console.error("Error checking domain: ", domainCheckError);
            throw new Error("Error checking domain");
        }

        return domainCheck;
    }

    // Accounts (user)
    public async updateAccount(updatedUser) {
        if (!updatedUser || !updatedUser.id) {
            throw new Error("Error updating account - no user provided");
        }

        const { data, error } = await this.supabase
            .from("users")
            .update(updatedUser)
            .eq("id", updatedUser.id)
            .select();

        if (error) {
            console.log("Error updating account: ", error);
            throw new Error("Error updating account");
        }

        return data[0];
    }

    // Organizations
    async getOrganization() {}

    // Subscriptions
    public async createSubscriptionWithPayment(subscription) {
        const { data, error: funcError } = await this.supabase.functions.invoke(
            SB_FUNCTIONS.CREATE_SUBSCRIPTION,
            {
                body: subscription,
            }
        );

        if (funcError) {
            console.log(
                "Error creating subscription with payment: ",
                funcError
            );
            throw new Error("Error creating subscription with payment");
        }

        return data;
    }

    public async createSubscriptionWithTrial(subscription) {
        const { data, error: funcError } = await this.supabase.functions.invoke(
            SB_FUNCTIONS.CREATE_TRIAL_SUBSCRIPTION,
            {
                body: subscription,
            }
        );

        if (funcError) {
            console.log("Error creating subscription with trial: ", funcError);
            throw new Error("Error creating subscription with trial");
        }

        return data;
    }

    // Admin Users
    async getAdminUser() {}

    // Users
    public async getUsers(query) {
        throw new Error("NOT IMPLEMENTED");
    }

    // Groups
    public async getGroups(query) {
        let selectFields = "*";

        if (query?.select) {
            selectFields = query?.select;
        }

        const { data, count, error } = await this.supabase
            .from("groups")
            .select(selectFields, { count: "exact" });

        if (error) {
            console.log("Error fetching groups from API: ", error);
            throw new Error("Error fetching groups from API");
        }

        return { groups: data, count };
    }

    // Rules
    public async getPersonalRules(query) {
        let selectFields =
            "*, sharedrules(id, rule_id, organization_id, editable, users(id, email), groups(id, name))";

        if (query?.select) {
            selectFields = query?.select;
        }

        const queryBuilder = this.supabase
            .from("rules")
            .select(selectFields, { count: "exact" })
            .eq("user_id", this.user.id)
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log("Error fetching rules from API: ", error);
            throw new Error("Error fetching rules from API");
        }

        return { rules: data, count };
    }

    public async getGroupRules(query) {
        const queryBuilder = this.supabase
            .from("rules")
            .select(
                "*, sharedrules!inner(id, rule_id, organization_id, editable, users(id, email), groups(id, name))",
                { count: "exact" }
            )
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .not("sharedrules.group_id", "is", null)
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log("Error fetching group rules from API: ", error);
            throw new Error("Error fetching group rules from API");
        }

        return { rules: data, count };
    }

    public async getOrganizationRules(query) {
        const queryBuilder = this.supabase
            .from("rules")
            .select(
                "*, users(email), sharedrules!inner(id, rule_id, organization_id, editable, users(id, email), groups(id, name))",
                { count: "exact" }
            )
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .not("sharedrules.organization_id", "is", null)
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log("Error fetching organization rules from API: ", error);
            throw new Error("Error fetching organization rules from API");
        }

        return { rules: data, count };
    }

    public async getOrgUnsharedRules(query) {
        throw new Error("NOT IMPLEMENTED");
    }

    async createRule(rule) {
        if (!rule) {
            throw new Error("Error creating new rule - no rule provided");
        }

        let newRule = { ...rule, public: false, user_id: this.user.id };

        const ruleShared = newRule.sharedrules;
        delete newRule.sharedrules;

        // Get shared templates and signatures to create
        const { sharedTemplatesToCreate, sharedSignaturesToCreate } =
            this.prepareToShareTempaltesAndSigs(newRule, ruleShared);
        try {
            await Promise.all([
                this.createManySharedTemplates(sharedTemplatesToCreate),
                this.createManySharedSignatures(sharedSignaturesToCreate),
            ]);
        } catch (err) {
            console.log(
                "Error sharing action templates and/or signatures for rule: ",
                err
            );
            throw new Error("Error saving new rule");
        }

        const { data, error } = await this.supabase
            .from("rules")
            .insert(newRule)
            .select();

        if (error) {
            console.log("Error saving new rule: ", error);
            throw new Error("Error saving new rule");
        }

        const createdRule = data[0];
        const sharedData = ruleShared.map((r) => ({
            ...r,
            rule_id: createdRule.id,
        }));

        try {
            const sharedRuleData = await this.createManySharedRules(sharedData);
            createdRule.sharedrules = sharedRuleData;
        } catch (err) {
            console.log("Error saving new shared rule: ", err);
            createdRule.sharedrules = [];
        }

        return createdRule;
    }

    async updateRule(updatedRule) {
        if (!updatedRule || !updatedRule.id) {
            throw new Error("Error updating rule - no rule provided");
        }

        let changedRule = { ...updatedRule };

        const addedSharedRules = changedRule.added_sharedrules;
        const removedSharedRules = changedRule.removed_sharedrules;
        delete changedRule.added_sharedrules;
        delete changedRule.removed_sharedrules;
        delete changedRule.sharedrules;

        // Get shared templates and signatures to create
        const { sharedTemplatesToCreate, sharedSignaturesToCreate } =
            this.prepareToShareTempaltesAndSigs(
                changedRule,
                updatedRule.sharedrules
            );
        try {
            await Promise.all([
                this.createManySharedTemplates(sharedTemplatesToCreate),
                this.createManySharedSignatures(sharedSignaturesToCreate),
            ]);
        } catch (err) {
            console.log(
                "Error sharing action templates and/or signatures for rule: ",
                err
            );
            throw new Error("Error saving new rule");
        }

        const { error } = await this.supabase
            .from("rules")
            .update(changedRule)
            .eq("id", updatedRule.id);

        if (error) {
            console.log("Error updating rule: ", error);
            throw new Error("Error updating rule");
        }

        try {
            const newSharedRules = await this.createManySharedRules(
                addedSharedRules
            );
            await this.deleteManySharedRules(removedSharedRules);
            changedRule.sharedrules = [
                ...updatedRule.sharedrules.filter((sr) => !!sr.id),
                ...newSharedRules,
            ];
        } catch (err) {
            console.log(
                "Error saving new shared rules for existing rule: ",
                err
            );
            changedRule.sharedrules = updatedRule.sharedrules;
        }

        return changedRule;
    }

    async deleteRule(ruleId) {
        if (!ruleId) {
            throw new Error("Error deleting rule - no rule provided");
        }

        const { error } = await this.supabase
            .from("rules")
            .delete()
            .eq("id", ruleId);

        if (error) {
            console.log("Error deleting rule: ", error);
            throw new Error("Error deleting rule");
        }
    }

    // Shared Rules
    private prepareToShareTempaltesAndSigs(rule, sharedrules) {
        // If this is an update, we need to remove any existing shared items
        let newSharedGroupIds = sharedrules
            .filter((sr) => !!sr.group_id || !!sr.groups)
            .map((sr) => (sr.group_id ? sr.groups_id : sr.groups.id));

        let { templateIds, signatureIds } = rule.metadata.actions.reduce(
            (acc, action) => {
                if (action.type === "template") {
                    acc.templateIds.push(action.templateId);
                } else if (action.type === "signature") {
                    acc.signatureIds.push(action.signatureId);
                }
                return acc;
            },
            { templateIds: [], signatureIds: [] }
        );

        const sharedTemplatesToCreate = templateIds.reduce(
            (acc, templateId) => {
                newSharedGroupIds.forEach((groupId) => {
                    if (groupId) {
                        acc.push({
                            group_id: groupId,
                            template_id: templateId,
                            user_id: null,
                            organization_id: null,
                        });
                    }
                });

                return acc;
            },
            []
        );

        const sharedSignaturesToCreate = signatureIds.reduce(
            (acc, signatureId) => {
                newSharedGroupIds.forEach((groupId) => {
                    if (groupId) {
                        acc.push({
                            group_id: groupId,
                            signature_id: signatureId,
                            user_id: null,
                            organization_id: null,
                        });
                    }
                });

                return acc;
            },
            []
        );

        return {
            sharedTemplatesToCreate,
            sharedSignaturesToCreate,
        };
    }

    async createManySharedRules(sharedRules) {
        if (!sharedRules) {
            throw new Error("Error creating new shared rules (undefined)");
        }

        if (sharedRules.length === 0) {
            return [];
        }

        const { data, error } = await this.supabase
            .from("sharedrules")
            .upsert(sharedRules, {
                onConflict: "rule_id, user_id, group_id, organization_id",
                ignoreDuplicates: false,
            })
            .select(
                "id, organization_id, editable, users(id, email), groups(id, name)"
            );

        if (error) {
            console.log("Error saving new shared rules: ", error);
            throw new Error("Error saving new shared rules");
        }

        return data;
    }

    async deleteManySharedRules(sharedRules) {
        if (!sharedRules) {
            throw new Error("Error creating new shared rules (undefined)");
        }

        if (sharedRules.length === 0) {
            return [];
        }

        const { error } = await this.supabase
            .from("sharedrules")
            .delete()
            .in(
                "id",
                sharedRules.map((sr) => sr.id)
            );

        if (error) {
            console.log("Error deleting shared rules: ", error);
            throw new Error("Error deleting shared rules");
        }
    }

    public async deleteSharedRule(srId) {
        if (!srId) {
            throw new Error(
                "Error deleting shared rule - no shared rule provided"
            );
        }

        const { error } = await this.supabase
            .from("sharedrules")
            .delete()
            .eq("id", srId);

        if (error) {
            console.log("Error deleting shared rule: ", error);
            throw new Error("Error deleting shared rule");
        }
    }

    // Templates
    public async getPersonalTemplates(query) {
        let selectFields =
            "*, sharedtemplates(id, template_id, user_id, editable, users(id, email), groups(id, name))";

        if (query?.select) {
            selectFields = query?.select;
        }

        const queryBuilder = this.supabase
            .from("templates")
            .select(selectFields, { count: "exact" })
            .eq("user_id", this.user.id)
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log("Error fetching templates from API: ", error);
            throw new Error("Error fetching templates from API");
        }

        return { templates: data, count };
    }

    public async getGroupTemplates(query) {
        const queryBuilder = this.supabase
            .from("templates")
            .select(
                "*, sharedtemplates!inner(id, template_id, user_id, organization_id, editable, users(id, email), groups(id, name))",
                { count: "exact" }
            )
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .not("sharedtemplates.group_id", "is", null)
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log("Error fetching group templates from API: ", error);
            throw new Error("Error fetching group templates from API");
        }

        return { templates: data, count };
    }

    public async getOrganizationTemplates(query) {
        const queryBuilder = this.supabase
            .from("templates")
            .select(
                "*, sharedtemplates!inner(id, template_id, organization_id, editable, users(id, email), groups(id, name))",
                { count: "exact" }
            )
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .not("sharedtemplates.organization_id", "is", null)
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log(
                "Error fetching organization templates from API: ",
                error
            );
            throw new Error("Error fetching organization templates from API");
        }

        return { templates: data, count };
    }

    public async getOrgUnsharedTemplates(query) {
        throw new Error("NOT IMPLEMENTED");
    }

    public async getAllSharedTemplates(query) {
        let selectFields =
            "id, editable, templates(*), organizations(id, full_name), groups(id, name)";

        if (query?.select) {
            selectFields = query?.select;
        }

        const queryBuilder = this.supabase
            .from("sharedtemplates")
            .select(selectFields, { count: "exact" })
            .not("templates", "is", null)
            .ilike("templates.name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log(
                "Error fetching all shared templates from API: ",
                error
            );
            return { templates: [], count: 0 };
        }

        // Reformat data
        const groupedItems = this.groupItemsByTemplates(data);

        return { templates: groupedItems, count };
    }

    private groupItemsByTemplates(items) {
        const groupedItems = {};

        items.forEach((item) => {
            const template = item.templates;
            const { id, editable, groups, organizations } = item; // Destructuring for clarity

            if (!groupedItems[template.id]) {
                groupedItems[template.id] = {
                    ...template,
                    sharedtemplates: [],
                };
            }

            groupedItems[template.id].sharedtemplates.push({
                id,
                editable,
                groups,
                organization_id: organizations?.id,
            });
        });

        return Object.values(groupedItems);
    }

    async createTemplate(template) {
        if (!template) {
            throw new Error(
                "Error creating new template - no template provided"
            );
        }

        let newTemplate = { ...template, public: false, user_id: this.user.id };

        const templateShared = newTemplate.sharedtemplates;
        delete newTemplate.sharedtemplates;

        const { data, error } = await this.supabase
            .from("templates")
            .insert(newTemplate)
            .select();

        if (error) {
            console.log("Error saving new template: ", error);
            throw new Error("Error saving new template");
        }

        const createdTemplate = data[0];
        const sharedData = templateShared.map((t) => ({
            ...t,
            template_id: createdTemplate.id,
        }));

        try {
            const sharedTemplateData = await this.createManySharedTemplates(
                sharedData
            );
            createdTemplate.sharedtemplates = sharedTemplateData;
        } catch (err) {
            console.log("Error saving new shared template: ", err);
            createdTemplate.sharedtemplates = [];
        }

        return createdTemplate;
    }

    async updateTemplate(updatedTemplate) {
        if (!updatedTemplate || !updatedTemplate.id) {
            throw new Error("Error updating template - no template provided");
        }

        let changedTemplate = { ...updatedTemplate };

        const addedSharedTemplates = changedTemplate.added_sharedtemplates;
        const removedSharedTemplates = changedTemplate.removed_sharedtemplates;
        delete changedTemplate.added_sharedtemplates;
        delete changedTemplate.removed_sharedtemplates;
        delete changedTemplate.sharedtemplates;

        const { error } = await this.supabase
            .from("templates")
            .update(changedTemplate)
            .eq("id", updatedTemplate.id);

        if (error) {
            console.log("Error updating template: ", error);
            throw new Error("Error updating template");
        }

        try {
            const newSharedTemplates = await this.createManySharedTemplates(
                addedSharedTemplates
            );
            await this.deleteManySharedTemplates(removedSharedTemplates);
            changedTemplate.sharedtemplates = [
                ...updatedTemplate.sharedtemplates.filter((st) => !!st.id),
                ...newSharedTemplates,
            ];
        } catch (err) {
            console.log(
                "Error saving new shared templates for existing template: ",
                err
            );
            changedTemplate.sharedtemplates = updatedTemplate.sharedtemplates;
        }

        return changedTemplate;
    }

    async deleteTemplate(templateId) {
        if (!templateId) {
            throw new Error("Error deleting template - no template provided");
        }

        const { error } = await this.supabase
            .from("templates")
            .delete()
            .eq("id", templateId);

        if (error) {
            console.log("Error deleting template: ", error);
            throw new Error("Error deleting template");
        }
    }

    // Shared Templates
    async createManySharedTemplates(sharedTemplates) {
        if (!sharedTemplates) {
            throw new Error(
                "Error creating new shared sharedTemplates (undefined)"
            );
        }

        if (sharedTemplates.length === 0) {
            return [];
        }

        // First check if shared with org - don't create shares with group if exists
        const { data: existingOrgShare, error: checkOrgShareExistsError } =
            await this.supabase
                .from("sharedtemplates")
                .select(
                    "id, template_id, organization_id, editable, users(id, email), groups(id, name)"
                )
                .not("organization_id", "is", null)
                .is("group_id", null)
                .eq("template_id", sharedTemplates[0].template_id)
                .maybeSingle();

        if (checkOrgShareExistsError) throw checkOrgShareExistsError;

        if (existingOrgShare) {
            return existingOrgShare;
        }

        const { data, error } = await this.supabase
            .from("sharedtemplates")
            .upsert(sharedTemplates, {
                onConflict: "template_id, user_id, group_id, organization_id",
                ignoreDuplicates: true,
            })
            .select(
                "id, template_id, organization_id, editable, users(id, email), groups(id, name)"
            );

        if (error) {
            console.log("Error saving new shared templates: ", error);
            throw new Error("Error saving new shared templates");
        }

        return data;
    }

    async deleteManySharedTemplates(sharedTemplates) {
        if (!sharedTemplates) {
            throw new Error("Error creating new shared templates (undefined)");
        }

        if (sharedTemplates.length === 0) {
            return [];
        }

        const { error } = await this.supabase
            .from("sharedtemplates")
            .delete()
            .in(
                "id",
                sharedTemplates.map((sr) => sr.id)
            );

        if (error) {
            console.log("Error deleting shared templates: ", error);
            throw new Error("Error deleting shared templates");
        }
    }

    async deleteSharedTemplate(stId) {
        if (!stId) {
            throw new Error(
                "Error deleting shared template - no shared template provided"
            );
        }

        const { error } = await this.supabase
            .from("sharedtemplates")
            .delete()
            .eq("id", stId);

        if (error) {
            console.log("Error deleting shared template: ", error);
            throw new Error("Error deleting shared template");
        }
    }

    // Signatures
    public async getPersonalSignatures(query) {
        let selectFields =
            "*, sharedsignatures(id, signature_id, organization_id, editable, users(id, email), groups(id, name))";

        if (query?.select) {
            selectFields = query?.select;
        }

        const queryBuilder = this.supabase
            .from("signatures")
            .select(selectFields, { count: "exact" })
            .eq("user_id", this.user.id)
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log("Error fetching signatures from API: ", error);
            throw new Error("Error fetching signatures from API");
        }

        return { signatures: data, count };
    }

    public async getGroupSignatures(query) {
        const queryBuilder = this.supabase
            .from("signatures")
            .select(
                "*, sharedsignatures!inner(id, signature_id, organization_id, editable, users(id, email), groups(id, name))",
                { count: "exact" }
            )
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .not("sharedsignatures.group_id", "is", null)
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log("Error fetching group signatures from API: ", error);
            throw new Error("Error fetching group signatures from API");
        }

        return { signatures: data, count };
    }

    public async getOrganizationSignatures(query) {
        const queryBuilder = this.supabase
            .from("signatures")
            .select(
                "*, sharedsignatures!inner(id, signature_id, organization_id, editable, users(id, email), groups(id, name))",
                { count: "exact" }
            )
            .ilike("name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .not("sharedsignatures.organization_id", "is", null)
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log(
                "Error fetching organization signatures from API: ",
                error
            );
            throw new Error("Error fetching organization signatures from API");
        }

        return { signatures: data, count };
    }

    public async getOrgUnsharedSignatures(query) {
        throw new Error("NOT IMPLEMENTED");
    }

    public async getAllSharedSignatures(query) {
        let selectFields =
            "id, editable, signatures(*), organizations(id, full_name), groups(id, name)";

        if (query?.select) {
            selectFields = query?.select;
        }

        const queryBuilder = this.supabase
            .from("sharedsignatures")
            .select(selectFields, { count: "exact" })
            .not("signatures", "is", null)
            .ilike("signatures.name", `%${query?.q || ""}%`)
            .order("updated_at", { ascending: false })
            .range(query?.from || 0, query?.to || 25);

        // Apply `enabled` filter only if explicitly defined
        if (query?.enabled !== undefined) {
            queryBuilder.eq("signatures.enabled", query.enabled);
        }

        const { data, count, error } = await queryBuilder;

        if (error) {
            console.log(
                "Error fetching all shared signatures from API: ",
                error
            );
            return { signatures: [], count: 0 };
        }

        // Reformat data
        const groupedItems = this.groupItemsBySignatures(data);

        return { signatures: groupedItems, count };
    }

    private groupItemsBySignatures(items) {
        const groupedItems = {};

        items.forEach((item) => {
            const signature = item.signatures;
            const { id, editable, groups, organizations } = item; // Destructuring for clarity

            if (!groupedItems[signature.id]) {
                groupedItems[signature.id] = {
                    ...signature,
                    sharedsignatures: [],
                };
            }

            groupedItems[signature.id].sharedsignatures.push({
                id,
                editable,
                groups,
                organization_id: organizations?.id,
            });
        });

        return Object.values(groupedItems);
    }

    async createSignature(signature) {
        if (!signature) {
            throw new Error(
                "Error creating new signature - no signature provided"
            );
        }

        let newSignature = {
            ...signature,
            public: false,
            user_id: this.user.id,
        };

        const signatureShared = newSignature.sharedsignatures;
        delete newSignature.sharedsignatures;

        const { data, error } = await this.supabase
            .from("signatures")
            .insert(newSignature)
            .select();

        if (error) {
            console.log("Error saving new signature: ", error);
            throw new Error("Error saving new signature");
        }

        const createdSignature = data[0];
        const sharedData = signatureShared.map((s) => ({
            ...s,
            signature_id: createdSignature.id,
        }));

        try {
            const sharedSignaturesData = await this.createManySharedSignatures(
                sharedData
            );
            createdSignature.sharedsignatures = sharedSignaturesData;
        } catch (err) {
            console.log("Error saving new shared signature: ", err);
            createdSignature.sharedsignatures = [];
        }

        return createdSignature;
    }

    async updateSignature(updatedSignature) {
        if (!updatedSignature || !updatedSignature.id) {
            throw new Error("Error updating signature - no signature provided");
        }

        let changedSignature = { ...updatedSignature };

        const addedSharedSignatures = changedSignature.added_sharedsignatures;
        const removedSharedSignatures =
            changedSignature.removed_sharedsignatures;
        delete changedSignature.added_sharedsignatures;
        delete changedSignature.removed_sharedsignatures;
        delete changedSignature.sharedsignatures;

        const { error } = await this.supabase
            .from("signatures")
            .update(changedSignature)
            .eq("id", updatedSignature.id);

        if (error) {
            console.log("Error updating signature: ", error);
            throw new Error("Error updating signature");
        }

        try {
            const newSharedSignatures = await this.createManySharedSignatures(
                addedSharedSignatures
            );
            await this.deleteManySharedSignatures(removedSharedSignatures);
            changedSignature.sharedsignatures = [
                ...updatedSignature.sharedsignatures.filter((ss) => !!ss.id),
                ...newSharedSignatures,
            ];
        } catch (err) {
            console.log(
                "Error saving new shared signatures for existing signature: ",
                err
            );
            changedSignature.sharedsignatures =
                updatedSignature.sharedsignatures;
        }

        return changedSignature;
    }

    async deleteSignature(signatureId) {
        if (!signatureId) {
            throw new Error("Error deleting signature - no signature provided");
        }

        const { error } = await this.supabase
            .from("signatures")
            .delete()
            .eq("id", signatureId);

        if (error) {
            console.log("Error deleting signature: ", error);
            throw new Error("Error deleting signature");
        }
    }

    // Shared Signatures
    async createManySharedSignatures(sharedSignatures) {
        if (!sharedSignatures) {
            throw new Error(
                "Error creating new shared sharedSignatures (undefined)"
            );
        }

        if (sharedSignatures.length === 0) {
            return [];
        }

        // First check if shared with org - don't create shares with group if exists
        const { data: existingOrgShare, error: checkOrgShareExistsError } =
            await this.supabase
                .from("sharedsignatures")
                .select(
                    "id, signature_id, organization_id, editable, users(id, email), groups(id, name)"
                )
                .not("organization_id", "is", null)
                .is("group_id", null)
                .eq("signature_id", sharedSignatures[0].signature_id)
                .maybeSingle();

        if (checkOrgShareExistsError) throw checkOrgShareExistsError;

        if (existingOrgShare) {
            return existingOrgShare;
        }

        const { data, error } = await this.supabase
            .from("sharedsignatures")
            .upsert(sharedSignatures, {
                onConflict: "signature_id, user_id, group_id, organization_id",
                ignoreDuplicates: true,
            })
            .select(
                "id, signature_id, organization_id, editable, users(id, email), groups(id, name)"
            );

        if (error) {
            console.log("Error saving new shared signatures: ", error);
            throw new Error("Error saving new shared signatures");
        }

        return data;
    }

    async deleteManySharedSignatures(sharedSignatures) {
        if (!sharedSignatures) {
            throw new Error("Error creating new shared signatures (undefined)");
        }

        if (sharedSignatures.length === 0) {
            return [];
        }

        const { error } = await this.supabase
            .from("sharedsignatures")
            .delete()
            .in(
                "id",
                sharedSignatures.map((sr) => sr.id)
            );

        if (error) {
            console.log("Error deleting shared signatures: ", error);
            throw new Error("Error deleting shared signatures");
        }
    }

    async deleteSharedSignature(ssId) {
        if (!ssId) {
            throw new Error(
                "Error deleting shared signature - no shared signature provided"
            );
        }

        const { error } = await this.supabase
            .from("sharedsignatures")
            .delete()
            .eq("id", ssId);

        if (error) {
            console.log("Error deleting shared signature: ", error);
            throw new Error("Error deleting shared signature");
        }
    }

    // Storage
    public async uploadAttachment(filePath, fileData, attachment) {
        const { error, data } = await this.supabase.storage
            .from("uploads")
            .upload(filePath, fileData, attachment);

        if (error) {
            console.log("Error uploading attachment: ", error);
            throw new Error("Error uploading attachment");
        }

        return data;
    }

    public async uploadTemplateImage(filePath, imageData, image) {
        const { error, data } = await this.supabase.storage
            .from("public-uploads")
            .upload(filePath, imageData, image);

        if (error) {
            console.log("Error uploading image: ", error);
            throw new Error("Error uploading image");
        }

        // Get public url
        const { data: urlData } = this.supabase.storage
            .from("public-uploads")
            .getPublicUrl(filePath);

        return {
            ...data,
            publicUrl: urlData.publicUrl,
        };
    }

    // Stripe
    async getStripePortalSession(userId) {
        const { data, error: funcError } = await this.supabase.functions.invoke(
            SB_FUNCTIONS.STRIPE_PORTAL_SESSION,
            {
                body: {
                    user: userId,
                },
            }
        );

        if (funcError) {
            console.log("Error creating Stripe Portal Session: ", funcError);
            throw new Error("Error creating Stripe Portal Session");
        }

        return data;
    }
}

// @ts-nocheck
import React, { useState, useEffect, useRef, useMemo } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import LinearProgress from "@mui/material/LinearProgress";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useAuth } from "../../../hooks/useAuth";
import CreateEditActions from "./CreateEditActions";
import useDebounce from "../../../hooks/useDebounce";
import { getActionNonUploadedAttachments } from "../../../helpers/ruleHelpers";
import { VALIDATION_REGEXES } from "./regexes";

import "./styles.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CreateEditRule = ({ open, onClose, onSave, isSaving, rule, organization, allAvailableTemplates, allAvailableSignatures }) => {
    const { datastore, user, isOrgUser, isOrgAdmin } = useAuth();
    const initialLoad = useRef(true);
    const [currentRule, setCurrentRule] = useState(JSON.parse(JSON.stringify(rule)));
    const [allAvailableGroups, setAllAvailableGroups] = useState([]);
    const [availableGroups, setAvailableGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [isPersonal, setIsPersonal] = useState(true);
    const [isSharedWithGroups, setIsSharedWithGroups] = useState(false);
    const [isSharedWithOrg, setIsSharedWithOrg] = useState(false);
    const [isSharedEditable, setIsSharedEditable] = useState(rule?.sharedrules[0]?.editable || false);
    const [availableTemplates, setAvailableTemplates] = useState(allAvailableTemplates || []);
    const [availableSignatures, setAvailableSignatures] = useState(allAvailableSignatures || []);
    const [ruleTemplate, setRuleTemplate] = useState(null);
    const [ruleSignature, setRuleSignature] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [hasError, setHasError] = useState(false);
    const [inputStateChanged, setInputStateChanged] = useState({
        name: false,
        description: false,
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({
        severity: "success",
        message: "",
    });
    const isEdit = useMemo(() => {
        return !!currentRule && !!currentRule.id;
    }, [currentRule]);

    const isRuleOwner = useMemo(() => {
        return !!currentRule && ((currentRule.user_id && currentRule.user_id === user.id) || (isOrgAdmin() && !currentRule.user_id))
    }, [currentRule, user]);

    const isPremiumExample = useMemo(() => {
        return !!currentRule && currentRule.metadata.premium_example;
    });

    const isSharingAvailable = useMemo(() => {
        return !isPremiumExample && (isOrgAdmin() || (isOrgUser() && (isRuleOwner || !currentRule?.id)));
    }, [currentRule, user, isRuleOwner]);

    const canEditRule = useMemo(() => {
        return !isEdit || (!isPremiumExample && (isRuleOwner || isSharedEditable));
    }, [currentRule, user, isSharedEditable]);

    const isOrgUnshared = useMemo(() => {
        return !!currentRule && currentRule.organization_id && (!currentRule.sharedrules || currentRule.sharedrules.length === 0);
    }, [currentRule]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const loadGroups = async () => {
        try {
            const results = await datastore.api.getGroups({
                select: "id, name, allow_user_sharing",
                from: 0,
                to: 1000,
            });

            if (isOrgAdmin()) {
                // Org Admins can share with any group
                setAllAvailableGroups(results.groups);
                setAvailableGroups(results.groups);
                return;
            }

            // Filter out groups without sharing enabled
            const filteredGroups = results.groups.filter((group) => group.allow_user_sharing);

            setAllAvailableGroups(filteredGroups);
            setAvailableGroups(filteredGroups);
        } catch (error) {
            console.error("Error loading groups: ", error);
            return [];
        }
    };

    const handleUpdateActions = (updatedActions) => {
        const updatedRule = { ...currentRule };
        updatedRule.metadata.actions = updatedActions;
        setCurrentRule(updatedRule);
    };

    const handleRuleEnabledToggle = (ev) => {
        const updatedRule = { ...currentRule };
        updatedRule.enabled = ev.target.checked;
        setCurrentRule(updatedRule);
    };

    const handlePersonalRuleToggle = (ev) => {
        setIsPersonal(ev.target.checked);

        if (ev.target.checked) {
            refreshToPersonalRule();
        }
    };

    const hanldeSharedWithGroupsToggles = (ev) => {
        if (ev.target.checked) {
            // Remove all shared rules
            setIsPersonal(false);
            setIsSharedWithOrg(false);
        }

        setIsSharedWithGroups(ev.target.checked);

        if (!ev.target.checked) {
            refreshToPersonalRule();
        }
    };

    const handleSharedWithOrgToggle = (ev) => {
        if (ev.target.checked) {
            // Remove all shared rules
            const updatedRule = { ...currentRule };
            updatedRule.sharedrules = [];
            setCurrentRule(updatedRule);
            setSelectedGroups([]);
            setIsSharedEditable(false);
            setIsPersonal(false);
            setIsSharedWithGroups(false);
        }

        setIsSharedWithOrg(ev.target.checked);

        if (!ev.target.checked) {
            refreshToPersonalRule();
        }
    };

    const refreshToPersonalRule = () => {
        // Remove all shared rules
        const updatedRule = { ...currentRule };
        updatedRule.sharedrules = [];
        setCurrentRule(updatedRule);
        setIsSharedEditable(false);
        setSelectedGroups([]);
        setIsSharedWithOrg(false);
        setIsSharedWithGroups(false);
    };

    const getOrCreateSharedRule = (newShared) => {
        // Check rule.sharedrules for a matching sharedrule (minus "id" field)
        const existingSharedRule = currentRule.sharedrules.find((sr) => {
            const srCopy = { ...sr };
            delete srCopy.id;
            delete srCopy.user_id;
            delete srCopy.group_id;
            return srCopy.organization_id === newShared.organization_id
                && JSON.stringify(srCopy.groups) === JSON.stringify(newShared.groups)
                && srCopy.editable === newShared.editable
                && srCopy.rule_id === newShared.rule_id;
        });

        return existingSharedRule ? existingSharedRule : newShared;
    };

    const handleClose = () => {
        if (!isSaving) {
            onClose();
        }
    };

    const handleSave = () => {
        if (!isRuleDetailsValid() || !isRuleActionsExist()) {
            setSnackbarDetails({
                severity: "error",
                message: `Please fill in all required fields to create this rule`,
            });
            setSnackbarOpen(true);
            return;
        }

        if (!isAllRuleActionsValid()) {
            setSnackbarDetails({
                severity: "error",
                message: `There are invalid actions in the rule`,
            });
            setSnackbarOpen(true);
            return;
        }

        onSave(currentRule);
    };

    const isRuleDetailsValid = () => {
        return currentRule && currentRule.name && currentRule.description;
    };

    const isRuleActionsExist = () => {
        return currentRule?.metadata?.actions?.length > 0;
    };

    const isAllRuleActionsValid = () => {
        if (currentRule.metadata.actions.length === 0) {
            return false;
        }

        return currentRule.metadata.actions.every((action) => {
            let isValid = true;
            switch (action.type) {
                case "tag":
                    isValid = !!action.tag && action.tag.length > 0;

                    break;
                case "recipient":
                    isValid = action.emails?.length > 0;

                    if (isValid) {
                        isValid = action.emails.every((email) => {
                            return VALIDATION_REGEXES.email.test(email);
                        });
                    }

                    break;
                case "attachment":
                    // Check each attachment is valid (name and url)
                    isValid = action.attachments?.length > 0;

                    if (isValid) {
                        isValid = action.attachments.every((attachment) => {
                            return (
                                attachment.name?.length > 0 &&
                                VALIDATION_REGEXES.filename.test(
                                    attachment.name
                                )
                            );
                        });
                    }

                    break;
                case "link":
                    isValid = !!action.url && action.url.length > 0;

                    isValid = VALIDATION_REGEXES.url.test(action.url);

                    break;
                case "template":
                    isValid = !!action.templateId;

                    // Check to ensure templateId is one that is available
                    const foundTemplate = availableTemplates.find(t => t.id === action.templateId);

                    if (!foundTemplate) {
                        isValid = false;
                    }

                    break;
                case "signature":
                    isValid = !!action.signatureId;

                    // Check to ensure signatureId is one that is available
                    const foundSignature = availableSignatures.find(s => s.id === action.signatureId);

                    if (!foundSignature) {
                        isValid = false;
                    }

                    break;
                case "delay":
                    if (action.method === "delay") {
                        isValid =
                            !!action.delayMinutes && action.delayMinutes > 0;
                    } else {
                        isValid = true;
                    }

                    break;
                default:
                    isValid = false;
            }

            return isValid;
        });
    };

    const groupRuleHasGroups = () => {
        if (!isSharedWithGroups) {
            return true;
        }

        return selectedGroups.length > 0;
    };

    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    const savingWithAttachmentsToSave = () => {
        if (!currentRule || !currentRule.metadata || !currentRule.metadata.actions) {
            return false;
        }

        const foundAttachments = getActionNonUploadedAttachments(currentRule.metadata.actions);
        return foundAttachments.length > 0;
    };

    const updateAvailableGroups = (newRuleTemplate, newRuleSignature) => {
        // Logic to filter or update availableGroups based on newRuleTemplate and newRuleSignature
        let templateFilteredGroups = [...allAvailableGroups];
        if (newRuleTemplate && (!newRuleTemplate.user_id || (newRuleTemplate.user_id !== user.id && newRuleTemplate.sharedtemplates.length > 0))) {
            templateFilteredGroups = templateFilteredGroups.filter((group) => {
                if (newRuleTemplate.sharedtemplates.length === 0) {
                    return true;
                }

                return newRuleTemplate.sharedtemplates.some((st) => {
                    if (st.groups) {
                        return group.id === st.groups.id;
                    }

                    return !!st.organization_id;
                });
            });
        }

        let signatureFilteredGroups = [...allAvailableGroups];
        if (newRuleSignature && (!newRuleSignature.user_id || (newRuleSignature.user_id !== user.id && newRuleSignature.sharedsignatures?.length > 0))) {
            signatureFilteredGroups = signatureFilteredGroups.filter((group) => {
                if (newRuleSignature.sharedsignatures.length === 0) {
                    return true;
                }

                return newRuleSignature.sharedsignatures.some((ss) => {
                    if (ss.groups) {
                        return group.id === ss.groups.id;
                    }

                    return !!ss.organization_id;
                });
            });
        }

        // Create a set of groups that are in both templateFilteredGroups and signatureFilteredGroups
        const filteredGroups = templateFilteredGroups.filter((group) => {
            return signatureFilteredGroups.some((sg) => sg.id === group.id);
        });

        setAvailableGroups(filteredGroups);
    };

    const updateAvailableTemplatesAndSignatures = (newAvailableGroups) => {
        // Logic to update availableTemplates and availableSignatures based on newAvailableGroups
        // Filter templates that do not have a shared rule with the group or org (or are personal)
        const filteredTemplates = allAvailableTemplates.filter((template) => {
            if (template.user_id && template.sharedtemplates.length === 0) {
                return true;
            }

            return template.sharedtemplates.some((st) => {
                if (st.groups) {
                    return newAvailableGroups.some(
                        (group) => group.id === st.groups.id
                    );
                }

                return !!st.organization_id;
            });
        });

        // Filter signatures that do not have a shared rule with the group or org (or are personal)
        const filteredSignatures = allAvailableSignatures.filter(
            (signature) => {
                if (
                    signature.user_id &&
                    signature.sharedsignatures.length === 0
                ) {
                    return true;
                }

                return signature.sharedsignatures.some((ss) => {
                    if (ss.groups) {
                        return newAvailableGroups.some(
                            (group) => group.id === ss.groups.id
                        );
                    }

                    return !!ss.organization_id;
                });
            }
        );

        setAvailableTemplates(filteredTemplates);
        setAvailableSignatures(filteredSignatures);
    };

    useEffect(() => {
        loadGroups();
    }, []);

    useEffect(() => {
        if (!rule) {
            return;
        }

        const isEdit = !!rule && !!rule.id;
        setInputStateChanged({
            name: isEdit,
            description: isEdit,
        });
        setCurrentRule(JSON.parse(JSON.stringify(rule)));
        setActiveStep(0);
        setHasError(false);

        // Determine groups rule is shared with and set selectedGroups
        if (rule.sharedrules?.length > 0) {
            const sGroups = [];
            let sharedWithOrg = false;
            rule.sharedrules.forEach((sr) => {
                if (sr.groups) {
                    sGroups.push(sr.groups);
                } else if (sr.organization_id) {
                    sharedWithOrg = true;
                }
            });

            setIsPersonal(!!rule.user_id && rule.sharedrules?.length === 0);
            setSelectedGroups(sGroups);
            setIsSharedWithOrg(sharedWithOrg);
            setIsSharedWithGroups(sGroups.length > 0);
            setIsSharedEditable(rule.sharedrules[0].editable);
        } else {
            setSelectedGroups([]);
            setIsPersonal(!isOrgUnshared);
            setIsSharedWithOrg(false);
            setIsSharedEditable(false);
        }
    }, [rule]);

    useEffect(() => {
        // Set on all sharedrules
        const updatedRule = { ...currentRule };
        updatedRule.sharedrules.forEach((sr) => {
            sr.editable = isSharedEditable;
        });
        setCurrentRule(updatedRule);
    }, [isSharedEditable]);

    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }

        if (isSharedWithOrg) {
            const updatedRule = { ...currentRule };
            updatedRule.sharedrules = [getOrCreateSharedRule({
                rule_id: currentRule.id,
                organization_id: organization.id,
                groups: null,
                editable: isSharedEditable,
            })];
            setSelectedGroups([]);
            setCurrentRule(updatedRule);
        } else {
            const updatedRule = { ...currentRule };
            updatedRule.sharedrules = [];
            setCurrentRule(updatedRule);
        }
    }, [isSharedWithOrg]);

    useEffect(() => {
        if (!isPersonal && !isSharedWithGroups && !isSharedWithOrg) {
            setIsPersonal(!isOrgUnshared);
            refreshToPersonalRule();
        }
    }, [isPersonal, isSharedWithGroups, isSharedWithOrg, isOrgUnshared]);

    useEffect(() => {
        if (!currentRule || !currentRule.metadata || !currentRule.metadata.actions) {
            setRuleTemplate(null);
            setRuleSignature(null);
            return;
        }

        // Check for action with template and/or signature
        // Used to show alerts that they will be shared
        const templateAction = currentRule.metadata.actions.find((a) => a.type === "template");
        if (templateAction) {
            const selectedTemplate = availableTemplates.find(
                (t) => t.id === templateAction.templateId
            );

            if (!templateAction.templateId) {
                setRuleTemplate(null);
            } else if (selectedTemplate && selectedTemplate.id !== ruleTemplate?.id) {
                setRuleTemplate(selectedTemplate);
            }
        } else {
            setRuleTemplate(null);
        }

        const signatureAction = currentRule.metadata.actions.find((a) => a.type === "signature");
        if (signatureAction) {
            const selectedSignature = availableSignatures.find(
                (s) => s.id === signatureAction.signatureId
            );

            if (!signatureAction.signatureId) {
                setRuleSignature(null);
            } else if (selectedSignature && selectedSignature.id !== ruleSignature?.id) {
                setRuleSignature(selectedSignature);
            }
        } else {
            setRuleSignature(null);
        }
    }, [currentRule?.metadata?.actions, availableTemplates, availableSignatures]);

    useEffect(() => {
        if (!isOrgAdmin()) {
            updateAvailableGroups(ruleTemplate, ruleSignature);
        }
    }, [ruleTemplate, ruleSignature]);

    useEffect(() => {
        if (!isOrgAdmin()) {
            updateAvailableTemplatesAndSignatures(availableGroups);
        }
    }, [availableGroups]);

    useDebounce(
        () => {
            setHasError(
                !isRuleDetailsValid() ||
                    !isRuleActionsExist() ||
                    !isAllRuleActionsValid() ||
                    !groupRuleHasGroups()
            );
        },
        [currentRule, selectedGroups, isSharedWithGroups, isSharedWithOrg],
        300
    );

    if (!currentRule) {
        return null;
    }

    return (
        <Dialog
            className="create-edit-rule-dialog"
            onClose={handleClose}
            open={open}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>{isEdit ? "Edit" : "Create"} Rule</DialogTitle>

            {isSaving && (
                <>
                    <LinearProgress />
                    {savingWithAttachmentsToSave() && (
                        <MuiAlert className="saving-alert" severity="warning">
                            This may take a bit as we securely save your
                            attachments...
                        </MuiAlert>
                    )}
                </>
            )}

            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent className="create-edit-dialog-content">
                {rule?.sharedrules?.length > 0 && isEdit && canEditRule && (
                    <MuiAlert className="shared-warning" severity="warning">
                        Any changes to this rule will affect all{" "}
                        {isSharedWithOrg ? "users" : "groups"} it is shared with
                    </MuiAlert>
                )}
                {isEdit && !canEditRule && (
                    <MuiAlert className="shared-warning" severity="warning">
                        You do not have permission to edit this {isPremiumExample ? 'example' : 'shared'} rule.
                    </MuiAlert>
                )}
                <Stepper activeStep={activeStep} orientation="vertical">
                    {/* STEP: DETAILS */}
                    <Step key="step-details">
                        <StepLabel>Rule Details</StepLabel>
                        <StepContent className="step-content">
                            <TextField
                                required
                                fullWidth
                                className="text-input"
                                label="Rule Name"
                                inputProps={{
                                    maxLength: 128,
                                }}
                                value={currentRule?.name || ""}
                                disabled={!canEditRule}
                                error={
                                    inputStateChanged.name && !currentRule.name
                                }
                                helperText={
                                    inputStateChanged.name && !currentRule.name
                                        ? "Rule name is required"
                                        : ""
                                }
                                onChange={(ev) =>
                                    setCurrentRule((prev) => ({
                                        ...prev,
                                        name: ev.target.value,
                                    }))
                                }
                                onBlur={() =>
                                    setInputStateChanged((prev) => ({
                                        ...prev,
                                        name: true,
                                    }))
                                }
                            />
                            <TextField
                                required
                                fullWidth
                                className="text-input"
                                label="Rule Description"
                                inputProps={{
                                    maxLength: 256,
                                }}
                                value={currentRule?.description || ""}
                                disabled={!canEditRule}
                                error={
                                    inputStateChanged.description &&
                                    !currentRule.description
                                }
                                helperText={
                                    inputStateChanged.description &&
                                    !currentRule.description
                                        ? "Rule description is required"
                                        : ""
                                }
                                onChange={(ev) =>
                                    setCurrentRule((prev) => ({
                                        ...prev,
                                        description: ev.target.value,
                                    }))
                                }
                                onBlur={() =>
                                    setInputStateChanged((prev) => ({
                                        ...prev,
                                        description: true,
                                    }))
                                }
                            />
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        disabled={
                                            !isRuleDetailsValid() || isSaving
                                        }
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>

                    {/* STEP: ACTIONS */}
                    <Step key="step-actions">
                        <StepLabel>Rule Actions</StepLabel>
                        <StepContent className="step-content">
                            <CreateEditActions
                                actions={currentRule.metadata?.actions}
                                updateActions={handleUpdateActions}
                                canEdit={canEditRule}
                                availableTemplates={availableTemplates}
                                availableSignatures={availableSignatures}
                            />
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={
                                            isSharingAvailable
                                                ? handleNext
                                                : handleSave
                                        }
                                        disabled={
                                            !isRuleActionsExist() ||
                                            !isAllRuleActionsValid() ||
                                            isSaving
                                        }
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {isSharingAvailable
                                            ? "Next"
                                            : isEdit
                                            ? "Save Rule"
                                            : "Create Rule"}
                                    </Button>
                                    <Button
                                        onClick={handleBack}
                                        disabled={isSaving}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>

                    {/* STEP: SHARING */}
                    {isSharingAvailable && (
                        <Step key="step-sharing">
                            <StepLabel>Rule Sharing</StepLabel>
                            <StepContent className="step-content">
                                <FormControlLabel
                                    className="org-shared-toggle"
                                    control={
                                        <Switch
                                            checked={currentRule?.enabled}
                                            onChange={
                                                handleRuleEnabledToggle
                                            }
                                            inputProps={{
                                                "aria-label":
                                                    "Rule enabled toggle switch",
                                            }}
                                        />
                                    }
                                    label="Rule enabled"
                                />

                                {((!currentRule?.id || (isRuleOwner && !currentRule?.organization_id) || (!isSharedWithGroups && !isSharedWithOrg)) && !isOrgUnshared) && (
                                    <FormControlLabel
                                        className="org-shared-toggle"
                                        control={
                                            <Switch
                                                checked={isPersonal}
                                                onChange={
                                                    handlePersonalRuleToggle
                                                }
                                                disabled={
                                                    !isSharedWithGroups &&
                                                    !isSharedWithOrg
                                                }
                                                inputProps={{
                                                    "aria-label":
                                                        "Peronsal rule toggle switch",
                                                }}
                                            />
                                        }
                                        label="Personal Rule"
                                    />
                                )}

                                {isOrgAdmin() && (
                                    <FormControlLabel
                                        className="org-shared-toggle"
                                        control={
                                            <Switch
                                                checked={
                                                    isSharedWithOrg || false
                                                }
                                                disabled={
                                                    isEdit &&
                                                    isSharedWithOrg &&
                                                    rule.sharedrules.length > 0
                                                }
                                                onChange={
                                                    handleSharedWithOrgToggle
                                                }
                                                inputProps={{
                                                    "aria-label":
                                                        "Shared rule with entire organization toggle switch",
                                                }}
                                            />
                                        }
                                        label="Share rule with entire organization"
                                    />
                                )}

                                <FormControlLabel
                                    className="org-shared-toggle"
                                    control={
                                        <Switch
                                            checked={
                                                isSharedWithGroups || false
                                            }
                                            disabled={
                                                isEdit &&
                                                isSharedWithGroups &&
                                                rule.sharedrules.length > 0
                                            }
                                            onChange={
                                                hanldeSharedWithGroupsToggles
                                            }
                                            inputProps={{
                                                "aria-label":
                                                    "Shared rule with groups toggle switch",
                                            }}
                                        />
                                    }
                                    label="Share rule with groups"
                                />

                                {isSharedWithGroups && (
                                    <>
                                        <FormControlLabel
                                            className="allow-editing-toggle"
                                            control={
                                                <Switch
                                                    checked={
                                                        isSharedEditable ||
                                                        false
                                                    }
                                                    onChange={(ev) => {
                                                        setIsSharedEditable(
                                                            ev.target.checked
                                                        );
                                                    }}
                                                    inputProps={{
                                                        "aria-label":
                                                            "Allow editing of rule by group members toggle switch",
                                                    }}
                                                    disabled={
                                                        isEdit &&
                                                        !isRuleOwner &&
                                                        rule.sharedrules
                                                            .length > 0
                                                    }
                                                />
                                            }
                                            label="Allow editing by shared group members"
                                        />

                                        <Autocomplete
                                            className="group-members-list"
                                            multiple
                                            disableCloseOnSelect
                                            disableClearable={isEdit}
                                            getOptionLabel={(option) =>
                                                typeof option === "string"
                                                    ? option
                                                    : option.name
                                            }
                                            options={availableGroups}
                                            noOptionsText="No groups found"
                                            value={selectedGroups}
                                            getOptionDisabled={(option) =>
                                                !isOrgAdmin() &&
                                                !isRuleOwner &&
                                                rule.sharedrules.some(
                                                    (r) =>
                                                        r.groups?.id ===
                                                        option.id
                                                )
                                            }
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.id === value.id}
                                            onChange={(event, newValue) => {
                                                const updatedRule = {
                                                    ...currentRule,
                                                };

                                                const updatedSharedRules =
                                                    newValue.map((group) =>
                                                        getOrCreateSharedRule({
                                                            rule_id:
                                                                currentRule.id,
                                                            groups: group,
                                                            organization_id:
                                                                null,
                                                            editable:
                                                                isSharedEditable,
                                                        })
                                                    );

                                                updatedRule.sharedrules =
                                                    updatedSharedRules;
                                                setCurrentRule(updatedRule);

                                                setSelectedGroups(newValue);
                                            }}
                                            renderTags={(
                                                tagValue,
                                                getTagProps
                                            ) =>
                                                tagValue.map(
                                                    (option, index) => {
                                                        const {
                                                            key,
                                                            ...tagProps
                                                        } = getTagProps({
                                                            index,
                                                        });
                                                        return (
                                                            <Chip
                                                                key={key}
                                                                label={
                                                                    option.name
                                                                }
                                                                {...tagProps}
                                                                disabled={
                                                                    !isOrgAdmin() &&
                                                                    !isRuleOwner &&
                                                                    rule.sharedrules.some(
                                                                        (r) =>
                                                                            r
                                                                                .groups
                                                                                ?.id ===
                                                                            option.id
                                                                    )
                                                                }
                                                            />
                                                        );
                                                    }
                                                )
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Share rule with group"
                                                    fullWidth
                                                />
                                            )}
                                            renderOption={(
                                                props,
                                                option,
                                                { selected }
                                            ) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={
                                                            <CheckBoxOutlineBlankIcon fontSize="small" />
                                                        }
                                                        checkedIcon={
                                                            <CheckBoxIcon fontSize="small" />
                                                        }
                                                        style={{
                                                            marginRight: 8,
                                                        }}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </li>
                                            )}
                                        />
                                    </>
                                )}

                                {ruleTemplate && !isPersonal && (
                                    <MuiAlert
                                        severity="warning"
                                        className="share-warning"
                                    >
                                        Template will be shared with{" "}
                                        {isSharedWithOrg
                                            ? "entire organization"
                                            : "selected groups"}{" "}
                                        if it is not already
                                    </MuiAlert>
                                )}

                                {ruleSignature && !isPersonal && (
                                    <MuiAlert
                                        severity="warning"
                                        className="share-warning"
                                    >
                                        Signature will be shared with{" "}
                                        {isSharedWithOrg
                                            ? "entire organization"
                                            : "selected groups"}{" "}
                                        if it is not already
                                    </MuiAlert>
                                )}

                                <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleSave}
                                            disabled={hasError || isSaving}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {isEdit
                                                ? "Save Rule"
                                                : "Create Rule"}
                                        </Button>
                                        <Button
                                            onClick={handleBack}
                                            disabled={isSaving}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    )}
                </Stepper>
            </DialogContent>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarDetails.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default CreateEditRule;

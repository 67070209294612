// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import {
    CardElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { useAuth } from "../../hooks/useAuth";
import {
    REACT_APP_STRIPE_PRICE_IDS,
    REACT_APP_STRIPE_PRICING,
} from "../../helpers/constants";

import "./styles.scss";

const CARDELEMENT_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#525CEB",
            color: "#222",
            fontWeight: 500,
            fontFamily: "'Noto Sans', sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            "::placeholder": {
                color: "#888888",
            },
        },
        invalid: {
            iconColor: "#d32f2f",
            color: "#d32f2f",
        },
    },
};

const CardErrorMessage = ({ children }) => (
    <div className="card-error-message">{children}</div>
);

export default function SignupWithPayment({ interval, seats, completePayment, type }) {
    const { user, organization, getSubscription, datastore } = useAuth();
    const [cardName, setCardName] = useState("");
    const [generalError, setGeneralError] = useState();
    const [nameError, setNameError] = useState();
    const [cardError, setCardError] = useState();
    const [isProcessing, setIsProcessing] = useState(false);
    const [checkoutSuccess, setCheckoutSuccess] = useState(false);

    // stripe items
    const stripe = useStripe();
    const elements = useElements();

    const createSubscription = async () => {
        if (
            !user ||
            (type === "business" && !organization) ||
            !stripe ||
            !elements
        ) {
            setGeneralError(
                "Unable to process payment. Please try again later."
            );
            return;
        }

        setGeneralError(null);

        if (!cardName) {
            setNameError("Missing name on card");
            return;
        }

        setNameError(null);
        setIsProcessing(true);

        const baseSubscriptionData = {
            name: cardName,
            priceId: REACT_APP_STRIPE_PRICE_IDS[type][interval],
            interval: interval,
        };

        const subscriptionData =
            type === "premium"
                ? { user: user.id, email: user.email, ...baseSubscriptionData }
                : {
                      organization: organization.id,
                      email: organization.billing_email,
                      quantity: seats,
                      ...baseSubscriptionData,
                  };

        try {
            const { paymentMethod, error } = await stripe?.createPaymentMethod({
                type: "card",
                card: elements?.getElement(CardElement),
                billing_details: {
                    email: subscriptionData.email,
                },
            });

            if (error) {
                console.log("Error creating payment method: ", error);
                setGeneralError(
                    "An error occurred with your payment method. Please try again later."
                );
                setIsProcessing(false);
                return;
            }

            let newSubscription;
            try {
                newSubscription = await datastore.api.createSubscriptionWithPayment({
                    paymentMethod: paymentMethod?.id,
                    card_last4: paymentMethod?.card?.last4,
                    card_brand: paymentMethod?.card?.brand,
                    ...subscriptionData
                });
            } catch (error) {
                console.log("Error creating subscription: ", funcError);
                setGeneralError(
                    "Unable to process payment and create subscription. Please try again later."
                );
                setIsProcessing(false);
                return;
            }

            const confirmPayment = await stripe?.confirmCardPayment(
                newSubscription.clientSecret
            );

            if (confirmPayment?.error) {
                console.log(
                    "Error confirming payment: ",
                    confirmPayment.error.message
                );
                // confirmPayment.error.message
                setGeneralError(
                    "There was an error confirming your payment method. Please contact support at support@gettoggles.com."
                );
            } else {
                setCheckoutSuccess(true);
            }

            // Fetch updated subscription (status should be updated) -- waiting 3 seconds to allow that event to be received
            await new Promise((resolve) => setTimeout(resolve, 3000));
            const { data: subscription, error: subscriptionError } = await getSubscription();

            if (subscriptionError) {
                console.log("Error fetching subscription: ", subscriptionError);
                setGeneralError("Unable to fetch subscription details. Please refresh the page.");
            }

            const updatedSubscription = subscription || null;
            setIsProcessing(false);
            setTimeout(() => {
                completePayment(updatedSubscription);
            }, 5000);
        } catch (error) {
            console.log("Error creating subscription: ", error);
            setGeneralError("Unable to process payment. Please try again.");
            setIsProcessing(false);
        }
    };

    const handleCardNameChange = (event) => {
        setCardName(event.target.value);

        if (event.target.value && event.target.value.length > 0) {
            setNameError(null);
        }
    };

    const handleCardElementChange = (event) => {
        if (event.error) {
            setCardError(event.error);
        }

        if (event.complete) {
            setCardError(null);
            console.log("Card element event complete");
        }
    };

    const getIntervalCost = () => {
        if (!interval || !seats) {
            return '???';
        }

        if (interval === 'monthly') {
            return (seats * REACT_APP_STRIPE_PRICING[type].monthly).toFixed(2);
        }

        if (interval === 'annually') {
            return (seats * REACT_APP_STRIPE_PRICING[type].annually).toFixed(2);
        }
    };

    return (
        <div className="payment-form">
            <Typography
                className="card-details-label"
                variant="subtitle2"
                display="block"
            >
                Card Details (Powered by Stripe)
            </Typography>
            <TextField
                fullWidth
                required
                className="card-name-input"
                label="Name on card"
                variant="outlined"
                onChange={handleCardNameChange}
                error={!!nameError}
                helperText={nameError}
            />
            <CardElement
                options={CARDELEMENT_OPTIONS}
                onChange={handleCardElementChange}
            />
            {cardError && (
                <CardErrorMessage>{cardError.message}</CardErrorMessage>
            )}
            <LoadingButton
                className="checkout-button"
                onClick={createSubscription}
                fullWidth
                variant="contained"
                size="large"
                disabled={!!cardError || !!nameError}
                loading={isProcessing}
                loadingIndicator="Processing…"
            >
                <span>Purchase</span>
            </LoadingButton>

            {generalError && (
                <Alert className="checkout-error" severity="error">
                    {generalError}
                </Alert>
            )}

            <Typography
                className="charge-note"
                variant="subtitle2"
                align="center"
            >
                Your card will be charged ${getIntervalCost()} USD {interval}.
                You can cancel renewal at any time via the Subscription
                Management screen on your Account page.
            </Typography>

            <Typography
                className="charge-note"
                variant="subtitle2"
                align="center"
            >
                By purchasing a subscription, you agree to our{" "}
                <Link href="https://www.gettoggles.com/terms" target="_blank" underline="none">
                    Terms of Service
                </Link>{" "}
                and{" "}
                <Link href="https://www.gettoggles.com/privacy" target="_blank" underline="none">
                    Privacy Policy
                </Link>.
            </Typography>

            {checkoutSuccess && (
                <Alert className="checkout-success" severity="success">
                    Your subscription has been successfully created! You will
                    receive an email confirmation shortly.
                </Alert>
            )}
        </div>
    );
};

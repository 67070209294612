// @ts-nocheck
import React, { useState, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import BusinessIcon from "@mui/icons-material/Business";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { useAuth } from "../../../hooks/useAuth";
import { convertUTCToLocalTime } from "../../../helpers/timeHelpers";

import "./styles.scss";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

function RuleRow({ rule, isPersonal, missingTemplate, missingSignature, handleEdit, handleDelete, removeSharedRule }) {
    const { user, isOrgUser, isOrgAdmin } = useAuth();
    const [open, setOpen] = useState(false);
    const isRuleOwner = useMemo(() => {
        return !!rule && ((rule.user_id && rule.user_id === user.id) || (isOrgAdmin() && !rule.user_id))
    }, [rule, user]);
    const isPremiumExample = useMemo(() => {
        return !!rule && rule.metadata.premium_example;
    });
    const canEdit = useMemo(() => {
        return isRuleOwner || isOrgAdmin() || rule.sharedrules[0]?.editable;
    }, [user, rule]);
    const canDelete = useMemo(() => {
        return !isPremiumExample && isRuleOwner;
    }, [user, rule]);

    const getSharedRuleItem = (ruleId, sharedRule) => {
        const isOrg = sharedRule.organization_id != null; // checks if null and undefined with only double =
        const isGroup = !!sharedRule.groups;
        const isUser = !!sharedRule.users;

        let type;
        let name;
        let icon;

        if (isOrg) {
            type = 'organization'
            name = 'All users';
            icon = <BusinessIcon className="shared-rule-icon" />;
        } else if (isGroup) {
            type = 'group';
            name = sharedRule.groups.name;
            icon = <GroupsIcon className="shared-rule-icon" />;
        } else if (isUser) {
            type = 'user';
            name = sharedRule.users.email;
            icon = <PersonIcon className="shared-rule-icon" />;
        } else {
            return;
        }

        return (
            <div
                className="list-shared-rule"
                key={`list-shared-rule-${sharedRule.id}`}
            >
                <div className="basic">
                    <div className="details">
                        <Typography className="rule-name" variant="subtitle2">
                            {icon}
                            {name}
                        </Typography>
                    </div>
                    <div className="item-actions">
                        {sharedRule.editable && (
                            <Chip className="action-count" label={`Editable`} />
                        )}
                        {isOrgAdmin() && (
                            <Tooltip title={`Unshare rule with ${type}`}>
                                <span>
                                    <IconButton
                                        className="icon-button"
                                        aria-label="delete"
                                        disabled={!isOrgAdmin()}
                                        onClick={() =>
                                            removeSharedRule(
                                                ruleId,
                                                sharedRule.id
                                            )
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const getRuleOwner = (rule) => {
        if (!!rule.user_id) {
            return rule.users?.email || 'Unknown';
        }

        return 'Organization';
    };

    const lastUpdatedDayJS = dayjs(convertUTCToLocalTime(rule?.updated_at || null));

    return (
        <>
            <TableRow
                key={rule.id}
                sx={{
                    "&:last-child td, &:last-child th": {
                        border: 0,
                    },
                }}
            >
                {isOrgUser() && (
                    <TableCell padding="checkbox">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </TableCell>
                )}
                <TableCell component="th" scope="row">
                    {rule.name}
                </TableCell>
                <TableCell align="left">
                    {isPersonal || !isOrgAdmin()
                        ? rule.description
                        : getRuleOwner(rule)}
                </TableCell>
                <TableCell align="center">
                    <Tooltip
                        title={lastUpdatedDayJS.format("LLL")}
                        placement="right"
                    >
                        <span>{lastUpdatedDayJS.fromNow()}</span>
                    </Tooltip>
                </TableCell>
                <TableCell align="right">
                    {isPremiumExample && (
                        <Tooltip title="Example Premium Rule">
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="Example Premium Rule warning"
                                    disabled
                                >
                                    <InfoOutlinedIcon color="info" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    {missingTemplate && (
                        <Tooltip title="Template used by rule is not available">
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="Template used by rule is not available warning"
                                    disabled
                                >
                                    <InfoOutlinedIcon color="error" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    {missingSignature && (
                        <Tooltip title="Signature used by rule is not available">
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="Signature used by rule is not available warning"
                                    disabled
                                >
                                    <InfoOutlinedIcon color="error" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    {isOrgUser() && (
                        <Tooltip
                            title={`Rule is ${rule.enabled ? "enabled" : "disabled"}`}
                        >
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="Rule enabled or disabled indicator" 
                                    disabled
                                >
                                    {rule.enabled ? (
                                        <VisibilityIcon color="primary" />
                                    ) : (
                                        <VisibilityOffIcon />
                                    )}
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    <Tooltip
                        title={`${
                            canEdit
                                ? "Edit Rule"
                                : "You do not have permission to edit this rule"
                        }`}
                    >
                        <span>
                            <IconButton
                                className="icon-button"
                                aria-label="edit"
                                disabled={!canEdit}
                                onClick={() => handleEdit(rule)}
                            >
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={`${
                            canDelete
                                ? "Delete Rule"
                                : "You do not have permission to delete this rule"
                        }`}
                    >
                        <span>
                            <IconButton
                                className="icon-button"
                                aria-label="delete"
                                disabled={!canDelete}
                                onClick={() => handleDelete(rule.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
            </TableRow>

            {isOrgUser() && (
                <TableRow>
                    <TableCell style={{ padding: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <div className="group-rules">
                                <Typography
                                    className="rules-section-label"
                                    variant="subtitle"
                                    gutterBottom
                                    component="div"
                                >
                                    Shared with
                                </Typography>
                                {!rule.sharedrules ||
                                rule.sharedrules?.length === 0 ? (
                                    <Alert severity="warning">
                                        Rule is not shared with any users or
                                        groups
                                    </Alert>
                                ) : (
                                    <div className="list-container">
                                        {rule.sharedrules.map((sharedRule) =>
                                            getSharedRuleItem(
                                                rule.id,
                                                sharedRule
                                            )
                                        )}
                                    </div>
                                )}
                            </div>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default function RuleList({
    rules,
    templates,
    signatures,
    loading,
    isPersonal,
    setPage,
    totalCount,
    page,
    pageSize,
    handleToggleEnabled,
    handleEdit,
    handleDelete,
    handleRemoveSharedRule,
}) {
    const { isOrgUser, isOrgAdmin } = useAuth();
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getLoadingRow = () => {
        return (
            <TableRow>
                <TableCell colSpan={5} align="center">
                    <LoadingIndicator />
                </TableCell>
            </TableRow>
        );
    };

    const getRows = (isPersonal) => {
        if (loading) {
            return getLoadingRow();
        }

        if (rules.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={5} align="center">
                        No rules found
                    </TableCell>
                </TableRow>
            );
        }

        return rules.map((rule) => {
            // Check for template or signature actions and check if those exist
            // If not, flag this on the RuleRow
            const templateAction = rule.metadata.actions.find((a) => a.type === "template");
            const signatureAction = rule.metadata.actions.find((a) => a.type === "signature");

            const missingTemplate = templateAction && !templates.find((t) => t.id === templateAction.templateId);
            const missingSignature = signatureAction && !signatures.find((s) => s.id === signatureAction.signatureId);

            return (
                <RuleRow
                    key={`rule-row-${rule.id}`}
                    rule={rule}
                    isPersonal={isPersonal}
                    missingTemplate={missingTemplate}
                    missingSignature={missingSignature}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    removeSharedRule={handleRemoveSharedRule}
                />
            );
        });
    };

    return (
        <div className="data-list">
            <TableContainer className="data-table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="table-header">
                        <TableRow>
                            {isOrgUser() && <TableCell padding="checkbox" />}
                            <TableCell>Rule Name</TableCell>
                            <TableCell align="left">
                                {isPersonal || !isOrgAdmin()
                                    ? "Description"
                                    : "Owner"}
                            </TableCell>
                            <TableCell align="center">Last Updated</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{getRows(isPersonal)}</TableBody>
                </Table>
            </TableContainer>

            <Pagination
                className="table-paging"
                page={page}
                count={Math.ceil(totalCount / pageSize)}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
            />
        </div>
    );
}



// @ts-nocheck
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LoadingIndicator from "../../../components/LoadingIndicator";

import "./styles.scss";

function GroupRow({ group, handleEdit, handleDelete, removeRule, removeTemplate, removeSignature }) {
    const [open, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("rules");

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const getRuleItem = (sharedRule) => {
        return (
            <div className="list-item" key={`list-rule-${sharedRule.rule.id}`}>
                <div className="basic">
                    <div className="details">
                        <Typography className="item-name" variant="subtitle1">
                            {sharedRule.rule.name}
                        </Typography>
                        <Typography variant="subtitle2">
                            {sharedRule.rule.description}
                        </Typography>
                    </div>
                    <div className="item-actions">
                        <Chip
                            className="action-count"
                            label={`${sharedRule.rule.metadata.actions.length} ${
                                sharedRule.rule.metadata.actions.length === 1
                                    ? "action"
                                    : "actions"
                            }`}
                        />
                        <Tooltip
                            title={'Remove rule from group'}
                        >
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="delete"
                                    onClick={() => removeRule(group.id, sharedRule.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    };

    const getTemplateItem = (sharedTemplate) => {
        return (
            <div className="list-item" key={`list-template-${sharedTemplate.template.id}`}>
                <div className="basic">
                    <div className="details">
                        <Typography className="item-name" variant="subtitle1">
                            {sharedTemplate.template.name}
                        </Typography>
                        <Typography variant="subtitle2">
                            {sharedTemplate.template.description}
                        </Typography>
                    </div>
                    <div className="item-actions">
                        <Chip
                            className="action-count"
                            label={`${
                                sharedTemplate.template.variables.length
                            } ${
                                sharedTemplate.template.variables.length === 1
                                    ? "variable"
                                    : "variables"
                            }`}
                        />
                        <Tooltip title={"Remove template from group"}>
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="delete"
                                    onClick={() =>
                                        removeTemplate(group.id, sharedTemplate.id)
                                    }
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    };

    const getSignatureItem = (sharedSignature) => {
        return (
            <div className="list-item" key={`list-signature-${sharedSignature.signature.id}`}>
                <div className="basic">
                    <div className="details">
                        <Typography className="item-name" variant="subtitle1">
                            {sharedSignature.signature.name}
                        </Typography>
                        <Typography variant="subtitle2">
                            {sharedSignature.signature.description}
                        </Typography>
                    </div>
                    <div className="item-actions">
                        <Chip
                            className="action-count"
                            label={`${
                                sharedSignature.signature.variables.length
                            } ${
                                sharedSignature.signature.variables.length === 1
                                    ? "variable"
                                    : "variables"
                            }`}
                        />
                        <Tooltip title={"Remove signature from group"}>
                            <span>
                                <IconButton
                                    className="icon-button"
                                    aria-label="delete"
                                    onClick={() =>
                                        removeSignature(group.id, sharedSignature.id)
                                    }
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <TableRow
                key={group.id}
                sx={{
                    "&:last-child td, &:last-child th": {
                        border: 0,
                    },
                }}
            >
                <TableCell padding="checkbox">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {group.name}
                </TableCell>
                <TableCell align="left">{group.description}</TableCell>
                <TableCell align="center">
                    <Chip
                        icon={<PersonIcon />}
                        fontSize="small"
                        label={group.groupusers.length}
                    />
                </TableCell>
                <TableCell align="right">
                    <IconButton
                        className="icon-button"
                        aria-label="edit"
                        onClick={() => handleEdit(group)}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        className="icon-button"
                        aria-label="delete"
                        onClick={() => handleDelete(group.id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box className="shared-container">
                            <TabContext value={activeTab}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <TabList
                                        onChange={handleTabChange}
                                        aria-label="Group shared items"
                                    >
                                        <Tab
                                            label="Group Rules"
                                            value="rules"
                                        />
                                        <Tab
                                            label="Group Templates"
                                            value="templates"
                                        />
                                        <Tab
                                            label="Group Signatures"
                                            value="signatures"
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="rules">
                                    <div className="shared-items">
                                        {group.sharedrules.length === 0 ? (
                                            <Alert severity="warning">
                                                No rules shared with group
                                            </Alert>
                                        ) : (
                                            <div className="list-container">
                                                {group.sharedrules.map((rule) =>
                                                    getRuleItem(rule)
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </TabPanel>
                                <TabPanel value="templates">
                                    <div className="shared-items">
                                        {group.sharedtemplates.length === 0 ? (
                                            <Alert severity="warning">
                                                No templates shared with group
                                            </Alert>
                                        ) : (
                                            <div className="list-container">
                                                {group.sharedtemplates.map((template) =>
                                                    getTemplateItem(template)
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </TabPanel>
                                <TabPanel value="signatures">
                                    <div className="shared-items">
                                        {group.sharedsignatures.length === 0 ? (
                                            <Alert severity="warning">
                                                No signatures shared with group
                                            </Alert>
                                        ) : (
                                            <div className="list-container">
                                                {group.sharedsignatures.map((signature) =>
                                                    getSignatureItem(signature)
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default function GroupList({
    groups,
    loading,
    setPage,
    totalCount,
    page,
    pageSize,
    handleEdit,
    handleDelete,
    handleRemoveRule,
    handleRemoveTemplate,
    handleRemoveSignature,
}) {
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getLoadingRow = () => {
        return (
            <TableRow>
                <TableCell colSpan={5} align="center">
                    <LoadingIndicator />
                </TableCell>
            </TableRow>
        );
    };

    const getRows = () => {
        if (loading) {
            return getLoadingRow();
        }

        if (groups.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={5} align="center">
                        No groups found
                    </TableCell>
                </TableRow>
            );
        }

        return groups.map((group) => (
            <GroupRow
                key={`group-row-${group.id}`}
                group={group}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                removeRule={handleRemoveRule}
                removeTemplate={handleRemoveTemplate}
                removeSignature={handleRemoveSignature}
            />
        ));
    };

    return (
        <div className="data-list">
            <TableContainer className="data-table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="table-header">
                        <TableRow>
                            <TableCell padding="checkbox" />
                            <TableCell>Group Name</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="center"># of users</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{getRows()}</TableBody>
                </Table>
            </TableContainer>

            <Pagination
                className="table-paging"
                page={page}
                count={Math.ceil(totalCount / pageSize)}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
            />
        </div>
    );
}



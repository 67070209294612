// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import SearchBar from "../../components/SearchBar";
import SignatureList from "./SignatureList";
import CreateEditSignature from "./CreateEditSignature";

import "./styles.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Signatures() {
    useDocumentTitle("Toggles Hub | Signatures");
    const initialLoad = useRef(true);
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { isOrgAdmin, user, setUser, organization, groups, datastore } = useAuth();
    const [currentTab, setCurrentTab] = useState("personal");
    const [signatures, setSignatures] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({
        severity: "success",
        message: "",
    });
    const [signatureToCreateOrEdit, setSignatureToCreateOrEdit] = useState(null);
    const [signatureToDelete, setSignatureToDelete] = useState(null);
    const [sharedSignatureToDelete, setSharedSignatureToDelete] = useState(null);

    const fetchPersonalSignatures = async (query) => {
        try {
            const data = await datastore.api.getPersonalSignatures(query);

            setSignatures(data.signatures);
            setTotalCount(data.count);
        } catch (err) {
            console.error("Error loading signatures: ", err);
        }
    };

    const fetchGroupSignatures = async (query) => {
        try {
            const data = await datastore.api.getGroupSignatures(query);

            setSignatures(data.signatures);
            setTotalCount(data.count);
        } catch (err) {
            console.error("Error loading signatures: ", err);
        }
    };

    const fetchOrganizationSignatures = async (query) => {
        try {
            const data = await datastore.api.getOrganizationSignatures(query);

            setSignatures(data.signatures);
            setTotalCount(data.count);
        } catch (err) {
            console.error("Error loading signatures: ", err);
        }
    };

    const fetchOrgUnsharedSignatures = async (query) => {
        try {
            const data = await datastore.api.getOrgUnsharedSignatures(query);

            setSignatures(data.signatures);
            setTotalCount(data.count);
        } catch (err) {
            console.error("Error loading signatures: ", err);
        }
    };

    const fetchSignatures = async ({ pageToFetch, query }) => {
        setLoading(true);
        const queryData = {
            from: (pageToFetch - 1) * pageSize,
            to: pageToFetch * pageSize - 1,
        };

        if (query) {
            queryData.q = query;
        }

        if (currentTab === "personal") {
            await fetchPersonalSignatures(queryData);
        } else if (currentTab === "group") {
            await fetchGroupSignatures(queryData);
        } else if (currentTab === "organization") {
            await fetchOrganizationSignatures(queryData);
        } else if (currentTab === "unshared") {
            await fetchOrgUnsharedSignatures(queryData);
        }

        setLoading(false);
    };

    const loadPage = (pageToLoad) => {
        fetchSignatures({ pageToFetch: pageToLoad, query: searchQuery });
        setPage(pageToLoad);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    };

    const handleCreate = async () => {
        const newSignature = {
            name: "",
            description: "",
            html_content: "",
            sharedsignatures: [],
            metadata: {
                variables: [],
            },
            enabled: true,
        };

        if (currentTab === "organization") {
            newSignature.sharedsignatures.push({
                signature_id: null,
                organization_id: organization.id,
                editable: false,
            });
        }

        setSignatureToCreateOrEdit(newSignature);
    };

    const handleEdit = (signature) => {
        setSignatureToCreateOrEdit(signature)
    };

    const handleSave = async (signature) => {
        setIsSaving(true);

        const isNew = !signature.id;
        let savedSignature;

        if (isNew) {
            const addedSharedSignatures = signature.sharedsignatures.map((r) => ({
                signature_id: r.id,
                user_id: r.users?.id,
                group_id: r.groups?.id,
                organization_id: r.organization_id,
                editable: r.editable,
            }));

            // Create
            try {
                const newSignature = await datastore.api.createSignature({
                    ...signature,
                    sharedsignatures: addedSharedSignatures,
                });
                savedSignature = newSignature;
            } catch (error) {
                console.error("error creating new signature", error);
                setSnackbarDetails({
                    severity: "error",
                    message: `Error ${isNew ? "creating" : "updating"} "${
                        signature.name
                    }"`,
                });
                setSnackbarOpen(true);
                setIsSaving(false);
                return;
            }
        } else {
            const { addedSharedSignatures, removedSharedSignatures } = getSharedSignatureChanges(signature);

            // Update
            try {
                const updatedSignature = await datastore.api.updateSignature({
                    ...signature,
                    added_sharedsignatures: addedSharedSignatures,
                    removed_sharedsignatures: removedSharedSignatures,
                });
                savedSignature = updatedSignature;
            } catch (error) {
                console.error("error creating new signature", error);
                setSnackbarDetails({
                    severity: "error",
                    message: `Error ${isNew ? "creating" : "updating"} "${
                        signature.name
                    }"`,
                });
                setSnackbarOpen(true);
                setIsSaving(false);
                return;
            }
        }

        let newMeVars = [];
        savedSignature.metadata?.variables?.forEach((variable) => {
            if (variable.name.toLowerCase().indexOf("me::") === 0) {
                const varName = variable.name.substring(4);
                if (!user.metadata?.[varName]) {
                    newMeVars.push(varName);
                }
            }
        });

        if (newMeVars.length > 0) {
            try {
                let updatedUser = { ...user };
                newMeVars.forEach((varName) => {
                    (updatedUser.metadata ||= {})[varName] = "";
                });
                const savedUpdatedUser = await datastore.api.updateAccount(
                    updatedUser
                );
                setUser(savedUpdatedUser);
            } catch (error) {
                console.error("error updating user metadata with new 'me' variables", error);
            }
        }

        const signatureType = savedSignature.sharedsignatures.length === 0 ? "personal" : savedSignature.sharedsignatures[0].organization_id ? "organization" : "group";
        if (signatureType === currentTab) {
            setSignatures((prev) => {
                const index = prev.findIndex((r) => r.id === savedSignature.id);
                if (index === -1) {
                    return [savedSignature, ...prev];
                }

                const newRules = [...prev];
                newRules[index] = savedSignature;
                return newRules;
            });
        } else {
            // Change currentTab to the type of the saved signature
            setCurrentTab(signatureType);
        }

        setSnackbarDetails({
            severity: "success",
            message: `Signature "${savedSignature.name}" ${
                isNew ? "created" : "updated"
            } successfully`,
        });
        setSnackbarOpen(true);
        setIsSaving(false);
        setSignatureToCreateOrEdit(null);
    };

    const getSharedSignatureChanges = (changedSignature) => {
        const original = signatureToCreateOrEdit.sharedsignatures;
        const changed = changedSignature.sharedsignatures;

        const deleted = original.filter(
            (o) => !changed.some((c) => c.id === o.id)
        );
        const updated = changed.filter((c) =>
            original.some((o) => c.id === o.id && !isSharedSignatureEqual(c, o))
        );
        const added = changed.filter(
            (c) => !original.some((o) => c.id === o.id)
        );

        let addedSharedSignatures = [];
        let removedSharedSignatures = [];

        [...added, ...updated].forEach((changedItem) => {
            const addedSharedSignature = {
                signature_id: changedItem.signature_id,
                user_id: changedItem.users?.id,
                group_id: changedItem.groups?.id,
                organization_id: changedItem.organization_id,
                editable: changedItem.editable,
            };

            if (changedItem.id) {
                addedSharedSignature.id = changedItem.id;
            }

            addedSharedSignatures.push(addedSharedSignature);
        });

        deleted.forEach((originalItem) => {
            removedSharedSignatures.push({
                id: originalItem.id,
                signature_id: originalItem.signature_id,
                user_id: originalItem.users?.id,
                group_id: originalItem.groups?.id,
                organization_id: originalItem.organization_id,
                editable: originalItem.editable,
            });
        });

        return { addedSharedSignatures, removedSharedSignatures };
    };

    const isSharedSignatureEqual = (ss1, ss2) => {
        const { id: id1, ...rest1 } = ss1;
        const { id: id2, ...rest2 } = ss2;

        // Set related entity ids
        rest1.user_id = ss1.users?.id;
        rest1.group_id = ss1.groups?.id;
        rest1.organization_id = ss1.organization_id || null;

        rest2.user_id = ss2.users?.id;
        rest2.group_id = ss2.groups?.id;
        rest2.organization_id = ss2.organization_id || null;

        // Delete expanded fields (organizations, users, groups)
        delete rest1.organizations;
        delete rest1.users;
        delete rest1.groups;
        delete rest2.organizations;
        delete rest2.users;
        delete rest2.groups;

        return Object.entries(rest2).every(
            ([key, value]) => rest1.hasOwnProperty(key) && rest1[key] === value
        );
    };

    const handleCancelCreateOrEdit = () => {
        setSignatureToCreateOrEdit(null);
    };

    const handleDeleteConfirm = async () => {
        if (signatureToDelete) {
            await deleteSignature();
        } else if (sharedSignatureToDelete) {
            await deleteSharedSignature();
        }
    };

    const handleDeleteCancel = () => {
        setSignatureToDelete(null);
        setSharedSignatureToDelete(null);
    };

    const handleDeleteSignature = (groupId) => {
        setSignatureToDelete(groupId);
    };

    const deleteSignature = async () => {
        try {
            const signatureItemToDelete = signatures.find((r) => r.id === signatureToDelete);
            const isPersonalSignature = !signatureItemToDelete.organization_id && (!signatureItemToDelete.sharedsignatures || signatureItemToDelete.sharedsignatures.length === 0);
            await datastore.api.deleteSignature(signatureToDelete, isPersonalSignature);
        } catch (error) {
            console.error("error deleting signature", error);
            setSignatureToDelete(null);
            setSnackbarDetails({
                severity: "error",
                message: `Error deleting signature`,
            });
            setSnackbarOpen(true);
            return;
        }

        setSnackbarDetails({
            severity: "success",
            message: `Signature deleted successfully`,
        });
        setSnackbarOpen(true);

        setSignatures((prev) => prev.filter((r) => r.id !== signatureToDelete));
        setSignatureToDelete(null);
    };

    const handleDeleteSharedSignature = (signatureId, sharedSignatureId) => {
        setSharedSignatureToDelete({ signature: signatureId, shared: sharedSignatureId });
    };

    const deleteSharedSignature = async () => {
        try {
            await datastore.api.deleteSharedSignature(sharedSignatureToDelete.shared);
        } catch (error) {
            console.error("error deleting shared signature", error);
            setSharedSignatureToDelete(null);
            setSnackbarDetails({
                severity: "error",
                message: `Error deleting shared signature`,
            });
            setSnackbarOpen(true);
            return;
        }

        setSnackbarDetails({
            severity: "success",
            message: `Shared signature deleted successfully`,
        });
        setSnackbarOpen(true);

        setSignatures((prev) => {
            const index = prev.findIndex((r) => r.id === sharedSignatureToDelete.signature);
            if (index === -1) {
                return prev;
            }

            const newSignatures = [...prev];
            newSignatures[index].sharedsignatures = newSignatures[index].sharedsignatures.filter(
                (r) => r.id !== sharedSignatureToDelete.shared
            );

            if (newSignatures[index].sharedsignatures.length === 0) {
                newSignatures.splice(index, 1);
            }

            return newSignatures;
        });

        setSharedSignatureToDelete(null);
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setSnackbarOpen(false);
    };

    useEffect(() => {
        fetchSignatures({ pageToFetch: page, query: searchQuery });
    }, []);

    useDebounce(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
        } else {
            setPage(1);
            fetchSignatures({ pageToFetch: 1, query: searchQuery })
        }
    }, [searchQuery], 500);

    useEffect(() => {
        setPage(1);
        fetchSignatures({ pageToFetch: 1, query: searchQuery });
    }, [currentTab]);

    return (
        <Container className="signatures-container table-view" maxWidth={false}>
            <Box className="list-nav" sx={{ width: "100%" }}>
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                >
                    <Tab value="personal" label="Personal" iconPosition="end" />
                    {organization && (
                        <Tab value="group" label="Group" iconPosition="end" />
                    )}
                    {organization && (
                        <Tab
                            value="organization"
                            label="Organization"
                            iconPosition="end"
                        />
                    )}
                    {organization && isOrgAdmin() && (
                        <Tab
                            value="unshared"
                            label="Unshared"
                            iconPosition="end"
                        />
                    )}
                </Tabs>
            </Box>

            <Grid className="table-actions" container spacing={2}>
                <Grid item sm={9} xs={12}>
                    <SearchBar
                        setSearchTerm={setSearchQuery}
                        placeholder={`Search ${currentTab} signatures`}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() => handleCreate()}
                    >
                        Create Signature
                    </Button>
                </Grid>
            </Grid>

            <SignatureList
                signatures={signatures}
                loading={loading}
                isPersonal={currentTab === "personal"}
                setPage={loadPage}
                totalCount={totalCount}
                page={page}
                pageSize={pageSize}
                handleEdit={handleEdit}
                handleDelete={handleDeleteSignature}
                handleRemoveSharedSignature={handleDeleteSharedSignature}
            />

            {!!signatureToCreateOrEdit && (
                <CreateEditSignature
                    signature={signatureToCreateOrEdit}
                    organization={organization}
                    groups={groups}
                    open={signatureToCreateOrEdit !== null}
                    onClose={handleCancelCreateOrEdit}
                    onSave={handleSave}
                    isSaving={isSaving}
                />
            )}

            <Dialog
                open={
                    signatureToDelete !== null ||
                    sharedSignatureToDelete !== null
                }
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {signatureToDelete
                            ? "Are you sure you wish to delete this signature"
                            : "Are you sure you wish to remove this signature from the group"}
                        ? Note that this will break any rules that use this
                        signature.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarDetails.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

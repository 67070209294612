// @ts-nocheck
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useEditor } from "@tiptap/react";
import type { EditorOptions } from "@tiptap/core";
import {
    LinkBubbleMenu,
    TableBubbleMenu,
    insertImages,
    RichTextEditorProvider,
    RichTextField,
} from "mui-tiptap";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Alert from "@mui/material/Alert";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import FormatShapesOutlinedIcon from "@mui/icons-material/FormatShapesOutlined";
import EditorMenuControls from "./EditorMenuControls";
import useExtensions from "./useExtensions";
import { uploadImages } from "./helpers";
import useDebounce from "../../hooks/useDebounce";

import "./styles.scss";

function fileListToImageFiles(fileList: FileList): File[] {
    // You may want to use a package like attr-accept
    // (https://www.npmjs.com/package/attr-accept) to restrict to certain file
    // types.
    return Array.from(fileList).filter((file) => {
        const mimeType = (file.type || "").toLowerCase();
        return mimeType.startsWith("image/");
    });
}

export default function RTEditor({
    content,
    saveContent,
    modalContainer,
    canEdit,
    datastore,
    uploadEntityId,
}) {
    const extensions = useExtensions();
    const [editorType, setEditorType] = useState("rte");
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const editor = useRef(null);

    const handleEditorTypeChange = (event, newEditorType) => {
        if (newEditorType !== null) {
            setEditorType(newEditorType);
        }
    };

    const handleNewImageFiles = useCallback(
        async (files: File[], insertPosition?: number): void => {
            setIsUploading(true);
            const uploadResults = await uploadImages(files, uploadEntityId, datastore);

            if (uploadResults.length !== files.length) {
                setUploadError(true);
            }

            const attributesForImageFiles = uploadResults.map((image) => ({
                src: image.path,
                alt: image.name,
            }));

            insertImages({
                images: attributesForImageFiles,
                editor: editor.current,
                insertPosition,
            });
            setIsUploading(false);
        },
        []
    );

    // Allow for dropping images into the editor
    const handleDrop: NonNullable<EditorOptions["editorProps"]["handleDrop"]> =
        useCallback(
            (view, event, _slice, _moved) => {
                if (!(event instanceof DragEvent) || !event.dataTransfer) {
                    return false;
                }

                const imageFiles = fileListToImageFiles(
                    event.dataTransfer.files
                );
                if (imageFiles.length > 0) {
                    const insertPosition = view.posAtCoords({
                        left: event.clientX,
                        top: event.clientY,
                    })?.pos;

                    handleNewImageFiles(imageFiles, insertPosition);

                    // Return true to treat the event as handled. We call preventDefault
                    // ourselves for good measure.
                    event.preventDefault();
                    return true;
                }

                return false;
            },
            [handleNewImageFiles]
        );

    // Allow for pasting images
    const handlePaste: NonNullable<
        EditorOptions["editorProps"]["handlePaste"]
    > = useCallback(
        (_view, event, _slice) => {
            if (!event.clipboardData) {
                return false;
            }

            const pastedImageFiles = fileListToImageFiles(
                event.clipboardData.files
            );
            if (pastedImageFiles.length > 0) {
                handleNewImageFiles(pastedImageFiles);
                // Return true to mark the paste event as handled. This can for
                // instance prevent redundant copies of the same image showing up,
                // like if you right-click and copy an image from within the editor
                // (in which case it will be added to the clipboard both as a file and
                // as HTML, which Tiptap would otherwise separately parse.)
                return true;
            }

            // We return false here to allow the standard paste-handler to run.
            return false;
        },
        [handleNewImageFiles]
    );

    editor.current = useEditor({
        extensions,
        content: content,
        onBlur({ editor, event }) {
            saveContent(editor.isEmpty ? "" : editor.getHTML());
        },
        editorProps: {
            handleDrop: handleDrop,
            handlePaste: handlePaste,
        },
    });

    useDebounce(
        () => {
            saveContent(editor.current.isEmpty ? "" : editor.current.getHTML());
        },
        [editor?.current?.state.doc.content],
        1000
    );

    useEffect(() => {
        if (editorType === "rte" && editor?.current) {
            editor.current.commands.setContent(content);
        }
    }, [editorType]);

    useEffect(() => {
        if (editorType === "rte" && editor?.current) {
            editor.current.options.editable = canEdit;
        }
    }, [canEdit, editor?.current]);

    useEffect(() => {
        if (uploadError) {
            setTimeout(() => {
                setUploadError(false);
            }, 5000);
        }
    }, [uploadError]);

    return (
        <div className="toggles-editor">
            <ToggleButtonGroup
                className="editor-type-options-container"
                orientation="vertical"
                value={editorType}
                exclusive
                onChange={handleEditorTypeChange}
                aria-label="editor-type-options"
                size="small"
            >
                <Tooltip title="Rich Text Editor" placement="right">
                    <ToggleButton value="rte" aria-label="Text Editor Button">
                        <FormatShapesOutlinedIcon />
                    </ToggleButton>
                </Tooltip>
                <Tooltip title="Raw HTML Editor" placement="right">
                    <ToggleButton
                        value="raw"
                        aria-label="Raw HTML Editor Button"
                    >
                        <CodeOutlinedIcon />
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
            <div className="editor-main-container">
                {editorType === "rte" && (
                    <>
                        <RichTextEditorProvider editor={editor?.current}>
                            <RichTextField
                                className="editor-container"
                                controls={
                                    <>
                                        <LinkBubbleMenu
                                            container={modalContainer.current}
                                        />
                                        <TableBubbleMenu
                                            container={modalContainer.current}
                                        />
                                        <EditorMenuControls uploadEntityId={uploadEntityId} datastore={datastore} />
                                    </>
                                }
                            />
                        </RichTextEditorProvider>
                        <Alert severity="warning" className="editor-render-warning">
                            Note: Editor may not represent how it will render
                            exactly. Please test by inserting template into an
                            email!
                        </Alert>
                    </>
                )}
                {editorType === "raw" && (
                    <>
                        <TextField
                            className="raw-html-editor"
                            multiline
                            fullWidth
                            rows={10}
                            disabled={!canEdit}
                            value={content}
                            onChange={(ev) => saveContent(ev.target.value)}
                        />
                        {canEdit && (
                            <Alert severity="warning">
                                Note: changes to raw HTML may cause content to
                                be lost!
                            </Alert>
                        )}
                    </>
                )}
                {isUploading && (
                    <Alert severity="info">Uploading image(s)...</Alert>   
                )}
                {uploadError && (
                    <Alert severity="error">
                        Error uploading image(s). Please try again.
                    </Alert>
                )}
            </div>
        </div>
    );
}

import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import './styles.scss';

export default function Copyright() {
    return (
        <Typography className="copyright" variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://www.gettoggles.com">
                Toggles
            </Link>{" "}
            {new Date().getFullYear()}.
        </Typography>
    );
}

import { TableImproved } from "mui-tiptap";

const TableImprovedV2 = TableImproved.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            style: {
                default: null,
                renderHTML: (attributes) => {
                    if (!attributes.style) {
                        return {};
                    }

                    return {
                        style: attributes.style,
                    };
                },
                parseHTML: (element) => {
                    const style = element.getAttribute("style") || "";
                    return style;
                },
            },
        };
    },
});

export default TableImprovedV2;

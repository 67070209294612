// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuth } from "../hooks/useAuth";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { VALIDATION_REGEXES } from "../helpers/constants";

import logo from "../assets/logo-dark_260x139.png";
import "./Signup.scss";

const SIGNUP_TYPE_LABELS = {
    starter: "Starter Plan",
    premium: "Premium Plan",
    business: "Business Pro Plan",
    invite: "Organization Invite",
};

export default function Signup() {
    useDocumentTitle("Toggles | Signup");
    const { signup, signupOrg, checkDomain } = useAuth();
    const [searchParams] = useSearchParams();
    const [signupType, setSignupType] = useState('starter');
    const [signupPeriod, setSignupPeriod] = useState('monthly');
    const [domainCheckOpen, setDomainCheckOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [data, setData] = useState({
        email: '',
        fullName: '',
        domain: '',
    });
    const [inputStateChanged, setInputStateChanged] = useState({
        email: false,
        fullName: false,
        domain: false,
    });

    const handleIndividualSignup = async (event) => {
        event.preventDefault();

        if (!isDataValid()) {
            setHasError(true);
            return;
        }

        setLoading(true);
        setHasError(false);
        setEmailSent(false);

        // 1. Create new user with email (or login existing user)
        const { error: signupError } = await signup(data.email, {
            signupType,
            fullName: data.fullName,
            signupPeriod: signupPeriod,
        });

        if (signupError) {
            setHasError(true);
            setLoading(false);
            setErrorMessage(
                "Error signing up with email. Please try again or contact support."
            );
            return;
        }

        // 2. Inform user to check their email to confirm their account and login
        setEmailSent(true);
        setHasError(false);
        setLoading(false);
    }

    const handleBusinessSignup = async (event) => {
        event.preventDefault();

        if (!isDataValid()) {
            setHasError(true);
            return;
        }

        setLoading(true);
        setHasError(false);
        setEmailSent(false);

        // 1. Check to ensure domain is not registered yet (new function)
        let domainCheck;
        try {
            domainCheck = await checkDomain(data.domain);
        } catch (error) {
            setHasError(true);
            setLoading(false);
            setErrorMessage(
                "Error checking domain availability. Please try again or contact support."
            );
            return;
        }

        // If in use, show warning modal asking for acknowledgement to proceed and wait for that
        if (!domainCheck?.available) {
            setDomainCheckOpen(true);
            return;
        }

        return completeBusinessSignup();
    };

    const completeBusinessSignup = async () => {
        // 2. Create new user with email (or login existing user) -- if sends successul, then immediately
        //    create new org and add user as admin
        const { error: signupError } = await signup(data.email, {
            signupType,
            fullName: data.fullName,
            signupPeriod: signupPeriod,
        });

        if (signupError) {
            setHasError(true);
            setLoading(false);
            setErrorMessage(
                "Error signing up with email. Please try again or contact support."
            );
            return;
        }

        // 3. Create new Org & add user as admin(owner) (new function)
        try {
            await signupOrg({
                email: data.email,
                companyName: data.fullName,
                domain: data.domain,
            });
        } catch (error) {
            setHasError(true);
            setLoading(false);
            setErrorMessage(
                "Error creating new organization. Please try again or contact support."
            );
            return;
        }

        // 4. Inform user to check their email to confirm their account and login
        setEmailSent(true);
        setHasError(false);
        setLoading(false);
    };

    const isDataValid = () => {
        if (signupType === "business") {
             return (
                 VALIDATION_REGEXES.email.test(data.email) &&
                 data?.fullName &&
                 VALIDATION_REGEXES.domain.test(data?.domain) &&
                 data?.email?.toLowerCase().endsWith(data?.domain.toLowerCase())
             );
        }

        if (signupType === "premium") {
            return (
                VALIDATION_REGEXES.email.test(data.email) &&
                data?.fullName
            );
        }

         return (
             VALIDATION_REGEXES.email.test(data.email)
         );
    };

    const closeDomainCheckDialog = () => {
        setDomainCheckOpen(false);
        setHasError(false);
        setLoading(false);
    };

    const bypassDomainCheck = () => {
        setDomainCheckOpen(false);
        return completeBusinessSignup();
    };

    useEffect(() => {
        const type = searchParams.get("type");
        const period = searchParams.get("period");
        const email = searchParams.get("email");

        setSignupType(type || 'starter');
        setSignupPeriod(period || 'monthly');

        if (email) {
            setData((prev) => ({
                ...prev,
                email,
            }));
        }
    }, [searchParams]);

    return (
        <Container className="signup-container" component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <img className="logo" src={logo} alt="Toggles Logo" />
                <div className="signup-type">
                    <Chip
                        className="signup-type-chip"
                        label={SIGNUP_TYPE_LABELS[signupType]}
                        color="primary"
                    />
                </div>
                {(signupType !== "starter" || signupType !== 'invite') && (
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label={
                            signupType === "business"
                                ? "Company Name"
                                : "Full Name"
                        }
                        inputProps={{
                            maxLength: 128,
                        }}
                        value={data?.fullName || ""}
                        error={inputStateChanged.fullName && !data?.fullName}
                        helperText={
                            inputStateChanged.fullName && !data?.fullName
                                ? signupType === "business"
                                    ? "Company name is required"
                                    : "Full name is required"
                                : ""
                        }
                        onChange={(ev) =>
                            setData((prev) => ({
                                ...prev,
                                fullName: ev.target.value,
                            }))
                        }
                        onBlur={() =>
                            setInputStateChanged((prev) => ({
                                ...prev,
                                fullName: true,
                            }))
                        }
                    />
                )}
                {signupType === "business" && (
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Company Email Domain"
                        inputProps={{
                            maxLength: 128,
                        }}
                        value={data?.domain || ""}
                        error={
                            inputStateChanged.domain &&
                            (!data?.domain ||
                                !VALIDATION_REGEXES.domain.test(data.domain))
                        }
                        helperText={
                            inputStateChanged.domain &&
                            (!data.domain ||
                                !VALIDATION_REGEXES.domain.test(data.domain))
                                ? "Company email domain is required (ex. togglesforemail.com)"
                                : ""
                        }
                        onChange={(ev) =>
                            setData((prev) => ({
                                ...prev,
                                domain: ev.target.value,
                            }))
                        }
                        onBlur={() =>
                            setInputStateChanged((prev) => ({
                                ...prev,
                                domain: true,
                            }))
                        }
                    />
                )}
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                    disabled={signupType === "invite"}
                    inputProps={{
                        maxLength: 128,
                    }}
                    value={data?.email || ""}
                    error={
                        inputStateChanged.email &&
                        (!data?.email ||
                            !VALIDATION_REGEXES.email.test(data.email) ||
                            !data.email.toLowerCase().endsWith(data.domain))
                    }
                    helperText={
                        inputStateChanged.email &&
                        (!data?.email ||
                            !VALIDATION_REGEXES.email.test(data.email) ||
                            !data.email.toLowerCase().endsWith(data.domain))
                            ? signupType !== "business"
                                ? "Valid email address is required"
                                : "Valid email address is required and must match the company email domain"
                            : ""
                    }
                    onChange={(ev) =>
                        setData((prev) => ({
                            ...prev,
                            email: ev.target.value,
                        }))
                    }
                    onBlur={() =>
                        setInputStateChanged((prev) => ({
                            ...prev,
                            email: true,
                        }))
                    }
                />
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 4 }}
                    onClick={
                        signupType === "business"
                            ? handleBusinessSignup
                            : handleIndividualSignup
                    }
                    disabled={emailSent || !isDataValid()}
                    loading={loading}
                >
                    Create Account
                </LoadingButton>

                <Link
                    color="primary"
                    variant="body2"
                    underline="none"
                    component={RouterLink}
                    to="/login"
                >
                    Already have an account? Sign in.
                </Link>

                <Typography
                    className="signup-note"
                    variant="subtitle2"
                    align="center"
                >
                    By signing up for a Toggles account, you agree to our{" "}
                    <Link
                        href="https://www.gettoggles.com/terms"
                        target="_blank"
                        underline="none"
                    >
                        Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link
                        href="https://www.gettoggles.com/privacy"
                        target="_blank"
                        underline="none"
                    >
                        Privacy Policy
                    </Link>
                    .
                </Typography>

                {hasError && (
                    <Alert className="signup-message" severity="error">
                        {errorMessage ||
                            "Something went wrong. Please try again or contact support."}
                    </Alert>
                )}

                {emailSent && (
                    <Alert className="signup-message" severity="success">
                        We've sent you an email with a link to sign in. Check
                        your inbox and click the link to continue registration.
                    </Alert>
                )}
            </Box>
            <Dialog
                open={domainCheckOpen}
                onClose={closeDomainCheckDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Organization with email domain exists
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The email domain you entered is already in use by another account. By 
                        proceeding, you acknowledge that you are authorized to use this domain
                        and that you understand that each account will have their own set of data 
                        and separate subscriptions.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDomainCheckDialog}>Cancel</Button>
                    <Button onClick={bypassDomainCheck} autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GroupsIcon from "@mui/icons-material/Groups";
import RuleIcon from "@mui/icons-material/Rule";
import ArticleIcon from "@mui/icons-material/Article";
import DrawIcon from "@mui/icons-material/Draw";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import logo from "./assets/logo-dark_260x139.png";
import { useAuth } from "./hooks/useAuth";
import Copyright from "./components/Copyright";
import SubscriptionEndsBanner from "./components/SubscriptionEndsBanner/SubscriptionEndsBanner"
import UpgradeBanner from "./components/UpgradeBanner/UpgradeBanner";
import { convertUTCToLocalTime } from "./helpers/timeHelpers";

import "./layout.scss";

const drawerWidth = 160;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const pages = [
    {
        name: "Users",
        path: "/users",
        icon: <PersonIcon />,
        allowedUserTypes: ["admin", "owner"],
    },
    {
        name: "Groups",
        path: "/groups",
        icon: <GroupsIcon />,
        allowedUserTypes: ["admin", "owner"],
    },
    {
        name: "Rules",
        path: "/rules",
        icon: <RuleIcon />,
        allowedUserTypes: ["free", "subscribed", "admin", "owner"],
    },
    {
        name: "Templates",
        path: "/templates",
        icon: <ArticleIcon />,
        allowedUserTypes: ["subscribed", "admin", "owner"],
    },
    {
        name: "Signatures",
        path: "/signatures",
        icon: <DrawIcon />,
        allowedUserTypes: ["subscribed", "admin", "owner"],
    },
];

export default function Layout() {
    const location = useLocation();
    const { user, organization, subscription, hasActiveSubscription, hasTrialSubscription, hasCanceledSubscription, isOrgUser, isOrgOwner, isOrgAdmin, isFeatureEnabled, logout } = useAuth();
    const [open, setOpen] = useState(false);
    const [shouldShowTrialWarning, setShouldShowTrialWarning] = useState(false);
    const [shouldShowUpgradeBanner, setShouldShowUpgradeBanner] = useState(false);
    const [shouldShowAddinInstallButton, setShouldShowAddinInstallButton] = useState(false);
    const [trialEndDate, setTrialEndDate] = useState(null);
    const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
    const accountMenuOpen = Boolean(accountMenuAnchorEl);

    const handleDrawerToggle = () => {
        setOpen(prev => !prev);
    };

    const handleAccountMenuClick = (event) => {
        setAccountMenuAnchorEl(event.currentTarget);
    };
    const handleAccountMenuClose = () => {
        setAccountMenuAnchorEl(null);
    };

    const allowAccessToPage = (page) => {
        if (isOrgUser() && organization) {
            // Check to ensure org has page enabled
            if (!isFeatureEnabled(page.name)) {
                return false;
            }
        }

        if (page.allowedUserTypes.includes("free")) {
            return true;
        }
        if (page.allowedUserTypes.includes("subscribed") && hasActiveSubscription()) {
            return true;
        }
        if (page.allowedUserTypes.includes("admin") && isOrgAdmin()) {
            return true;
        }
        if (page.allowedUserTypes.includes("owner") && isOrgOwner()) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        setShouldShowTrialWarning(
            (hasActiveSubscription() && hasTrialSubscription()) ||
                subscription?.status === "paused"
        );
        setTrialEndDate(convertUTCToLocalTime(subscription?.trial_ends_at));
        setShouldShowUpgradeBanner(!subscription || hasCanceledSubscription());
        setShouldShowAddinInstallButton(
            !organization &&
            user &&
                (!user.onboarding_complete ||
                    !user.outlook_onboarding_complete)
        );
    }, [subscription]);

    const isSmall = useMediaQuery("(max-width: 500px)");
    const isDesktop = useMediaQuery("(min-width: 901px)");
    const activeRoute = pages.find((page) => location.pathname === page.path);
    const filteredPages = pages.filter(allowAccessToPage);

    return (
        <Box className="site-container">
            <CssBaseline />
            <AppBar
                className={`site-appbar ${open ? "menu-open" : ""}`}
                position="fixed"
                open={open}
            >
                <Toolbar className="appbar-toolbar">
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        edge="start"
                        sx={{
                            marginRight: 5,
                        }}
                    >
                        <MenuIcon sx={{ fontSize: "30px" }} />
                    </IconButton>

                    <div>
                        {shouldShowAddinInstallButton && !isSmall && (
                            <Button
                                href="https://appsource.microsoft.com/en-us/product/office/WA200006828"
                                target="_blank"
                                rel="noreferrer"
                                className="addin-install-button"
                                variant="outlined"
                                size="small"
                            >
                                Install Outlook Add-in
                            </Button>
                        )}

                        <IconButton
                            onClick={handleAccountMenuClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                        >
                            <Avatar
                                sx={{
                                    width: 32,
                                    height: 32,
                                    bgcolor: (theme) =>
                                        theme.palette.primary.main,
                                }}
                                src={organization?.branding?.iconUrl}
                            >
                                {organization?.full_name?.charAt(0)}
                            </Avatar>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {isDesktop ? (
                <Drawer
                    className="site-menu"
                    variant={isDesktop ? "permanent" : "persistent"}
                    open={open}
                >
                    <DrawerHeader>
                        <img className="logo" src={logo} alt="Toggles Logo" />
                    </DrawerHeader>
                    <List>
                        {filteredPages.map((page) => (
                            <ListItem
                                key={`menu-item-${page.name}`}
                                disablePadding
                                sx={{ display: "block" }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open
                                            ? "initial"
                                            : "center",
                                        px: 2.5,
                                    }}
                                    component={RouterLink}
                                    to={page.path}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 2 : "auto",
                                            justifyContent: "center",
                                            color:
                                                activeRoute?.path === page.path
                                                    ? (theme) =>
                                                          theme.palette.primary
                                                              .main
                                                    : "inherit",
                                        }}
                                    >
                                        {page.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={page.name}
                                        primaryTypographyProps={{
                                            variant: "body2",
                                            sx: {
                                                fontWeight: "medium",
                                                color:
                                                    activeRoute?.path ===
                                                    page.path
                                                        ? (theme) =>
                                                              theme.palette
                                                                  .primary.main
                                                        : "inherit",
                                            },
                                        }}
                                        sx={{ opacity: open ? 1 : 0 }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            ) : (
                <SwipeableDrawer
                    className="mobile-site-menu"
                    anchor="left"
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                >
                    <DrawerHeader>
                        <img className="logo" src={logo} alt="Toggles Logo" />
                    </DrawerHeader>
                    <List>
                        {filteredPages.map((page) => (
                            <ListItem
                                key={`menu-item-${page.name}`}
                                disablePadding
                                sx={{ display: "block" }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open
                                            ? "initial"
                                            : "center",
                                        px: 2.5,
                                    }}
                                    component={RouterLink}
                                    to={page.path}
                                    onClick={() => setOpen(false)}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 2 : "auto",
                                            justifyContent: "center",
                                            color:
                                                activeRoute?.path === page.path
                                                    ? (theme) =>
                                                          theme.palette.primary
                                                              .main
                                                    : "inherit",
                                        }}
                                    >
                                        {page.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={page.name}
                                        primaryTypographyProps={{
                                            variant: "body2",
                                            sx: {
                                                fontWeight: "medium",
                                                color:
                                                    activeRoute?.path ===
                                                    page.path
                                                        ? (theme) =>
                                                              theme.palette
                                                                  .primary.main
                                                        : "inherit",
                                            },
                                        }}
                                        sx={{ opacity: open ? 1 : 0 }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </SwipeableDrawer>
            )}

            <Menu
                anchorEl={accountMenuAnchorEl}
                id="account-menu"
                open={accountMenuOpen}
                onClose={handleAccountMenuClose}
                onClick={handleAccountMenuClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem
                    component={RouterLink}
                    to="/account"
                    onClick={handleAccountMenuClose}
                >
                    <ListItemIcon>
                        <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    Profile
                </MenuItem>
                {isOrgAdmin() && (
                    <MenuItem
                        component={RouterLink}
                        to="/org-settings"
                        onClick={handleAccountMenuClose}
                    >
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Organization Settings
                    </MenuItem>
                )}
                <MenuItem
                    component={"a"}
                    href="https://www.gettoggles.com/support"
                    target="_blank"
                    onClick={handleAccountMenuClose}
                >
                    <ListItemIcon>
                        <HelpOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    Support
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleAccountMenuClose();
                        logout();
                    }}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>

            <Box className={`content-wrapper ${open ? "menu-open" : ""}`}>
                {shouldShowTrialWarning && (
                    <SubscriptionEndsBanner
                        endDate={trialEndDate}
                        status={subscription?.status}
                    />
                )}
                {shouldShowUpgradeBanner && (
                    <UpgradeBanner
                        trialAvailable={!subscription?.trial_ends_at}
                    />
                )}
                <div className="appbar-spacer" />
                <div className="content-outlet">
                    <Outlet />
                </div>
                <Copyright />
            </Box>
        </Box>
    );
}

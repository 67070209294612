// @ts-nocheck
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { useAuth } from "../../hooks/useAuth";
import {
    REACT_APP_STRIPE_PRICE_IDS,
    TRIAL_NUM_DAYS,
} from "../../helpers/constants";

import "./styles.scss";

export default function SignupWithTrial({
    interval,
    seats,
    completePayment,
    type,
}) {
    const { user, organization, getSubscription, datastore } = useAuth();
    const [cardName, setCardName] = useState("");
    const [generalError, setGeneralError] = useState();
    const [nameError, setNameError] = useState();
    const [isProcessing, setIsProcessing] = useState(false);
    const [checkoutSuccess, setCheckoutSuccess] = useState(false);

    const createSubscription = async () => {
        if (!user || (type === 'business' && !organization)) {
            setGeneralError(
                "Unable to process signup. Please try again later."
            );
            return;
        }

        if (!interval) {
            setGeneralError("Missing subscription period");
            return;
        }

        setGeneralError(null);

        if (!cardName) {
            setNameError("Missing name on account");
            return;
        }

        setNameError(null);
        setIsProcessing(true);

        const baseSubscriptionData = {
            name: cardName,
            priceId: REACT_APP_STRIPE_PRICE_IDS[type][interval],
            interval: interval,
        };

        const subscriptionData =
            type === "premium"
                ? { user: user.id, email: user.email, ...baseSubscriptionData }
                : {
                      organization: organization.id,
                      email: organization.billing_email,
                      quantity: seats,
                      ...baseSubscriptionData,
                  };

        try {
            await datastore.api.createSubscriptionWithTrial(subscriptionData);

            setCheckoutSuccess(true);

            // Fetch updated subscription (status should be updated) -- waiting 3 seconds to allow that event to be received
            await new Promise((resolve) => setTimeout(resolve, 3000));
            const { data: subscription, error: subscriptionError } = await getSubscription();

            if (subscriptionError) {
                console.log("Error fetching subscription: ", subscriptionError);
                setGeneralError(
                    "Unable to fetch subscription details. Please refresh the page."
                );
            }

            const updatedSubscription = subscription || null;
            setIsProcessing(false);
            setTimeout(() => {
                completePayment(updatedSubscription);
            }, 5000);
        } catch (error) {
            console.log("Error creating subscription: ", error);
            setGeneralError("Unable to process signup. Please try again.");
            setIsProcessing(false);
        }
    };

    const handleCardNameChange = (event) => {
        setCardName(event.target.value);

        if (event.target.value && event.target.value.length > 0) {
            setNameError(null);
        }
    };

    return (
        <div className="payment-form">
            <Typography
                className="card-details-label"
                variant="subtitle2"
                display="block"
            >
                Account Details
            </Typography>
            <TextField
                fullWidth
                required
                className="card-name-input"
                label="Full Name"
                variant="outlined"
                onChange={handleCardNameChange}
                error={!!nameError}
                helperText={nameError}
            />
            <Alert className="trial-info" severity="info">
                Start your {TRIAL_NUM_DAYS[type]} day free trial of Toggles {type === 'premium' ? 'Premium' : 'Business Pro'}. No
                commitment or credit card required.
            </Alert>
            <LoadingButton
                className="checkout-button"
                onClick={createSubscription}
                fullWidth
                variant="contained"
                size="large"
                disabled={!!nameError || checkoutSuccess}
                loading={isProcessing}
                loadingIndicator="Processing…"
            >
                <span>Try {type === 'premium' ? 'Premium' : 'Business Pro'}</span>
            </LoadingButton>

            {generalError && (
                <Alert className="checkout-error" severity="error">
                    {generalError}
                </Alert>
            )}

            <Typography
                className="charge-note"
                variant="subtitle2"
                align="center"
            >
                By signing up, you agree to our{" "}
                <Link
                    href="https://www.gettoggles.com/terms"
                    target="_blank"
                    underline="none"
                >
                    Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                    href="https://www.gettoggles.com/privacy"
                    target="_blank"
                    underline="none"
                >
                    Privacy Policy
                </Link>
                .
            </Typography>

            {checkoutSuccess && (
                <Alert className="checkout-success" severity="success">
                    After your {TRIAL_NUM_DAYS[type]} day free trial, you're account
                    will be disabled unless you add a payment method to your
                    account via the "Manage Subscription" portal.
                </Alert>
            )}
        </div>
    );
}

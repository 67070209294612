// @ts-nocheck
import React, { useState, useEffect, useRef, useCallback } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import SearchBar from "../../components/SearchBar";
import UserList from "./UserList";
import CreateInvite from "./CreateInvite";
import ConfirmDialog from "./ConfirmDialog";

import "./styles.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Users() {
    useDocumentTitle("Toggles Hub | Users");
    const initialLoad = useRef(true);
    const [loading, setLoading] = useState(false);
    const { organization, datastore, isOrgOwner } = useAuth();
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({
        severity: "success",
        message: "",
    });
    const [userToInvite, setUserToInvite] = useState(null);
    const [confirmItem, setConfirmItem] = useState(null);

    const fetchUsers = async ({ pageToFetch, query }) => {
        setLoading(true);
        const queryData = {
            from: (pageToFetch - 1) * pageSize,
            to: pageToFetch * pageSize - 1,
        };

        if (query) {
            queryData.q = query;
        }

        try {
            const data = await datastore.api.getUsers(queryData);
            setUsers(data.users);
            setTotalCount(data.count);
        } catch (error) {
            console.error("error", error);
        }

        setLoading(false);
    };

    const loadPage = (pageToLoad) => {
        fetchUsers({ pageToFetch: pageToLoad, query: searchQuery });
        setPage(pageToLoad);
    };

    const sendInvite = async (invitedUser) => {
        try {
            await datastore.api.inviteUser(invitedUser);
        } catch (err) {
            console.error("Error inviting user: ", err);
            setSnackbarDetails({
                severity: "error",
                message: `Error inviting ${invitedUser.user_email} to your organization.`,
            });
            setSnackbarOpen(true);
            return;
        }

        // Re-fetch users (not optimal...but good enough for now)
        await fetchUsers({ pageToFetch: page, query: searchQuery });

        setSnackbarDetails({
            severity: "success",
            message: `${invitedUser.user_email} successfully invited to your organization.`,
        });
        setSnackbarOpen(true);
        setUserToInvite(null);
    };

    const handleSendInvite = async () => {
        setUserToInvite({
            user_email: "",
        });
    };

    const handleCancelSendInvite = () => {
        setUserToInvite(null);
    };

    const resendInvite = async (invitedUser) => {
        try {
            await datastore.api.resendInvite(invitedUser.id);
        } catch (err) {
            console.error("Error resending invite: ", err);
            setSnackbarDetails({
                severity: "error",
                message: `Error resending invite to ${invitedUser.user_email}.`,
            });
            setSnackbarOpen(true);
            return;
        }

        setSnackbarDetails({
            severity: "success",
            message: `Invite resent to ${invitedUser.user_email}.`,
        });
        setSnackbarOpen(true);
    };

    const handleResendInvite = (user) => {
        setConfirmItem({
            item: user,
            type: "resendInvite",
            handleConfirm: handleResendInviteConfirm,
            handleCancel: handleResendInviteCancel,
        });
    };

    const handleResendInviteCancel = () => {
        setConfirmItem(null);
    };

    const handleResendInviteConfirm = async (user) => {
        await resendInvite(user.organizationuser);
        setConfirmItem(null);
    };

    const deleteInvite = async (invitedUser) => {
        try {
            await datastore.api.deleteInvite(invitedUser.id);
        } catch (err) {
            console.error("Error deleting invite: ", err);
            setSnackbarDetails({
                severity: "error",
                message: `Error deleting invite for ${invitedUser.user_email}.`,
            });
            setSnackbarOpen(true);
            return;
        }

        // Re-fetch users (not optimal...but good enough for now)
        await fetchUsers({ pageToFetch: page, query: searchQuery });

        setSnackbarDetails({
            severity: "success",
            message: `Invite for ${invitedUser.user_email} successfully deleted.`,
        });
        setSnackbarOpen(true);
    };

    const handleDeleteInvite = (user) => {
        setConfirmItem({
            item: user,
            type: "deleteInvite",
            handleConfirm: handleDeleteInviteConfirm,
            handleCancel: handleDeleteInviteCancel,
        });
    };

    const handleDeleteInviteCancel = () => {
        setConfirmItem(null);
    };

    const handleDeleteInviteConfirm = async (user) => {
        await deleteInvite(user.organizationuser);
        setConfirmItem(null);
    };

    const deactivateUser = async (orgUser) => {
        try {
            await datastore.api.deactivateUser(orgUser.id);
        } catch (err) {
            console.error("Error deactivating user: ", err);
            setSnackbarDetails({
                severity: "error",
                message: `Error deactivating user for ${orgUser.user_email}.`,
            });
            setSnackbarOpen(true);
            return;
        }

        // Re-fetch users (not optimal...but good enough for now)
        await fetchUsers({ pageToFetch: page, query: searchQuery });

        setSnackbarDetails({
            severity: "success",
            message: `${orgUser.user_email} successfully deactivated.`,
        });
        setSnackbarOpen(true);
    };

    const handleDeactivateUser = (user) => {
        setConfirmItem({
            item: user,
            type: "deactivateUser",
            handleConfirm: handleDeactivateUserConfirm,
            handleCancel: handleDeactivateUserCancel,
        });
    };

    const handleDeactivateUserCancel = () => {
        setConfirmItem(null);
    };

    const handleDeactivateUserConfirm = async (user) => {
        await deactivateUser(user.organizationuser);
        setConfirmItem(null);
    };

    const reactivateUser = async (orgUser) => {
        try {
            await datastore.api.reactivateUser(orgUser.id);
        } catch (err) {
            console.error("Error deactivating user: ", err);
            setSnackbarDetails({
                severity: "error",
                message: `Error deactivating user for ${orgUser.user_email}.`,
            });
            setSnackbarOpen(true);
            return;
        }

        // Re-fetch users (not optimal...but good enough for now)
        await fetchUsers({ pageToFetch: page, query: searchQuery });

        setSnackbarDetails({
            severity: "success",
            message: `${orgUser.user_email} successfully activated.`,
        });
        setSnackbarOpen(true);
    };

    const handleReactivateUser = (user) => {
        setConfirmItem({
            item: user,
            type: "reactivateUser",
            handleConfirm: handleReactivateUserConfirm,
            handleCancel: handleReactivateUserCancel,
        });
    };

    const handleReactivateUserCancel = () => {
        setConfirmItem(null);
    };

    const handleReactivateUserConfirm = async (user) => {
        await reactivateUser(user.organizationuser);
        setConfirmItem(null);
    };

    const addAdmin = async (user) => {
        try {
            const { adminuser } = await datastore.api.createAdminUser(user.id);

            // Add adminuser to user in list of users
            setUsers((prevUsers) => {
                return prevUsers.map((u) => {
                    if (u.id === user.id) {
                        return {
                            ...u,
                            adminuser,
                        };
                    }

                    return u;
                });
            });

            setSnackbarDetails({
                severity: "success",
                message: `Added ${user.email} as an admin user.`,
            });
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Error adding admin: ", error);
            setSnackbarDetails({
                severity: "error",
                message: `Error adding ${user.email} as an admin user.`,
            });
            setSnackbarOpen(true);
        }
    };

    const handleAddAdmin = (user) => {
        setConfirmItem({
            item: user,
            type: "addAdmin",
            handleConfirm: handleAddAdminConfirm,
            handleCancel: handleAddAdminCancel,
        });
    };

    const handleAddAdminCancel = () => {
        setConfirmItem(null);
    };

    const handleAddAdminConfirm = async (user) => {
        await addAdmin(user);
        setConfirmItem(null);
    };

    const removeAdmin = async (user) => {
        try {
            await datastore.api.removeAdminUser(user.id);
            // Remove adminuser from user in list of users
            setUsers((prevUsers) => {
                return prevUsers.map((u) => {
                    if (u.id === user.id) {
                        return {
                            ...u,
                            adminuser: null,
                        };
                    }

                    return u;
                });
            });

            setSnackbarDetails({
                severity: "success",
                message: `Removed ${user.email} as an admin user.`,
            });
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Error removing admin: ", error);
            setSnackbarDetails({
                severity: "error",
                message: `Error removing ${user.email} as an admin user.`,
            });
            setSnackbarOpen(true);
        }
    };

    const handleRemoveAdmin = (user) => {
        setConfirmItem({
            item: user,
            type: "removeAdmin",
            handleConfirm: handleRemoveAdminConfirm,
            handleCancel: handleRemoveAdminCancel,
        });
    };

    const handleRemoveAdminCancel = () => {
        setConfirmItem(null);
    };

    const handleRemoveAdminConfirm = async (user) => {
        await removeAdmin(user);
        setConfirmItem(null);
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (organization) {
            fetchUsers({ pageToFetch: page, query: searchQuery });
        }
    }, []);

    useDebounce(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
        } else {
            setPage(1);
            fetchUsers({ pageToFetch: 1, query: searchQuery })
        }
    }, [searchQuery], 500);

    return (
        <Container className="users-container table-view" maxWidth={false}>
            <Grid className="table-actions" container spacing={2}>
                <Grid item sm={9} xs={12}>
                    <SearchBar
                        setSearchTerm={setSearchQuery}
                        placeholder="Search users..."
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() => handleSendInvite()}
                    >
                        Invite User
                    </Button>
                </Grid>
            </Grid>

            <UserList
                users={users}
                loading={loading}
                setPage={loadPage}
                totalCount={totalCount}
                page={page}
                pageSize={pageSize}
                addAdmin={handleAddAdmin}
                removeAdmin={handleRemoveAdmin}
                resendInvite={handleResendInvite}
                deleteInvite={handleDeleteInvite}
                deactivateUser={handleDeactivateUser}
                reactivateUser={handleReactivateUser}
                isOwner={isOrgOwner()}
            />

            <CreateInvite
                open={!!userToInvite}
                onClose={handleCancelSendInvite}
                onSave={sendInvite}
                invite={userToInvite}
            />

            <ConfirmDialog
                item={confirmItem?.item}
                handleClose={confirmItem?.handleCancel}
                handleConfirm={confirmItem?.handleConfirm}
                type={confirmItem?.type}
            />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarDetails.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export const REACT_APP_SUPABASE_ID = "madfsdooadtjmnectkdg";
export const REACT_APP_SUPABASE_AUTH_STORAGE_KEY = `sb-${REACT_APP_SUPABASE_ID}-tk`;
export const REACT_APP_SUPABASE_URL =
    "https://madfsdooadtjmnectkdg.supabase.co";
export const REACT_APP_SUPABASE_KEY =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1hZGZzZG9vYWR0am1uZWN0a2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQ0MTk0NzAsImV4cCI6MjAxOTk5NTQ3MH0.v6t0Rj9ivLCUTuJtVz-fzy-gvHAwix3wMhZJWq4VvAI";

export const REACT_APP_SUPABASE_FUNCTIONS = {
    CREATE_SUBSCRIPTION: "create-subscription",
    GET_PORTAL_SESSION: "get-stripe-portal-session",
};

export const VALIDATION_REGEXES = {
    email: /^\S+@\S+\.\S+$/,
    filename: /[^\\]*\.(\w+)$/,
    url: /^(http|https):\/\/[^ "]+$/,
    domain: /^(((?!-))(xn--|_)?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9\-]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/,
};

export const IS_PRODUCTION =
    process.env.NODE_ENV && process.env.NODE_ENV === "production";

export const REACT_APP_STRIPE_KEY = IS_PRODUCTION
    ? "pk_live_51OaiIEHW9Y2vzFsb0WJ0RVU1QhiRmRt9I3pIko7a85PtjiGQtKeQDNVVJBHg6mhkr76DI3EgX3py4o8UU3LFTzi500zTi1X2F4"
    : "pk_test_51OaiIEHW9Y2vzFsbbcCY3ypy6ZaZge6vRNjwr4Sz69fy9AvzZV4NDMaXrmv5hJ0C2Tu5iIK80AfVNYgZh02NMwcT00V7Np0k3V";
export const REACT_APP_STRIPE_PRICE_IDS = IS_PRODUCTION
    ? {
          business: {
              monthly: "price_1PCiaxHW9Y2vzFsbiRJV5wV5",
              annually: "price_1PCiaTHW9Y2vzFsbLtwrEy8u",
          },
          premium: {
              monthly: "price_1PEiWDHW9Y2vzFsbolMbByEt",
              annually: "price_1PCiYpHW9Y2vzFsbCHrGnvyD",
          },
      }
    : {
          business: {
              monthly: "price_1PCiQxHW9Y2vzFsb8OXCGJvc",
              annually: "price_1PCiQ5HW9Y2vzFsbnXyPYLz5",
          },
          premium: {
              monthly: "price_1PEiUoHW9Y2vzFsbOV5fqTrI",
              annually: "price_1PCiONHW9Y2vzFsbGnaxamzV",
          },
      };
export const REACT_APP_STRIPE_PRICING = {
    business: {
        monthly: 19,
        annually: 180,
    },
    premium: {
        monthly: 12.5,
        annually: 120,
    },
};
export const TRIAL_NUM_DAYS = {
    premium: 7,
    business: 7,
};

export const REACT_APP_AUTH_REDIRECT_URL = IS_PRODUCTION
    ? "https://hub.togglesforemail.com/auth-confirm"
    : "http://localhost:3003/auth-confirm";

export const REACT_APP_PREM_SIGNUP_REDIRECT_URL = IS_PRODUCTION
    ? "https://hub.togglesforemail.com/premium-auth-confirm"
    : "http://localhost:3003/premium-auth-confirm";

export const STARTER_PLAN_MAX_RULES = 3;

export const SB_FUNCTIONS = {
    ACCOUNTS: 'a-accounts',
    ACCOUNTS_MANIFEST: 'a-accounts/manifest',
    CREATE_SUBSCRIPTION: IS_PRODUCTION ? 'create-subscription' : 'dev-create-subscription',
    CREATE_TRIAL_SUBSCRIPTION: IS_PRODUCTION ? 'create-trial-subscription' : 'dev-create-trial-subscription',
    GROUPS: 'v2-groups',
    GET_GROUP_RULES: 'v2-rules/group-rules',
    GET_UNSHARED_ORG_RULES: 'v2-rules/unshared-rules',
    GET_GROUP_SIGNATURES: 'v2-signatures/group-signatures',
    GET_UNSHARED_ORG_SIGNATURES: 'v2-signatures/unshared-signatures',
    GET_GROUP_TEMPLATES: 'v2-templates/group-templates',
    GET_UNSHARED_ORG_TEMPLATES: 'v2-templates/unshared-templates',
    GET_ORG_SHARED_SIGNATURES: 'v2-signatures/shared-signatures',
    GET_ORG_SHARED_TEMPLATES: 'v2-templates/shared-templates',
    ORG_DOMAIN_CHECK: 'a-signup/domain-check?domain=',
    ORG_SIGNUP: 'a-signup',
    RULES: 'v2-rules',
    SHAREDRULES: 'a-sharedrules',
    SHAREDSIGNATURES: 'a-sharedsignatures',
    SHAREDTEMPLATES: 'a-sharedtemplates',
    SIGNATURES: 'v2-signatures',
    TEMPLATES: 'v2-templates',
    ORGANIZATIONUSERS: 'v2-organizationusers',
    USERS: 'a-users',
    STRIPE_PORTAL_SESSION: IS_PRODUCTION ? 'get-stripe-portal-session' : 'dev-get-stripe-portal-session',
};

export const SUBSCRIPTION_STATUS_LABELS = {
    active: 'Active',
    canceled: 'Canceled',
    paused: 'Paused',
    incomplete: 'Incomplete',
    incomplete_expired: 'Incomplete Expired',
    past_due: 'Past Due',
    trialing: 'Trial',
    unpaid: 'Unpaid',
};

// @ts-nocheck
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../hooks/useAuth";
import { VALIDATION_REGEXES } from "../../../helpers/constants";
import useDebounce from "../../../hooks/useDebounce";

import "./styles.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CreateInvite({ open, onClose, onSave, invite }) {
    const { organization } = useAuth();
    const [currentInvite, setCurrentInvite] = useState(invite);
    const [hasError, setHasError] = useState(false);
    const [emailOutsideOrg, setEmailOutsideOrg] = useState(false);
    const [inputStateChanged, setInputStateChanged] = useState({
        name: false,
        description: false,
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({
        severity: "success",
        message: "",
    });

    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    const isInviteValid = () => {
        return (
            currentInvite &&
            currentInvite.user_email &&
            VALIDATION_REGEXES.email.test(currentInvite.user_email)
        );
    };

    const handleSave = () => {
        if (!isInviteValid()) {
            setSnackbarDetails({
                severity: "error",
                message: `Please enter a valid email address for user to invite`,
            });
            setSnackbarOpen(true);
            return;
        }

        onSave(currentInvite);
    };

    useEffect(() => {
        if (!invite) {
            return;
        }

        setInputStateChanged({
            user_email: false,
        });
        setCurrentInvite(invite);
        setHasError(false);
    }, [invite]);

    useDebounce(
        () => {
            const isValid = isInviteValid();
            setHasError(!isValid);
            
            if (isValid) {
                // Check if email is outside the organization
                if (organization && currentInvite?.user_email) {
                    const domain = currentInvite.user_email.split("@")[1];
                    if (organization.domains.map(d => d.toLowerCase()).indexOf(domain.toLowerCase()) === -1) {
                        setEmailOutsideOrg(true);
                    } else {
                        setEmailOutsideOrg(false);
                    }
                }
            } else {
                setEmailOutsideOrg(false);
            }
        },
        [currentInvite],
        200
    );

    return (
        <Dialog
            className="create-invite-dialog"
            onClose={onClose}
            open={open}
            maxWidth="sm"
        >
            <DialogTitle>Invite User</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent className="create-edit-dialog-content">
                <Typography className="invite-description" variant="body1">
                    Invite a user to your organization by entering their email
                    address below. They will receive an email with a link to
                    create an account that will automatically be linked to your organization.
                </Typography>
                <TextField
                    required
                    fullWidth
                    className="text-input"
                    label="User Email"
                    inputProps={{
                        maxLength: 256,
                    }}
                    value={currentInvite?.user_email || ""}
                    error={
                        inputStateChanged.user_email &&
                        (!currentInvite?.user_email ||
                            !VALIDATION_REGEXES.email.test(
                                currentInvite?.user_email
                            ))
                    }
                    helperText={
                        inputStateChanged.user_email &&
                        !currentInvite?.user_email
                            ? "Valid user email is required"
                            : ""
                    }
                    onChange={(ev) =>
                        setCurrentInvite({
                            user_email: ev.target.value,
                        })
                    }
                    onBlur={() =>
                        setInputStateChanged({
                            user_email: true,
                        })
                    }
                />
                {emailOutsideOrg && (
                    <MuiAlert className="external-alert" severity="warning">
                        Note: User's email appears to be external to your organization
                    </MuiAlert>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={hasError}>
                    Invite
                </Button>
            </DialogActions>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarDetails.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
}

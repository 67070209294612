import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "./styles.scss";

export default function LoadingIndicator() {
    return (
        <Box className="loading-wrapper">
            <CircularProgress />
        </Box>
    );
}

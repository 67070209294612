// @ts-nocheck
import React, { useState, useEffect, useMemo, memo } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { VARIABLE_TYPES, getVariableInput } from "./VariableTypes";

import "./styles.scss";

const SingleVariableTypeSelection = ({ variable, index, handleChanges, setValid, canEdit }) => {
    const isMeVariable = useMemo(() => {
        variable.name.indexOf("me::") === 0;
    }, [variable.name]);

    const inputField = getVariableInput({
        variable,
        isMeVariable,
        canEdit,
        setValueFn: (value) => {
            const updatedVariableData = { ...variable };
            updatedVariableData.default = value;
            handleChanges(index, updatedVariableData);
        },
        setValidFn: setValid,
    });

    return (
        <Grid container spacing={2} key={index} className="template-var-option">
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    disabled
                    size="small"
                    label="Variable Name"
                    value={variable.name}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={3}
                className={isMeVariable ? "item-flexed" : ""}
            >
                <TextField
                    fullWidth
                    select
                    size="small"
                    label="Type"
                    value={variable.type}
                    onChange={(e) => {
                        const updatedVariableData = { ...variable };
                        updatedVariableData.type = e.target.value;
                        updatedVariableData.default = "";

                        if (e.target.value === "dropdown") {
                            updatedVariableData.options = ["Option 1"];
                        } else {
                            delete updatedVariableData.options;
                        }

                        handleChanges(index, updatedVariableData);
                    }}
                    disabled={!canEdit || isMeVariable}
                >
                    {VARIABLE_TYPES.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                {isMeVariable && (
                    <Tooltip title="ME Variable is always Text type">
                        <span>
                            <IconButton
                                className="icon-button"
                                aria-label="ME Variable"
                                disabled
                            >
                                <InfoOutlinedIcon color="info" />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
            </Grid>
            <Grid
                item
                xs={12}
                md={5}
                className={isMeVariable ? "item-flexed" : ""}
            >
                {inputField}
                {isMeVariable && (
                    <Tooltip title="ME Variable values are set in your Profile">
                        <span>
                            <IconButton
                                className="icon-button"
                                aria-label="ME Variable value set in Profile"
                                disabled
                            >
                                <InfoOutlinedIcon color="info" />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
            </Grid>
            {variable.type === "dropdown" && (
                <Grid item xs={12}>
                    {variable.options.map((option, optionIndex) => {
                        return (
                            <div
                                className={`dropdown-option ${
                                    !option ? "error" : ""
                                }`}
                                key={`select-option-${optionIndex}`}
                            >
                                <TextField
                                    required
                                    fullWidth
                                    className="action-input"
                                    size="small"
                                    inputProps={{
                                        maxLength: 500,
                                    }}
                                    key={`option-input-${optionIndex}`}
                                    value={option}
                                    disabled={!canEdit}
                                    error={!option}
                                    helperText={
                                        !option ? "Option cannot be empty" : ""
                                    }
                                    onChange={(ev) => {
                                        const updatedOptions = [
                                            ...variable.options,
                                        ];
                                        updatedOptions[optionIndex] =
                                            ev.target.value;
                                        const updatedVariableData = {
                                            ...variable,
                                        };
                                        updatedVariableData.options =
                                            updatedOptions;
                                        handleChanges(
                                            index,
                                            updatedVariableData
                                        );
                                    }}
                                />
                                <IconButton
                                    className="delete-option-input-btn"
                                    disabled={
                                        variable.options.length === 1 ||
                                        !canEdit
                                    }
                                    onClick={() => {
                                        const updatedOptions = [
                                            ...variable.options,
                                        ];
                                        const deleted = updatedOptions.splice(
                                            optionIndex,
                                            1
                                        );
                                        const updatedVariableData = {
                                            ...variable,
                                        };

                                        if (
                                            deleted[0] ===
                                            updatedVariableData.default
                                        ) {
                                            updatedVariableData.default = "";
                                        }

                                        updatedVariableData.options =
                                            updatedOptions;
                                        handleChanges(
                                            index,
                                            updatedVariableData
                                        );
                                    }}
                                    aria-label="delete option"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        );
                    })}
                    <Button
                        className="add-option"
                        disabled={!canEdit}
                        onClick={() =>
                            handleChanges(index, {
                                ...variable,
                                options: [
                                    ...variable.options,
                                    `Option ${variable.options.length + 1}`,
                                ],
                            })
                        }
                    >
                        Add dropdown option
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

const VariableTypeSelection = memo(({ variables, handleChanges, setIsValid, canEdit }) => {
    const [variableValidities, setVariableValidities] = useState({});

    // Update variableValidities when variables change
    useEffect(() => {
        setVariableValidities((prevValidities) => {
            const newValidities = {};

            // Add new variables with validity set to true
            variables.forEach((variable) => {
                if (!prevValidities.hasOwnProperty(variable.name)) {
                    newValidities[variable.name] = true;
                } else {
                    // Existing variable - leave validity as-is
                    newValidities[variable.name] =
                        prevValidities[variable.name];
                }
            });

            // Remove deleted variables
            Object.keys(prevValidities).forEach((prevName) => {
                if (!variables.some((variable) => variable.name === prevName)) {
                    delete newValidities[prevName];
                }
            });

            return newValidities;
        });
    }, [variables]);

    useEffect(() => {
        setIsValid(Object.values(variableValidities).every((valid) => valid === true));
    }, [variableValidities]);

    if (variables.length === 0) {
        return;
    }

    return (
        <div className="template-variable-types">
            <Typography variant="subtitle2" className="section-label">
                Content Variables (Types and Default Values)
            </Typography>

            {variables.map((variable, index) => {
                return (
                    <SingleVariableTypeSelection
                        key={`var-type-selection-${index}`}
                        variable={variable}
                        index={index}
                        handleChanges={(index, updatedVariable) => {
                            const updatedVariables = [...variables];

                            if (!updatedVariable) {
                                // Remove from list of updatedVariables
                                updatedVariables.splice(index, 1);
                            } else {
                                // Update in list of updatedVariables
                                updatedVariables[index] = updatedVariable;
                            }

                            handleChanges(updatedVariables);
                        }}
                        setValid={(variableName, isValid) => {
                            setVariableValidities((prevValidities) => ({
                                ...prevValidities,
                                [variableName]: isValid,
                            }));
                        }}
                        canEdit={canEdit}
                    />
                );
            })}
        </div>
    );
});

export default VariableTypeSelection;
